import classNames from "classnames";
import styles from "./Loader.module.css";
import { flexStyles, heightStyles, paddingStyles, widthStyles } from "./styles";
import type { PropsWithClassName } from "./types";

interface Props extends PropsWithClassName {
  full?: boolean;
  fitted?: boolean;
}
export function Loader({ className, fitted = false, full = false }: Props) {
  return (
    <div
      className={classNames(
        heightStyles.full,
        flexStyles.horiz,
        flexStyles.alignCenter,
        flexStyles.justifyCenter,
        {
          [heightStyles.height80vh]: full,
          [widthStyles.full]: !fitted,
          [paddingStyles.p200]: !fitted,
        },
        className,
      )}
    >
      <div className={styles.loader} />
    </div>
  );
}
