import { useEffect } from "react";
import type { GoogleAuthStatePayload, GoogleAuthUrlType } from "schemas";
import { useLiteSiteStore } from "..";
import { apiRequest } from "../apiRequest";

interface Args {
  type: GoogleAuthUrlType;
  payload: GoogleAuthStatePayload;
}
export function useGoogleAuthUrl({ type, payload }: Args) {
  useEffect(() => {
    getGoogleAuthUrl(type, payload);
  }, [type, payload]);

  return useLiteSiteStore((state) => state.googleAuthUrl);
}

async function getGoogleAuthUrl(type: GoogleAuthUrlType, payload: GoogleAuthStatePayload) {
  const { url } = await apiRequest<{ url: string }>({
    url: "auth/google/url",
    method: "POST",
    body: JSON.stringify({ type, state: payload }),
  });

  useLiteSiteStore.setState({
    googleAuthUrl: url,
  });
}
