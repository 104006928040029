import type { SVGProps } from "react";

const SvgHeroWhatsapp = (props: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.626 21.882c.457-1.754.903-3.262 1.22-4.797.116-.562.078-1.252-.147-1.773-1.962-4.551-.537-9.86 3.487-12.77 4.002-2.897 9.573-2.603 13.221.7 3.686 3.335 4.565 8.778 2.11 13.067-2.502 4.37-7.683 6.443-12.423 4.74-1.47-.53-2.733-.51-4.132-.054-1.017.333-2.068.554-3.336.887Zm2.752-2.984c2.106-.382 3.83-.333 5.17.146a8.381 8.381 0 0 0 9.85-3.276c2.193-3.318 1.85-7.502-.861-10.456-2.53-2.76-6.86-3.484-10.151-1.7-3.47 1.881-5.322 5.873-4.168 9.605.57 1.846 1.495 3.546.157 5.681h.003Z"
      fill="#fff"
    />
    <path
      d="M14.08 15.763c-3.14-.014-7.49-3.846-7.824-6.797-.03-.26-.06-.587.064-.784.411-.646.836-1.308 1.387-1.824.149-.138 1.005.11 1.11.362.35.822.966 2.008.639 2.543-.711 1.17-.165 1.76.513 2.4a9.416 9.416 0 0 0 1.94 1.419c.27.146.938.06 1.087-.157.57-.824 1.268-1.073 2.051-.516.562.397 1.443 1.286 1.325 1.521-.357.709-1.122 1.222-1.763 1.768-.137.119-.435.054-.53.062v.003Z"
      fill="#fff"
    />
  </svg>
);
export default SvgHeroWhatsapp;
