import classNames from "classnames/dedupe";
import { Loader, Offsetino, flexStyles, paddingStyles } from "ui";
import { useHomePage, useTheme } from "../../store";
import { Home } from "../design";
import { Button, Overlay } from "../ui";

export function Design() {
  const homePage = useHomePage();
  const theme = useTheme(homePage?.themeId);

  if (!theme) {
    return <Loader />;
  }

  return (
    <Overlay
      className={paddingStyles.p125}
      backTo={{ to: "..", label: "Link in Bio" }}
      title="Link in Bio"
      header="Design"
    >
      <div className={classNames(flexStyles.vert050, flexStyles.grow)}>
        <Home theme={theme} />
        <Offsetino />
        <Button to="..">Done</Button>
      </div>
    </Overlay>
  );
}
