import { useFormContext, useWatch } from "react-hook-form";
import { Description, TextInput, flexStyles, textStyles } from "ui";
import { RadioCard } from "../ui";

const NICE_BLUE = "#007BFF";

export function ButtonColorRadio() {
  const name = "buttonHexColor";
  const value: string | null = useWatch({ name });
  const { setValue } = useFormContext();
  const onClick = (newValue: string | null) => {
    setValue(name, newValue, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
  };
  const hexVisible = value !== null;

  return (
    <div className={flexStyles.vert050}>
      <Description className={textStyles.neutral400}>Button Color</Description>
      <RadioCard checked={value === null} title="Default" onClick={() => onClick(null)} />
      <RadioCard checked={hexVisible} title="Custom Color" onClick={() => onClick(NICE_BLUE)} />
      {hexVisible && <TextInput name={name} />}
    </div>
  );
}
