import classNames from "classnames";
import { useWatch } from "react-hook-form";
import { Description, type PropsWithName, bgStyles, flexStyles, paddingStyles, textStyles } from "ui";
import { FontPreview } from "./FontPreview";

interface Props extends PropsWithName {}
export function BigFontPreview({ name }: Props) {
  const fontFamily = useWatch({ name });

  return (
    <div
      className={classNames(
        bgStyles.neutral200,
        paddingStyles.p075,
        flexStyles.vert050,
        flexStyles.alignCenter,
        textStyles.center,
      )}
    >
      <Description>Preview</Description>
      <FontPreview fontFamily={fontFamily} className={classNames(textStyles.size175, textStyles.wrapBalance)}>
        The quick brown fox jumps over the lazy dog.
      </FontPreview>
    </div>
  );
}
