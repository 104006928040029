import classNames from "classnames";
import type { PropsWithChildren } from "react";
import { bgStyles, flexStyles } from "../styles";
import type { PropsWithClassName } from "../types";

interface Props extends PropsWithClassName, PropsWithChildren {}

export function GrayGrow({ className, children }: Props) {
  return <div className={classNames(flexStyles.grow, bgStyles.neutral100, className)}>{children}</div>;
}
