import type { SVGProps } from "react";
const SvgDollars = (props: SVGProps<SVGSVGElement>) => (
  <svg width={81} height={81} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M30.774 5.456c-.096 1.505 1.61 3.534 2.132 4.926.307.82.777 2.904 1.364 3.37M48.938 3.865c-.345 2.102-.177 4.192-.53 6.333-.26 1.586-.008 3.167-.259 4.692M60.754 6.665c-.51 3.102-1.506 6.053-2.008 9.109"
      stroke="#F5A343"
      strokeWidth={1.731}
      strokeLinecap="round"
    />
    <circle cx={40.5} cy={50.77} r={30} fill="#FACC95" />
    <path
      d="M39.299 69.109V66.22c-2.45-.275-4.388-1.15-5.813-2.625-1.425-1.475-2.137-3.35-2.137-5.625h3.3c0 1.325.387 2.513 1.162 3.563.8 1.025 1.963 1.687 3.488 1.987V53.36c-.175-.05-.363-.1-.563-.15a4.404 4.404 0 0 0-.525-.188c-2.025-.7-3.525-1.562-4.5-2.587-.975-1.05-1.462-2.413-1.462-4.088-.025-1.95.587-3.575 1.837-4.875 1.275-1.3 3.013-2.05 5.213-2.25v-3.037h2.587v3.037c2.1.25 3.775 1.038 5.025 2.363 1.25 1.325 1.888 2.937 1.913 4.837h-3.3c0-.925-.3-1.812-.9-2.662-.6-.875-1.513-1.463-2.738-1.763v9.075c.2.05.4.113.6.188.2.075.413.137.638.187 1.25.45 2.375.975 3.375 1.575s1.775 1.375 2.325 2.325c.575.95.862 2.163.862 3.638a6.847 6.847 0 0 1-.862 3.337c-.575 1.05-1.45 1.938-2.625 2.663-1.15.725-2.588 1.15-4.313 1.275v2.85H39.3ZM35.51 46.046c0 1.15.338 2.025 1.013 2.625.675.575 1.6 1.075 2.775 1.5v-8.25c-1.125.175-2.038.625-2.738 1.35-.7.7-1.05 1.625-1.05 2.775Zm10.913 13.125c0-1.45-.425-2.525-1.275-3.225-.85-.7-1.938-1.275-3.263-1.725v9.375c1.45-.175 2.563-.675 3.338-1.5.8-.825 1.2-1.8 1.2-2.925Z"
      fill="#FF6B00"
    />
  </svg>
);
export default SvgDollars;
