import { type FormQuestionPayload, FormQuestionSchema } from "schemas";
import { CheckboxWithLabel, FormProvider, TextInput, TextMain, flexStyles } from "ui";
import { RawSubmit } from "../ui";
import { ChoiceForm } from "./ChoiceForm";

interface Params {
  question: FormQuestionPayload;
  type: "Add" | "Update";
  onSubmit: (payload: FormQuestionPayload) => Promise<void>;
  cancelTo: string;
}
export function QuestionForm({ type, question, cancelTo, onSubmit }: Params) {
  return (
    <FormProvider<FormQuestionPayload> schema={FormQuestionSchema} defaultValues={question}>
      <div className={flexStyles.vert125}>
        <TextInput autoFocus name="question" label="Your Question" />
        {question.questionType === "SingleChoice" && <ChoiceForm type="single" />}
        {question.questionType === "MultipleChoice" && <ChoiceForm type="multiple" />}
        <CheckboxWithLabel name="required">
          <TextMain>Required</TextMain>
        </CheckboxWithLabel>
        <RawSubmit<FormQuestionPayload>
          onSubmit={onSubmit}
          label={`${type} question`}
          disableClean
          disableInvalid
          cancelTo={cancelTo}
          cancelType="button"
        />
      </div>
    </FormProvider>
  );
}
