import type { CampaignSubjectPayload, PersistedCampaign } from "schemas";
import { apiRequest } from "../apiRequest";
import { getCampaignElements } from "./getCampaignElements";
import { setCampaign } from "./setCampaign";

export async function generateCampaignFromSubject(
  promptId: string,
  payload: CampaignSubjectPayload,
): Promise<{ campaign: PersistedCampaign }> {
  const { campaign } = await apiRequest<{ campaign: PersistedCampaign }>({
    url: `campaigns/generate/${promptId}`,
    method: "POST",
    body: JSON.stringify(payload),
  });

  setCampaign(campaign);
  getCampaignElements(campaign.id);

  return { campaign };
}
