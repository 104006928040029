import classNames from "classnames";
import { forwardRef } from "react";
import {
  bgStyles,
  borderStyles,
  heightStyles,
  opacityStyles,
  pointerStyles,
  positionStyles,
  widthStyles,
} from "../styles";
import type { PropsWithClassName, PropsWithResize, PropsWithStyle } from "../types";

interface Props extends PropsWithClassName, PropsWithStyle, PropsWithResize {}
export const Handle = forwardRef<HTMLDivElement, Props>(({ className, style, onMouseDown, onTouchStart }, ref) => {
  return (
    <div
      ref={ref}
      style={style}
      className={classNames(
        widthStyles.width037,
        heightStyles.height312,
        borderStyles.radius025,
        opacityStyles.opacity50,
        bgStyles.neutral0,
        pointerStyles.resizeX,
        pointerStyles.largeTouchTarget,
        positionStyles.relative,
        className,
      )}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
    />
  );
});
