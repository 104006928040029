import type { SVGProps } from "react";

const SvgEmpty = (props: SVGProps<SVGSVGElement>) => (
  <svg width={149} height={148} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#Empty_svg__a)">
      <path
        d="M74.87 148c38.621 0 69.93-1.585 69.93-3.54 0-1.954-31.309-3.539-69.93-3.539-38.621 0-69.93 1.585-69.93 3.539 0 1.955 31.309 3.54 69.93 3.54Z"
        fill="#D1D4D5"
      />
      <path
        d="M115.726 34.245h-.794v-21.54c0-1.637-.326-3.258-.959-4.77a12.455 12.455 0 0 0-2.731-4.045 12.599 12.599 0 0 0-4.086-2.703 12.708 12.708 0 0 0-4.82-.949H56.229c-1.654 0-3.292.323-4.82.95a12.605 12.605 0 0 0-4.087 2.702 12.46 12.46 0 0 0-2.73 4.044 12.356 12.356 0 0 0-.96 4.771v118.17c0 1.637.327 3.258.96 4.771a12.456 12.456 0 0 0 2.73 4.044 12.609 12.609 0 0 0 4.086 2.703c1.529.626 3.166.949 4.82.949h46.108c1.654 0 3.292-.323 4.82-.949a12.603 12.603 0 0 0 4.086-2.703 12.452 12.452 0 0 0 2.731-4.044 12.36 12.36 0 0 0 .959-4.771V49.578h.794V34.245Z"
        fill="#191B20"
      />
      <path
        d="M112.199 12.792v117.996a9.262 9.262 0 0 1-2.755 6.583 9.457 9.457 0 0 1-6.652 2.727H56.464a9.456 9.456 0 0 1-6.652-2.727 9.262 9.262 0 0 1-2.755-6.583V12.792c0-2.47.991-4.837 2.755-6.584a9.456 9.456 0 0 1 6.652-2.727h5.62a4.385 4.385 0 0 0 .43 4.137c.41.602.961 1.095 1.608 1.437.646.34 1.367.52 2.1.52h26.415c.732 0 1.454-.18 2.1-.52a4.455 4.455 0 0 0 1.607-1.437 4.385 4.385 0 0 0 .43-4.137h6.018c2.495 0 4.888.981 6.652 2.727a9.263 9.263 0 0 1 2.755 6.584Z"
        fill="#F0A056"
      />
      <path
        d="M56.926 88.18c18.041 0 32.667-14.476 32.667-32.333 0-17.856-14.626-32.332-32.667-32.332-18.042 0-32.668 14.476-32.668 32.332 0 17.857 14.626 32.333 32.668 32.333Z"
        fill="#FFCA28"
      />
      <path
        d="M99.493 55.663c0 23.351-19.13 42.302-42.753 42.302-23.624 0-42.752-18.951-42.752-42.302 0-23.35 19.128-42.302 42.752-42.302 23.624 0 42.753 18.951 42.753 42.302Z"
        stroke="#FFCA28"
        strokeWidth={2.474}
      />
      <path
        d="M112.732 55.848c0 30.496-24.98 55.229-55.807 55.229S1.118 86.344 1.118 55.847C1.118 25.352 26.098.619 56.925.619c30.828 0 55.807 24.733 55.807 55.23Z"
        stroke="#FFCA28"
        strokeWidth={1.237}
      />
      <path
        d="M62.542 44.255c3.613 0 6.541-2.899 6.541-6.475 0-3.575-2.928-6.474-6.541-6.474-3.613 0-6.542 2.899-6.542 6.474 0 3.576 2.929 6.475 6.542 6.475Z"
        fill="#191B20"
      />
      <path
        d="M73.445 53.196c4.3 0 7.788-3.45 7.788-7.708 0-4.257-3.487-7.708-7.788-7.708s-7.788 3.451-7.788 7.708 3.487 7.708 7.788 7.708Z"
        fill="#191B20"
      />
      <path
        d="m82.423 111.832-3.82 6.689s-4.995-4.944-2.35-6.252l4.113-1.309 2.057.872ZM75.665 129.718l1.175 4.944-3.526 1.163-1.175-5.671 3.526-.436Z"
        fill="#F9D4B9"
      />
      <path
        d="m83.017 79.82.757 7.649s13.634 12.44 8.933 17.092c-4.702 4.654-10.578 8.434-10.578 8.434l-2.644-1.89 6.023-8.143-12.194-8.579 2.792 35.916-4.26 1.163-9.55-36.352s-2.938-11.633 4.26-17.449l16.46 2.16Z"
        fill="#191B20"
      />
      <path
        d="M74.343 134.516s2.203-2.763 3.232-.291a34.27 34.27 0 0 1 1.616 5.235s3.82 5.671-2.057 4.944c-5.877-.727-4.114-3.345-4.114-3.345a1.933 1.933 0 0 1-.882-1.89c.148-1.309.882-4.362.882-4.362s.735-1.309 1.323-.291ZM80.44 115.54s1.248 1.236-.808 3.272c0 0-.294 5.671-2.939 5.089-2.644-.581-3.526-7.706-3.232-8.433.294-.728-.44-6.108 1.322-5.962 1.763.145 3.967 2.036 3.967 2.036s-3.82 3.489-.147 5.816c0 0 .955-.364 1.837-1.818Z"
        fill="#232A2C"
      />
      <path
        d="m69.494 59.34 11.166.291c-2.46-3.456-2.924-7.532-1.91-12.069l-7.346 1.455c1.415 3.701 1.44 6.877-1.91 10.323Z"
        fill="#F9D4B9"
      />
      <path
        d="m65.969 78.098 17.335 4.798 3.327-22.685a4.345 4.345 0 0 0-1.207-2.724 4.43 4.43 0 0 0-2.69-1.327l-3.984-.455-7.199.727-3.404.953a4.395 4.395 0 0 0-2.37 1.64 4.32 4.32 0 0 0-.826 2.743l1.018 16.33Z"
        fill="#FFF9EE"
      />
      <path
        d="m82.57 78.534 2.79 3.2 6.759-7.126-2.513-12.33a7.534 7.534 0 0 0-2.112-3.875 7.66 7.66 0 0 0-3.936-2.053l-1.87-.354 4.114 16.577-3.233 5.961Z"
        fill="#FFF9EE"
      />
      <path
        d="m82.708 83.03 2.065-2.75-1.763-1.746-2.863.74c-.405.104-.775.31-1.076.598a2.38 2.38 0 0 0-.103 3.347 2.428 2.428 0 0 0 1.915.778 2.447 2.447 0 0 0 1.825-.966Z"
        fill="#F9D4B9"
      />
      <path
        d="m69.054 79.406-2.792 3.2-6.758-7.126 2.513-12.33a7.534 7.534 0 0 1 2.112-3.875 7.66 7.66 0 0 1 3.936-2.052l1.87-.355-4.113 16.577 3.232 5.961Z"
        fill="#FFF9EE"
      />
      <path
        d="M68.915 83.903 66.85 81.15l1.763-1.745 2.863.74c.405.104.776.31 1.076.598a2.38 2.38 0 0 1 .103 3.347 2.428 2.428 0 0 1-1.915.778 2.446 2.446 0 0 1-1.825-.966ZM74.521 51.16c2.817 0 5.1-2.26 5.1-5.047 0-2.788-2.283-5.048-5.1-5.048-2.817 0-5.1 2.26-5.1 5.047 0 2.788 2.283 5.048 5.1 5.048Z"
        fill="#F9D4B9"
      />
      <path d="M79.986 45.642h-8.722l-2.648-3.545c2.605-2.257 5.865-2.662 9.501-2.004l1.87 5.55Z" fill="#191B20" />
    </g>
    <defs>
      <clipPath id="Empty_svg__a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h148v148H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgEmpty;
