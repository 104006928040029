import type { SVGProps } from "react";

const SvgParagraph = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="Paragraph_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#Paragraph_svg__a)">
      <path d="M3 10.75v-1.5h9v1.5H3ZM3 6.5V5h9v1.5H3Zm0 8.75v-1.5h14v1.5H3Z" fill="#F0A056" />
    </g>
  </svg>
);
export default SvgParagraph;
