import { createPortal } from "react-dom";
import { Slide, ToastContainer } from "react-toastify";

export function Toast() {
  return createPortal(
    <ToastContainer stacked position="bottom-center" hideProgressBar transition={Slide} style={{ zIndex: 1000000 }} />,
    // biome-ignore lint/style/noNonNullAssertion:
    document.getElementById("root")!,
  );
}
