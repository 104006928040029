import type { BusinessPayload, PersistedBusiness } from "schemas";
import { apiRequest } from "../apiRequest";
import { setBusiness } from "./setBusiness";

export async function updateBusiness(payload: BusinessPayload) {
  const business = await apiRequest<PersistedBusiness>({
    url: "business",
    method: "PATCH",
    body: JSON.stringify(payload),
  });
  setBusiness(business);
}
