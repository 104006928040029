import type { BlockDestination, BlockPayload, PersistedInlineEditableBlock } from "schemas";
import type { OnClickHandler } from "ui";
import type { PropsWithDirtyBlock } from "../../../helpers";
import { removeCampaignElement, removePageElement, updateBlock } from "../../../store";
import { InlineEditor } from "./InlineEditor";

interface Props extends PropsWithDirtyBlock {
  elementId: string;
  block: PersistedInlineEditableBlock;
  destination: BlockDestination;
  closeItself: OnClickHandler;
}
export function EditInlineBlock({ elementId, block, destination, closeItself, dirtyBlock, setDirtyBlock }: Props) {
  const onSubmit = async (payload: BlockPayload) => {
    await updateBlock({ id: block.id, kind: block.kind, payload });
    closeItself();
  };

  const onDelete = async () => {
    if (window.confirm("Are you sure?")) {
      switch (destination.type) {
        case "Campaign":
          await removeCampaignElement({ campaignId: destination.entity.id, campaignElementId: elementId });
          break;
        case "Page":
          await removePageElement({ pageId: destination.entity.id, pageElementId: elementId });
          break;
      }
    }
  };

  return (
    <InlineEditor
      block={block}
      onDelete={onDelete}
      onSubmit={onSubmit}
      onCancel={closeItself}
      dirtyBlock={dirtyBlock}
      setDirtyBlock={setDirtyBlock}
    />
  );
}
