import { useNavigate } from "react-router";
import { type CampaignRecipientsPayload, CampaignRecipientsSchema } from "schemas";
import { FormProvider, FormTag, Loader, Progress, TextMain, paddingStyles } from "ui";
import { updateCampaignRecipients, useCampaign } from "../../store";
import { Overlay } from "../ui";
import { RecipientsForm } from "./RecipientsForm";

export function Recipients() {
  const navigate = useNavigate();
  const { campaign } = useCampaign();

  if (!campaign) {
    return <Loader />;
  }

  const onSubmit = async (payload: CampaignRecipientsPayload) => {
    await updateCampaignRecipients(campaign.id, payload);
    navigate("../subject", { relative: "path" });
  };

  return (
    <FormProvider defaultValues={campaign.recipients} schema={CampaignRecipientsSchema}>
      <Overlay
        className={paddingStyles.p125}
        backTo={{ to: "../content", label: "Campaign Editor" }}
        title="Recipients"
        subtitle={<TextMain>You can add contacts, contact groups or individual email addresses</TextMain>}
        header="Create a campaign"
        subHeaderElement={<Progress current={3} total={5} />}
      >
        <FormTag<CampaignRecipientsPayload> onSubmit={onSubmit}>
          <RecipientsForm />
        </FormTag>
      </Overlay>
    </FormProvider>
  );
}
