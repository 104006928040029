import classNames from "classnames";
import { DescriptionAccent, Icon, TextMain, flexStyles, paddingStyles } from "ui";
import { useCalendars } from "../../store";
import { Overlay, SuperCard } from "../ui";

export function CalendarSettings() {
  const { calendars } = useCalendars();

  const calendarEmails = calendars.map((calendar) => calendar.email).join(", ");

  return (
    <Overlay
      className={classNames(paddingStyles.p125, flexStyles.gap050)}
      header="Calendars Settings"
      backTo={{ to: "/more" }}
    >
      <SuperCard
        to="synced"
        left={<Icon iconType="SyncCalendar" />}
        right={<Icon iconType="ChevronRight" />}
        title={<DescriptionAccent>Synced Calendars</DescriptionAccent>}
      >
        {calendarEmails && <TextMain>{calendarEmails}</TextMain>}
      </SuperCard>
      <SuperCard
        to="availability"
        left={<Icon iconType="CalendarSettings" />}
        right={<Icon iconType="ChevronRight" />}
        title={<DescriptionAccent>Availability Rules</DescriptionAccent>}
      />
    </Overlay>
  );
}
