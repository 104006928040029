import { useEffect, useState } from "react";
import type { PersistedContact } from "schemas";
import { useIdParam } from "../../hooks";
import { useLiteSiteStore } from "../store";
import { getContacts } from "./getContacts";

function sortName(contact: PersistedContact) {
  return contact.displayName.toLowerCase();
}

export function useContacts() {
  const [loaded, setLoaded] = useState<boolean>(false);
  const contactGroupId = useIdParam<string>("contactGroupId");

  useEffect(() => {
    if (!loaded && contactGroupId) {
      getContacts(contactGroupId, 1).then(() => setLoaded(true));
    }
  }, [loaded, contactGroupId]);

  const contactsMap = useLiteSiteStore(({ contacts }) => contacts);

  return Array.from(contactsMap.values()).sort((a, b) => sortName(a).localeCompare(sortName(b)));
}
