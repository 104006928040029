import classNames from "classnames";
import type { FormQuestionPayload } from "schemas";
import {
  FormProvider,
  ImageInput,
  Loader,
  MultipleChoice,
  SingleChoice,
  TextField,
  flexStyles,
  paddingStyles,
} from "ui";
import { useForm } from "../../store";
import { Button, Overlay } from "../ui";

function renderQuestion(question: FormQuestionPayload, index: number) {
  switch (question.questionType) {
    case "TextField":
      return (
        <TextField
          disabled
          name={`question_[${index}]`}
          label={question.question}
          key={`question_[${index}]`}
          placeholder="Type in text"
        />
      );
    case "NumberField":
      return (
        <TextField
          disabled
          name={`question_[${index}]`}
          label={question.question}
          key={`question_[${index}]`}
          placeholder="# Type in number"
        />
      );
    case "DateField":
      return (
        <TextField
          type="date"
          disabled
          name={`question_[${index}]`}
          label={question.question}
          key={`question_[${index}]`}
        />
      );
    case "SingleChoice":
      return (
        <SingleChoice
          disabled
          name={`question_[${index}]`}
          label={question.question}
          // biome-ignore lint/style/noNonNullAssertion:
          options={question.possibleAnswers!}
          otherOption={question.otherOption}
        />
      );
    case "MultipleChoice":
      return (
        <MultipleChoice
          disabled
          name={`question_[${index}]`}
          label={question.question}
          // biome-ignore lint/style/noNonNullAssertion:
          options={question.possibleAnswers!}
          otherOption={question.otherOption}
        />
      );
    case "FileField":
      return (
        <ImageInput
          disabled
          boldLabel
          name={`question_[${index}]`}
          key={`question_[${index}]`}
          label={question.question}
        />
      );
  }
}

export function Preview() {
  const { form } = useForm();

  if (!form) {
    return <Loader />;
  }

  return (
    <Overlay className={paddingStyles.p125} header={form.name} backTo={{ to: "../..", label: "Utilities" }}>
      <FormProvider defaultValues={{}} schema={{}}>
        <div className={classNames(flexStyles.vert200)}>
          {form.questions.map(renderQuestion)}
          <Button to="../..">Close preview</Button>
        </div>
      </FormProvider>
    </Overlay>
  );
}
