import type { BlockKind, BlockPayload } from "./Block";

export function emptyBlock(kind: BlockKind): BlockPayload {
  switch (kind) {
    case "TextBlock":
      return { text: "", textAlignment: "Default", kind };
    case "ImageBlock":
      return { alt: "", image: "", kind };
    case "VideoBlock":
    case "VideoLinkBlock":
      return { video: "", kind: "VideoBlock" };
    case "EmailBlock":
      return { email: "", label: "", kind };
    case "FormLinkBlock":
      return { formId: "", kind };
    case "PhoneBlock":
      return { number: "", label: "", kind };
    case "LinkBlock":
      return { url: "", label: "", kind };
    case "TwoColumnsBlock":
      return { kind, widthPercentage: 50 };
    case "AppointmentBlock":
      return { appointmentId: "", kind };
    case "AppointmentLinkBlock":
      return { appointmentId: "", kind };
    case "PageBlock":
      return { pageId: "", kind };
    case "SocialLinksBlock":
      return { links: [], kind };
  }
}
