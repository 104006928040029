import type { SVGProps } from "react";

const SvgHeroTikTok = (props: SVGProps<SVGSVGElement>) => (
  <svg width={19} height={22} viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.275.98h2.873c.532 2.695 2.16 4.286 5 4.82v1.584c0 2.048-.166 2.23-2.133 1.536-.994-.35-1.866-1.042-2.915-1.65v5.041c0 1.135.05 2.272-.026 3.402-.219 3.283-3.134 5.65-6.649 5.454-3.172-.179-5.732-3.036-5.628-6.289.103-3.32 2.872-6.072 6.057-6.014.118 0 .24.06.443.114v2.852c-.186.05-.34.106-.496.13-1.937.303-3.097 1.675-2.898 3.435C4.085 17 5.517 18.2 7.146 18.111c1.775-.096 3.026-1.46 3.044-3.356.037-4.199.058-8.397.085-12.598V.979Z"
      fill="#fff"
    />
  </svg>
);
export default SvgHeroTikTok;
