import classNames from "classnames";
import { type PropsWithChildren, forwardRef } from "react";
import { bgStyles, borderStyles, flexStyles, pointerStyles } from "./styles";
import type { PropsWithClassName, PropsWithOptionalClick, PropsWithStyle } from "./types";

interface Props extends PropsWithClassName, PropsWithChildren, PropsWithOptionalClick, PropsWithStyle {
  noBackground?: boolean;
}
export const Card = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, children, noBackground, style }: Props, ref) => (
    <div
      style={style}
      dir="auto"
      className={classNames(flexStyles.vert050, flexStyles.justifyStretch, borderStyles.radius025, className, {
        [bgStyles.neutral0]: !noBackground,
        [borderStyles.neutral200]: !noBackground,
        [pointerStyles.cursor]: !!onClick,
      })}
      onClick={onClick}
      ref={ref}
    >
      {children}
    </div>
  ),
);
