"use client";

import AnimationPlayer from "./AnimationPlayer";
import type { PropsWithClassName } from "./types";

export type AnimationType =
  | "AvatarCircle"
  | "BouncyCheckmark"
  | "Checkmark"
  | "Confetti"
  | "Dandelion"
  | "DesktopMenuAccount"
  | "DesktopMenuAppointments"
  | "DesktopMenuCampaigns"
  | "DesktopMenuContacts"
  | "DesktopMenuForms"
  | "DesktopMenuLink"
  | "DesktopMenuMore"
  | "DesktopMenuPayments"
  | "DesktopMenuProducts"
  | "MenuAccount"
  | "MenuCampaigns"
  | "MenuContacts"
  | "MenuLink"
  | "MenuMore"
  | "NeutralLoading"
  | "SmallLoading"
  | "Tada"
  | "Thrash";

const Animations: Record<AnimationType, string> = {
  AvatarCircle: "https://lottie.popsend.com/avatarCircle.json",
  BouncyCheckmark: "https://lottie.popsend.com/bouncyCheckmark.json",
  Checkmark: "https://lottie.popsend.com/checkmark.json",
  Confetti: "https://lottie.popsend.com/confetti.json",
  Dandelion: "https://lottie.popsend.com/dandelion.json",
  DesktopMenuAccount: "https://lottie.popsend.com/desktopMenuAccount.json",
  DesktopMenuAppointments: "https://lottie.popsend.com/desktopMenuAppointments.json",
  DesktopMenuCampaigns: "https://lottie.popsend.com/desktopMenuCampaigns.json",
  DesktopMenuContacts: "https://lottie.popsend.com/desktopMenuContacts.json",
  DesktopMenuForms: "https://lottie.popsend.com/desktopMenuForms.json",
  DesktopMenuLink: "https://lottie.popsend.com/desktopMenuLink.json",
  DesktopMenuMore: "https://lottie.popsend.com/desktopMenuMore.json",
  DesktopMenuPayments: "https://lottie.popsend.com/desktopMenuPayments.json",
  DesktopMenuProducts: "https://lottie.popsend.com/desktopMenuProducts.json",
  MenuAccount: "https://lottie.popsend.com/menuAccount.json",
  MenuCampaigns: "https://lottie.popsend.com/menuCampaigns.json",
  MenuContacts: "https://lottie.popsend.com/menuContacts.json",
  MenuLink: "https://lottie.popsend.com/menuLink.json",
  MenuMore: "https://lottie.popsend.com/menuMore.json",
  NeutralLoading: "https://lottie.popsend.com/neutralLoading.json",
  SmallLoading: "https://lottie.popsend.com/smallLoading.json",
  Tada: "https://lottie.popsend.com/tada.json",
  Thrash: "https://lottie.popsend.com/thrash.json",
};

export interface AnimationProps extends PropsWithClassName {
  loop?: boolean;
  noAutoplay?: boolean;
  keepLastFrame?: boolean;
  togglePlay?: boolean;
  oneWay?: boolean;
}

interface Props extends AnimationProps {
  animationType: AnimationType;
}

export function Animation({
  className,
  animationType,
  loop = false,
  noAutoplay = false,
  keepLastFrame = false,
  togglePlay = false,
  oneWay = false,
}: Props) {
  return (
    <AnimationPlayer
      oneWay={oneWay}
      noAutoplay={noAutoplay}
      togglePlay={togglePlay}
      loop={loop}
      className={className}
      src={Animations[animationType]}
      keepLastFrame={keepLastFrame}
    />
  );
}
