import type { ThemePayload } from "../schemas/Theme";
import { Colors } from "./colors";
import { isGradientTheme } from "./helpers/isGradientTheme";
import { themePrimary } from "./themePrimary";
import { themePrimaryDark } from "./themePrimaryDark";

export function themeCalloutIcon(theme: ThemePayload) {
  const isGradient = isGradientTheme(theme);

  switch (theme.variation) {
    case "Primary":
      return isGradient ? themePrimaryDark(theme) : themePrimary(theme);
    case "Light":
      return isGradient ? themePrimaryDark(theme) : Colors.Neutral0;
    case "Dark":
      return themePrimaryDark(theme);
    case "Gray":
      return Colors.Neutral100;
    case "Black":
      return themePrimaryDark(theme);
  }
}
