import { useEffect } from "react";
import type { ContactBookings } from "schemas";
import { apiRequest } from "../apiRequest";
import { useLiteSiteStore } from "../store";

async function getContactBookings(id: string) {
  const { pastBookings, futureBookings } = await apiRequest<ContactBookings>({ url: `contacts/${id}/bookings` });

  useLiteSiteStore.setState(({ contactBookings }) => ({
    contactBookings: contactBookings.set(id, { pastBookings, futureBookings }),
  }));
}

export function useContactBookings(id: string) {
  useEffect(() => {
    if (id) {
      getContactBookings(id);
    }
  }, [id]);

  return useLiteSiteStore((state) => state.contactBookings.get(id));
}
