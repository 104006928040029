import classNames from "classnames";
import type { CampaignStatus, PersistedCampaign } from "schemas";
import { Icon, type IconType, TextMain, flexStyles, textStyles } from "ui";

interface Props {
  campaign: PersistedCampaign;
}

export function CampaignStatusLabel({ campaign }: Props) {
  return (
    <div
      className={classNames(flexStyles.horiz025, {
        [textStyles.successMedium]: campaign.status === "Sent",
        [textStyles.secondary500]: campaign.status !== "Sent",
      })}
    >
      <TextMain>{campaign.status}</TextMain>
      <Icon iconType={StatusIcons[campaign.status]} />
    </div>
  );
}

const StatusIcons: Record<CampaignStatus, IconType> = {
  Draft: "Edit",
  Sending: "Check",
  Sent: "Check",
};
