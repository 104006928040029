import type { SVGProps } from "react";

const SvgShare = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="Share_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#Share_svg__a)">
      <path
        d="M14.5 18a2.411 2.411 0 0 1-1.771-.729A2.411 2.411 0 0 1 12 15.5c0-.111.007-.212.021-.302a3.51 3.51 0 0 1 .062-.302l-4.958-3.021a1.802 1.802 0 0 1-.74.479c-.285.097-.58.146-.885.146a2.411 2.411 0 0 1-1.771-.729A2.411 2.411 0 0 1 3 10c0-.695.243-1.285.729-1.771A2.411 2.411 0 0 1 5.5 7.5c.305 0 .6.052.885.156.285.104.531.26.74.469l4.958-3.021a3.51 3.51 0 0 1-.062-.302A1.976 1.976 0 0 1 12 4.5c0-.695.243-1.285.729-1.771A2.411 2.411 0 0 1 14.5 2c.695 0 1.285.243 1.771.729S17 3.805 17 4.5c0 .695-.243 1.285-.729 1.771A2.411 2.411 0 0 1 14.5 7c-.305 0-.6-.049-.885-.146a1.802 1.802 0 0 1-.74-.479L7.917 9.396c.027.11.048.211.062.302.014.09.021.19.021.302 0 .111-.007.212-.021.302-.014.09-.035.191-.062.302l4.958 3.021c.209-.236.455-.4.74-.49.285-.09.58-.135.885-.135.695 0 1.285.243 1.771.729S17 14.805 17 15.5c0 .695-.243 1.285-.729 1.771A2.411 2.411 0 0 1 14.5 18Zm0-12.5a.962.962 0 0 0 .708-.292.962.962 0 0 0 .292-.708.962.962 0 0 0-.292-.708.962.962 0 0 0-.708-.292.962.962 0 0 0-.708.292.962.962 0 0 0-.292.708c0 .278.097.514.292.708.194.195.43.292.708.292Zm-9 5.5a.962.962 0 0 0 .708-.292A.962.962 0 0 0 6.5 10a.962.962 0 0 0-.292-.708A.962.962 0 0 0 5.5 9a.962.962 0 0 0-.708.292A.962.962 0 0 0 4.5 10c0 .278.097.514.292.708.194.195.43.292.708.292Zm9 5.5a.962.962 0 0 0 .708-.292.962.962 0 0 0 .292-.708.962.962 0 0 0-.292-.708.962.962 0 0 0-.708-.292.962.962 0 0 0-.708.292.962.962 0 0 0-.292.708c0 .278.097.514.292.708.194.195.43.292.708.292Z"
        fill="#191B20"
      />
    </g>
  </svg>
);
export default SvgShare;
