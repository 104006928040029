import type { EmailBlockPayload } from "schemas";
import { InlineTextInput } from "ui";
import { BlockPreview } from "./BlockPreview";

interface Props {
  onSubmit: (payload: EmailBlockPayload) => Promise<void>;
}
export function EmailBlockEditor({ onSubmit }: Props) {
  return (
    <>
      <BlockPreview />
      <InlineTextInput size="Text" label="Button label" name="label" placeholder="Button Label" onSubmit={onSubmit} />
      <InlineTextInput
        size="Text"
        label="Email address"
        name="email"
        placeholder="user@address.com"
        onSubmit={onSubmit}
      />
    </>
  );
}
