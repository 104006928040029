import classNames from "classnames";
import { useFormContext, useWatch } from "react-hook-form";
import { flexStyles, paddingStyles } from "ui";
import { Button } from "../Button";
import { Cancel } from "./Cancel";
import type { SharedProps } from "./SharedProps";

interface Props<T> extends SharedProps {
  onSubmit: (payload: T) => void;
}
export function RawSubmit<T extends object>({
  disabled = false,
  disableInvalid,
  disableClean,
  className,
  onSubmit,
  cancelTo,
  cancelType = "link",
  label = "Submit",
}: Props<T>) {
  const values = useWatch();
  const {
    formState: { isSubmitting, isValid, isDirty },
  } = useFormContext();
  const onClick = () => {
    onSubmit(values as T);
  };

  return (
    <div className={classNames(flexStyles.vert125, paddingStyles.py125, className)}>
      <Button
        onClick={onClick}
        disabled={disabled || isSubmitting || (disableClean && !isDirty) || (disableInvalid && !isValid)}
      >
        {label}
      </Button>
      <Cancel to={cancelTo} cancelType={cancelType} />
    </div>
  );
}
