import type { SVGProps } from "react";
const SvgColumnsImageAndText = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={20} fill="none" {...props}>
    <path
      fill="#465357"
      d="M4.875 17.396q-.938 0-1.604-.667a2.19 2.19 0 0 1-.667-1.604V4.875q0-.958.667-1.615.668-.656 1.604-.656h10.25q.958 0 1.615.656.656.657.656 1.615v10.25q0 .938-.656 1.604a2.18 2.18 0 0 1-1.615.667zm0-1.542h10.25q.333 0 .531-.208a.73.73 0 0 0 .198-.521V4.875q0-.333-.198-.531t-.531-.198H4.875a.73.73 0 0 0-.521.198.7.7 0 0 0-.208.531v10.25q0 .313.208.521a.7.7 0 0 0 .521.208m.917-1.958 2.812-2.813 1.229 1.25 1.563-1.958 2.792 3.521zm1.291-5.229a1.53 1.53 0 0 1-1.125-.459A1.53 1.53 0 0 1 5.5 7.083q0-.666.469-1.125A1.57 1.57 0 0 1 7.104 5.5q.667 0 1.115.469.448.47.448 1.135 0 .667-.459 1.115-.457.448-1.125.448M26 16V6h-4V4h10v2h-4v10zm7.5 0v-6H31V8h7v2h-2.5v6z"
    />
  </svg>
);
export default SvgColumnsImageAndText;
