import type { SVGProps } from "react";

const SvgAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.16 19.32C5.11 19.32 1 15.21 1 10.16S5.11 1 10.16 1s9.16 4.11 9.16 9.16-4.11 9.16-9.16 9.16Zm0-16.32C6.21 3 3 6.21 3 10.16s3.21 7.16 7.16 7.16 7.16-3.21 7.16-7.16S14.11 3 10.16 3Z"
      fill="#465357"
    />
    <path d="M11.16 5.94h-2v8.43h2V5.94Z" fill="#465357" />
    <path d="M14.37 9.16H5.94v2h8.43v-2Z" fill="#465357" />
  </svg>
);
export default SvgAdd;
