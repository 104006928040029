import { type Asserts, array, object, string } from "yup";
import { type ContactImportStatus, type ContactImportType, ContactImportTypeValues } from "../enums";
import type { PersistedUserUpload } from "./UserUpload";

export const ContactImportSchema = object({
  type: string().required().oneOf(ContactImportTypeValues),
  destinationContactGroupIds: array().of(string().defined()),
}).noUnknown();
export type ContactImportPayload = Asserts<typeof ContactImportSchema>;

export type PersistedContactImport = {
  id: string;
  candidateCount: number;
  duplicateCount: number;
  destinationContactGroupIds: string[];
  type: ContactImportType;
  status: ContactImportStatus;
  userUpload?: PersistedUserUpload;
};
