import type { SVGProps } from "react";

const SvgPhoneEnabled = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="PhoneEnabled_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#PhoneEnabled_svg__a)">
      <path
        d="M4.104 17c-.208.014-.444-.055-.708-.208C3.132 16.639 3 16.375 3 16v-2.833c0-.237.07-.445.208-.625a.965.965 0 0 1 .563-.354L6 11.708c.153-.027.312-.024.479.011a.91.91 0 0 1 .459.26l2.062 2a12.712 12.712 0 0 0 5-5l-2.021-2.041a1.123 1.123 0 0 1-.271-.917l.5-2.25A.954.954 0 0 1 13.167 3H16c.292 0 .539.108.74.323a.936.936 0 0 1 .26.76 13.408 13.408 0 0 1-1.271 4.834 14.44 14.44 0 0 1-2.802 4 14.026 14.026 0 0 1-3.989 2.802A13.842 13.842 0 0 1 4.104 17Zm10.584-9.479c.194-.486.354-.979.479-1.479.125-.5.222-1.014.291-1.542h-1.875l-.354 1.562 1.459 1.459Zm-7.167 7.167-1.459-1.459-1.562.333v1.876c.514-.07 1.024-.164 1.531-.282a10.32 10.32 0 0 0 1.49-.468Z"
        fill="#465357"
      />
    </g>
  </svg>
);
export default SvgPhoneEnabled;
