import classNames from "classnames";
import { TextMain, flexStyles } from "ui";
import { useBackLocation } from "../../hooks";
import { LogoHeader, Primer } from "../ui";

const tableRows = [
  "Collect payments during booking.",
  "Allow your clients to pay with debit or credit card",
  "Reduce no-shows by requiring payment upfront",
  "$2 for basic plan, $1 for premium plan, plus 2.9% Stripe transaction fee per paid appointment.",
];

export function Connect() {
  const back = useBackLocation("/");

  return (
    <div className={classNames(flexStyles.vert, flexStyles.grow)}>
      <LogoHeader logo="StripeLogo" />
      <Primer
        header="Connect your Stripe account"
        description={<TextMain>PopSend uses Stripe to ensure that your payments are fast, easy, and secure.</TextMain>}
        why="Why should I setup my payments?"
        tableRows={tableRows}
        minutes={10}
        continueTo="/payments/retry"
        cta="Setup Payments"
        cancelTo={back}
      />
    </div>
  );
}
