import { apiRequest } from "../apiRequest";
import type { Upload } from "./Upload";

export async function getUploadUrl() {
  const url = await apiRequest<Upload & { uploadUrl: string; fileUrl: string }>({
    url: "uploads/private",
    method: "POST",
  });
  return url;
}
