import { useNavigate } from "react-router";
import type { EditableBlockKinds, TextBlockPayload } from "schemas";
import { Loader, paddingStyles } from "ui";
import { useBlockKindParam, useIdParam } from "../../hooks";
import { updateBlock, useBlock } from "../../store";
import { Overlay } from "../ui";
import { BlockFormComponents } from "./blockForms";
import { Titles } from "./titles";

export function Edit() {
  const navigate = useNavigate();

  const id = useIdParam("blockId");
  const kind = useBlockKindParam();
  const block = useBlock(kind, id);

  if (!block) {
    return <Loader />;
  }

  const onSubmit = async (payload: TextBlockPayload) => {
    await updateBlock({ id, kind, payload });

    navigate("../../../..", { relative: "path" });
  };
  const Form = BlockFormComponents[block.kind as EditableBlockKinds];

  return (
    <Overlay
      className={paddingStyles.p125}
      backTo={{ to: "../../../..", label: "Editor" }}
      header={Titles[block.kind as EditableBlockKinds]}
    >
      <Form onSubmit={onSubmit} initialValues={block} cancelPath="../../../.." />
    </Overlay>
  );
}
