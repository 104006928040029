import classNames from "classnames";
import { noop } from "es-toolkit";
import type { PropsWithChildren } from "react";
import { useWatch } from "react-hook-form";
import { IconButton } from "../../IconButton";
import { Loader } from "../../Loader";
import {
  bgStyles,
  borderStyles,
  flexStyles,
  heightStyles,
  iconStyles,
  pointerStyles,
  positionStyles,
  squareStyles,
  widthStyles,
} from "../../styles";
import type { PropsWithName } from "../../types";
import styles from "./FileDrop.module.css";
import { FilePrompt } from "./FilePrompt";

interface Props extends PropsWithChildren, PropsWithName {
  prompt: string;
  uploading: boolean;
  round?: boolean;
}
export function FileDrop({ name, children, prompt, uploading, round = false }: Props) {
  const value = useWatch({ name });

  return (
    <div
      className={classNames(
        flexStyles.vert,
        flexStyles.justifyCenter,
        flexStyles.alignCenter,
        widthStyles.full,
        styles.wrapper,
        {
          [borderStyles.radius025]: !round,
          [borderStyles.radiusRound]: round,
          [squareStyles.square1000]: round,
        },
      )}
    >
      <div
        className={classNames(
          positionStyles.relative,
          flexStyles.vert100,
          flexStyles.alignCenter,
          flexStyles.justifyCenter,
          widthStyles.full,
          iconStyles.secondary300,
          iconStyles.editorInterface,
          heightStyles.min500,
        )}
      >
        {uploading && <Loader />}
        {!uploading && value && (
          <IconButton
            className={classNames(
              positionStyles.absolute,
              positionStyles.right0,
              positionStyles.bottom0,
              positionStyles.zIndex3,
              pointerStyles.eventsNone,
              iconStyles.neutral0,
              bgStyles.primary,
              iconStyles.buttonDimensions375,
            )}
            onClick={noop}
            iconType="Edit"
          />
        )}
        {!uploading && !value && <FilePrompt prompt={prompt} />}
        {children}
      </div>
    </div>
  );
}
