import classNames from "classnames";
import { useFormContext, useWatch } from "react-hook-form";
import type { PersistedContactGroup } from "schemas";
import { RawCheckbox, TextAccent, flexStyles, paddingStyles } from "ui";

interface Props {
  contactGroup: PersistedContactGroup;
  collection: { id: string }[];
  name: string;
}
export function AllCheckbox({ contactGroup, collection, name }: Props) {
  const { setValue } = useFormContext();
  const selection = useWatch({ name });
  const selectionCount = selection ? selection.length : 0;
  const full = selectionCount === contactGroup.contactCount;
  const interrim = !full && selectionCount !== 0;

  const handler = () => {
    if (full) {
      setValue(name, [], { shouldDirty: true, shouldValidate: true });
    } else if (selection.length === collection.length) {
      setValue(name, contactGroup.contacts, { shouldDirty: true, shouldValidate: true });
    } else {
      setValue(
        name,
        collection.map((item) => item.id),
        { shouldDirty: true, shouldValidate: true },
      );
    }
  };

  return (
    <div className={classNames(flexStyles.horiz125, flexStyles.alignCenter, paddingStyles.py075)}>
      <RawCheckbox on={full} onClick={handler} interrim={interrim} />
      <TextAccent className={flexStyles.grow}>
        {selectionCount} of {contactGroup.contactCount} selected
      </TextAccent>
    </div>
  );
}
