import type { PersistedTheme, ThemePayload } from "schemas";
import { apiRequest } from "../apiRequest";
import { setTheme } from "./setTheme";

export async function updateTheme(id: string, payload: ThemePayload) {
  const theme = await apiRequest<PersistedTheme>({
    url: `themes/${id}`,
    method: "PATCH",
    body: JSON.stringify(payload),
  });
  setTheme(theme);
}
