import type { CampaignPayload, PersistedCampaign } from "schemas";
import { apiRequest } from "../apiRequest";
import { setCampaign } from "./setCampaign";

export async function addCampaign(payload: CampaignPayload): Promise<PersistedCampaign> {
  const campaign = await apiRequest<PersistedCampaign>({
    url: "campaigns",
    method: "POST",
    body: JSON.stringify(payload),
  });
  setCampaign(campaign);

  return campaign;
}
