import { useNavigate } from "react-router";
import { type CampaignPayload, CampaignSchema, EmptyCampaign } from "schemas";
import { FormProvider, Progress, paddingStyles } from "ui";
import { addCampaign } from "../../store";
import { Overlay } from "../ui";
import { InitialDetailsForm } from "./InitialDetailsForm";

export function New() {
  const navigate = useNavigate();

  const onSubmit = async (payload: CampaignPayload) => {
    const campaign = await addCampaign(payload);
    navigate(`/campaigns/${campaign.id}/content`);
  };

  return (
    <FormProvider defaultValues={EmptyCampaign} schema={CampaignSchema}>
      <Overlay
        className={paddingStyles.p125}
        backTo={{ to: "/campaigns", label: "Campaigns List" }}
        title="Name your campaign"
        header="Campaign Basics"
        subHeaderElement={<Progress current={1} total={5} />}
      >
        <InitialDetailsForm onSubmit={onSubmit} />
      </Overlay>
    </FormProvider>
  );
}
