import type { Props } from "./Props";
import { SideBySide } from "./SideBySide";
import { WrappingText } from "./WrappingText";

export function TwoColumnsBlockCard({ block }: Props) {
  if (
    block.wrapsText &&
    (block.leftImageBlock || block.rightImageBlock) &&
    (block.leftTextBlock || block.rightTextBlock)
  ) {
    return <WrappingText block={block} />;
  }
  return <SideBySide block={block} />;
}
