import type { SVGProps } from "react";
const SvgLock = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="Lock_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={-1}
      width={20}
      height={21}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#Lock_svg__a)" fill="#fff">
      <path d="M8 12.115c0-.588.205-1.087.617-1.498A2.04 2.04 0 0 1 10.115 10a2.04 2.04 0 0 1 1.498.617c.412.41.617.91.617 1.498 0 .47-.135.89-.405 1.26s-.629.62-1.075.75V15.5H9.48v-1.375a1.974 1.974 0 0 1-1.075-.75c-.27-.37-.405-.79-.405-1.26Zm1.269 0c0 .24.081.44.243.603a.819.819 0 0 0 .603.243.82.82 0 0 0 .603-.243.82.82 0 0 0 .243-.603c0-.24-.08-.44-.243-.603a.819.819 0 0 0-.603-.243c-.24 0-.44.08-.603.243a.819.819 0 0 0-.243.603Z" />
      <path d="M5.494 18c-.413 0-.765-.147-1.056-.441A1.45 1.45 0 0 1 4 16.499v-8c0-.412.147-.765.44-1.059.294-.294.647-.44 1.06-.44H6V5c0-1.107.39-2.05 1.171-2.83C7.952 1.39 8.897 1 10.005 1s2.05.39 2.828 1.17C13.611 2.95 14 3.893 14 5v2h.5c.412 0 .766.146 1.06.44.293.294.44.647.44 1.06v8c0 .412-.147.765-.44 1.059-.295.294-.648.44-1.06.44H5.493Zm.006-1.5h9v-8h-9v8Zm2-9.5h5V5c0-.695-.243-1.285-.73-1.771a2.41 2.41 0 0 0-1.77-.73 2.41 2.41 0 0 0-1.77.73 2.41 2.41 0 0 0-.73 1.77v2Z" />
    </g>
  </svg>
);
export default SvgLock;
