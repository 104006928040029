import { DaysAheadAllowedOptions, TimeBetweenAppointmentsOptions } from "schemas";
import { Accordeon, Divider, Select, TextMain } from "ui";

import { ScheduleInput } from "../ui";

export function Schedule() {
  return (
    <>
      <ScheduleInput name="schedule" />
      <Divider />
      <Accordeon label="advanced options">
        <TextMain>How far in the future do you want to allow bookings.</TextMain>
        <Select name="daysAheadAllowed" label="Allow bookings within" options={DaysAheadAllowedOptions} />
        <TextMain>Select if you need any time between bookings.</TextMain>
        <Select name="timeBetween" label="Break between appointments" options={TimeBetweenAppointmentsOptions} />
      </Accordeon>
    </>
  );
}
