import { useNavigate } from "react-router";
import { EmptyPage, type PagePayload, PageSchema } from "schemas";
import { FormProvider, Loader, paddingStyles } from "ui";
import { useDestinationIndex } from "../../hooks";
import { addPage, useHomePage } from "../../store";
import { Overlay } from "../ui";
import { Form } from "./Form";

export function New() {
  const navigate = useNavigate();
  const homePage = useHomePage();
  const destinationIndex = useDestinationIndex();

  if (!homePage) {
    return <Loader />;
  }

  const onSubmit = async (payload: PagePayload) => {
    const page = await addPage(destinationIndex, homePage, payload);
    navigate(`../../${page.id}`, { relative: "path" });
  };

  return (
    <FormProvider defaultValues={EmptyPage} schema={PageSchema}>
      <Overlay
        className={paddingStyles.p125}
        backTo={{ to: `/content/blocks/new/${destinationIndex}`, label: "Page Editor" }}
        header="Create a Page Block"
        title="Page Basics"
      >
        <Form onSubmit={onSubmit} />
      </Overlay>
    </FormProvider>
  );
}
