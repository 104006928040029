import classNames from "classnames";
import { clone } from "es-toolkit";
import { useFormContext, useWatch } from "react-hook-form";
import type { ScheduleItemPayload, TimesSchemaPayload } from "schemas";
import { type PropsWithClassName, SimpleError, flexStyles } from "ui";
import type { ChangeType } from "./ChangeType";
import { Time } from "./Time";

interface Props extends PropsWithClassName {
  name: string;
  times: TimesSchemaPayload[];
  index?: number;
}

const defaultTimeBlock = () => ({
  startMinutes: 9 * 60,
  endMinutes: 17 * 60,
});

export function Times({ className, name, times, index = undefined }: Props) {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();
  const value = useWatch({ name });

  // @ts-expect-error errors
  const error = errors[name]?.[index || 0]?.times?.message;

  const mapValues = (transformation: (times: TimesSchemaPayload[]) => TimesSchemaPayload[]) =>
    value.map((localValue: ScheduleItemPayload, localIndex: number) => {
      const copy = clone(localValue);
      if (index === undefined || localIndex === index) {
        copy.times = transformation(copy.times);
      }

      return copy;
    });

  const onDelete = async (blockIndex: number) => {
    setValue(
      name,
      mapValues((times) => times.filter((_, i) => i !== blockIndex)),
      { shouldValidate: true },
    );
  };

  const onAdd = async (blockIndex: number) => {
    setValue(
      name,
      mapValues((times) => {
        const copy = [...times];
        copy.splice(blockIndex + 1, 0, defaultTimeBlock());
        return copy;
      }),
      { shouldValidate: true },
    );
  };

  const onChange = (blockIndex: number, type: ChangeType, newValue: number) => {
    setValue(
      name,
      mapValues((times) => {
        const copy = [...times];
        const blockValue = copy[blockIndex];
        blockValue[type] = newValue;
        copy[blockIndex] = blockValue;
        return copy;
      }),
      { shouldValidate: true },
    );
  };

  return (
    <div className={classNames(flexStyles.vert050, className)}>
      <div className={flexStyles.vert050}>
        {times.map((time, blockIndex) => (
          <Time
            // biome-ignore lint:
            key={`Time-${index}-${blockIndex}`}
            index={blockIndex}
            time={time}
            onAdd={() => onAdd(blockIndex)}
            onChange={(type, value) => onChange(blockIndex, type, value)}
            onDelete={times.length > 1 ? () => onDelete(blockIndex) : null}
          />
        ))}
      </div>
      <SimpleError>{error}</SimpleError>
    </div>
  );
}
