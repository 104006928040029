"use client";

import classNames from "classnames";
import { type FieldError, useFormContext } from "react-hook-form";

import { textStyles } from "../styles";
import type { PropsWithClassNameAndChildren } from "../types";
import { Description } from "../typography";

export function SimpleError({ children, className }: PropsWithClassNameAndChildren) {
  if (!children) {
    return null;
  }
  return <Description className={classNames(textStyles.errorDark, className)}>{children}</Description>;
}

interface Props {
  name: string;
}

// biome-ignore lint:
const getNestedValue = (obj: any, path: string) => {
  return path.split(".").reduce((acc, part) => acc?.[part], obj);
};

export function Error({ name }: Props) {
  const {
    formState: { errors },
  } = useFormContext();
  const error = getNestedValue(errors, name) as FieldError;

  if (!error) {
    return null;
  }

  return <SimpleError>{error.message?.toString()}</SimpleError>;
}
