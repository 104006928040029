import { useWatch } from "react-hook-form";
import type { PropsWithName } from "ui";
import { BulkContactActions } from "../contacts";

interface Props extends PropsWithName {
  onDone: () => void;
}
export function SelectBottomBar({ name, onDone }: Props) {
  const contactIds = useWatch({ name });

  return <BulkContactActions contactIds={contactIds} onDone={onDone} />;
}
