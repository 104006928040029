import { type RefObject, useMemo } from "react";
import { useIntersection } from "react-use";

export function useChillIntersection(ref: RefObject<HTMLElement>, offsetTop: number) {
  const intersection = useIntersection(ref, {
    rootMargin: `${offsetTop}px 0px 0px 0px`,
  });

  const isIntersecting = useMemo(() => !!intersection?.isIntersecting, [intersection?.isIntersecting]);

  return isIntersecting;
}
