import type { ThemePayload } from "../schemas/Theme";
import { Colors } from "./colors";
import { themePrimary } from "./themePrimary";

export function themeTagBorderColor(theme: ThemePayload) {
  switch (theme.variation) {
    case "Light":
    case "Dark":
    case "Primary":
      return themePrimary(theme);
    case "Black":
    case "Gray":
      return Colors.Neutral600;
  }
}
