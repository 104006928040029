import classNames from "classnames";
import {
  DescriptionAccent,
  Icon,
  type OnClickHandler,
  flexStyles,
  iconStyles,
  paddingStyles,
  pointerStyles,
  textStyles,
} from "ui";
import type { LinkItem } from "./LinkItem";

interface Props {
  item: LinkItem;
  active: boolean;
  onClick: OnClickHandler;
}

export function Item({ item, active, onClick }: Props) {
  return (
    <a
      // biome-ignore lint/a11y:
      onClick={onClick}
      className={classNames(
        textStyles.decorationNone,
        flexStyles.horiz050,
        flexStyles.alignCenter,
        pointerStyles.cursor,
        paddingStyles.p075,
        {
          [textStyles.secondary500]: active,
          [iconStyles.secondary500]: active,
          [textStyles.primary]: !active,
          [iconStyles.primary]: !active,
        },
      )}
    >
      <Icon iconType={item.iconType} />
      <DescriptionAccent>{item.label}</DescriptionAccent>
    </a>
  );
}
