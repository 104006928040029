import { ChangePassword, Login, PasswordReset, Signup } from "../components/auth";
import { PublicOutlet } from "./PublicOutlet";

export const AuthRoutes = {
  path: "auth",
  Component: PublicOutlet,
  children: [
    {
      path: "login",
      Component: Login,
    },
    {
      path: "signup",
      Component: Signup,
    },
    {
      path: "passwordReset",
      Component: PasswordReset,
    },
    {
      path: "passwordReset/:token",
      Component: ChangePassword,
    },
  ],
};
