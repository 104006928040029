import type { SVGProps } from "react";
const SvgFormDateField = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="FormDateField_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#FormDateField_svg__a)">
      <path
        d="M8.005 14.5a1.942 1.942 0 0 1-1.422-.579A1.919 1.919 0 0 1 6 12.505c0-.559.193-1.033.579-1.422a1.919 1.919 0 0 1 1.416-.583c.559 0 1.033.193 1.422.579.389.385.583.858.583 1.416 0 .559-.193 1.033-.579 1.422a1.919 1.919 0 0 1-1.416.583ZM4.5 18c-.412 0-.766-.15-1.06-.448A1.452 1.452 0 0 1 3 16.5v-11c0-.403.147-.753.44-1.052A1.433 1.433 0 0 1 4.5 4H6V2h1.5v2h5V2H14v2h1.5c.412 0 .766.15 1.06.448.293.299.44.65.44 1.052v11c0 .403-.147.753-.44 1.052A1.433 1.433 0 0 1 15.5 18h-11Zm0-1.5h11V9h-11v7.5Zm0-9h11v-2h-11v2Z"
        fill="#F0A056"
      />
    </g>
  </svg>
);
export default SvgFormDateField;
