import classNames from "classnames";
import type { ReactNode } from "react";
import { Link } from "react-router";
import {
  DotMenu,
  HeaderMain,
  Icon,
  type PropsWithOptionalDotMenu,
  SubheaderMain,
  TextAccent,
  flexStyles,
  paddingStyles,
  widthStyles,
} from "ui";

export type BackTo = { to: string; label?: string };
interface Props extends PropsWithOptionalDotMenu {
  header: string;
  title?: string;
  subHeaderElement?: ReactNode;
  backTo?: BackTo;
}

export function OverlayHeader({ backTo, header, links, title, subHeaderElement }: Props) {
  return (
    <div className={classNames(flexStyles.vert, flexStyles.alignCenter, paddingStyles.pt200, paddingStyles.pb125)}>
      <div className={classNames(flexStyles.vert125, widthStyles.full, widthStyles.maxWidth4225, paddingStyles.px125)}>
        {backTo && (
          <Link to={backTo.to} relative="path" className={classNames(flexStyles.horiz062)}>
            <Icon iconType="BackArrow" />
            <TextAccent>Back{backTo?.label && ` to ${backTo.label}`}</TextAccent>
          </Link>
        )}
        <div className={flexStyles.horizApart}>
          <HeaderMain>{header}</HeaderMain>
          {links && <DotMenu links={links} vertical />}
        </div>
        {subHeaderElement}
        {title && <SubheaderMain>{title}</SubheaderMain>}
      </div>
    </div>
  );
}
