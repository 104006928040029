import { type Schema, lazy } from "yup";
import {
  type AppointmentBlockPayload,
  AppointmentBlockSchema,
  type PersistedAppointmentBlock,
} from "./AppointmentBlock";
import {
  type AppointmentLinkBlockPayload,
  AppointmentLinkBlockSchema,
  type PersistedAppointmentLinkBlock,
} from "./AppointmentLinkBlock";
import { type EmailBlockPayload, EmailBlockSchema, type PersistedEmailBlock } from "./EmailBlock";
import { type FormLinkBlockPayload, FormLinkBlockSchema, type PersistedFormLinkBlock } from "./FormLinkBlock";
import { type ImageBlockPayload, ImageBlockSchema, type PersistedImageBlock } from "./ImageBlock";
import { type LinkBlockPayload, LinkBlockSchema, type PersistedLinkBlock } from "./LinkBlock";
import { type PageBlockPayload, PageBlockSchema, type PersistedPageBlock } from "./PageBlock";
import { type PersistedPhoneBlock, type PhoneBlockPayload, PhoneBlockSchema } from "./PhoneBlock";
import {
  type PersistedSocialLinksBlock,
  type SocialLinksBlockPayload,
  SocialLinksBlockSchema,
} from "./SocialLinksBlock";
import { type PersistedTextBlock, type TextBlockPayload, TextBlockSchema } from "./TextBlock";
import {
  type PersistedTwoColumnsBlock,
  type TwoColumnsBlockPayload,
  TwoColumnsBlockSchema,
} from "./TwoColumnsBlockSchema";
import { type PersistedVideoBlock, type VideoBlockPayload, VideoBlockSchema } from "./VideoBlock";

export type BlockKind =
  | "AppointmentBlock"
  | "AppointmentLinkBlock"
  | "EmailBlock"
  | "FormLinkBlock"
  | "ImageBlock"
  | "LinkBlock"
  | "PageBlock"
  | "PhoneBlock"
  | "SocialLinksBlock"
  | "TextBlock"
  | "TwoColumnsBlock"
  | "VideoBlock"
  | "VideoLinkBlock";

export const BlockKindValues: BlockKind[] = [
  "AppointmentBlock",
  "AppointmentLinkBlock",
  "EmailBlock",
  "FormLinkBlock",
  "ImageBlock",
  "LinkBlock",
  "PageBlock",
  "PhoneBlock",
  "SocialLinksBlock",
  "TextBlock",
  "TwoColumnsBlock",
  "VideoBlock",
  "VideoLinkBlock",
];

export type InlineEditableBlockKinds =
  | "TextBlock"
  | "ImageBlock"
  | "VideoBlock"
  | "VideoLinkBlock"
  | "LinkBlock"
  | "EmailBlock"
  | "TwoColumnsBlock"
  | "PhoneBlock";
export const InlineEditableBlockKindValues: BlockKind[] = [
  "TextBlock",
  "ImageBlock",
  "VideoLinkBlock",
  "VideoBlock",
  "LinkBlock",
  "PhoneBlock",
  "TwoColumnsBlock",
  "EmailBlock",
];

export type BlockTypeMap = {
  AppointmentBlock: AppointmentBlockPayload;
  AppointmentLinkBlock: AppointmentLinkBlockPayload;
  EmailBlock: EmailBlockPayload;
  FormLinkBlock: FormLinkBlockPayload;
  ImageBlock: ImageBlockPayload;
  LinkBlock: LinkBlockPayload;
  PageBlock: PageBlockPayload;
  PhoneBlock: PhoneBlockPayload;
  SocialLinksBlock: SocialLinksBlockPayload;
  TextBlock: TextBlockPayload;
  TwoColumnsBlock: TwoColumnsBlockPayload;
  VideoBlock: VideoBlockPayload;
  VideoLinkBlock: VideoBlockPayload;
};

export type EditableBlockKinds = "AppointmentLinkBlock" | "FormLinkBlock" | "SocialLinksBlock";

export type BlockPayload =
  | AppointmentBlockPayload
  | AppointmentLinkBlockPayload
  | EmailBlockPayload
  | FormLinkBlockPayload
  | ImageBlockPayload
  | LinkBlockPayload
  | PageBlockPayload
  | PhoneBlockPayload
  | SocialLinksBlockPayload
  | TextBlockPayload
  | TwoColumnsBlockPayload
  | VideoBlockPayload;

export type PersistedInlineEditableBlock =
  | PersistedTextBlock
  | PersistedImageBlock
  | PersistedVideoBlock
  | PersistedPhoneBlock
  | PersistedEmailBlock
  | PersistedTwoColumnsBlock
  | PersistedLinkBlock;

export type PersistedBlock =
  | PersistedInlineEditableBlock
  | PersistedAppointmentBlock
  | PersistedAppointmentLinkBlock
  | PersistedFormLinkBlock
  | PersistedPageBlock
  | PersistedSocialLinksBlock;

const blockSchemas: Record<BlockKind, Schema> = {
  AppointmentBlock: AppointmentBlockSchema,
  AppointmentLinkBlock: AppointmentLinkBlockSchema,
  EmailBlock: EmailBlockSchema,
  FormLinkBlock: FormLinkBlockSchema,
  ImageBlock: ImageBlockSchema,
  LinkBlock: LinkBlockSchema,
  PageBlock: PageBlockSchema,
  PhoneBlock: PhoneBlockSchema,
  SocialLinksBlock: SocialLinksBlockSchema,
  TextBlock: TextBlockSchema,
  TwoColumnsBlock: TwoColumnsBlockSchema,
  VideoBlock: VideoBlockSchema,
  VideoLinkBlock: VideoBlockSchema,
};

export const LazyBlockSchema = lazy((value) => blockSchemas[value.kind as BlockKind]);

export async function validateBlock(block: BlockPayload): Promise<BlockPayload> {
  return blockSchemas[block.kind].validate(block);
}
