import classNames from "classnames";
import type { PropsWithChildren } from "react";
import { floatStyles, marginStyles } from "../../styles";
import { ImageCard } from "../ImageCard";
import { TextBlockCard } from "../TextBlockCard";
import type { Props } from "./Props";

export function WrappingText({ block }: Props) {
  const { leftTextBlock, leftImageBlock, rightTextBlock, rightImageBlock, widthPercentage } = block;
  const imageBlock = leftImageBlock || rightImageBlock;
  const textBlock = leftTextBlock || rightTextBlock;

  if (!imageBlock || !textBlock) {
    return null;
  }

  const Wrapper = ({ children }: PropsWithChildren) => {
    const width = leftImageBlock ? widthPercentage : 100 - widthPercentage;
    return (
      <>
        <ImageCard
          style={{ width: `${width}%` }}
          disabled
          className={classNames(marginStyles.mb100, {
            [floatStyles.left]: leftImageBlock,
            [marginStyles.mie100]: leftImageBlock,
            [floatStyles.right]: rightImageBlock,
            [marginStyles.mis100]: rightImageBlock,
          })}
          block={imageBlock}
        />
        {children}
        <div className={floatStyles.clear} />
      </>
    );
  };

  return <TextBlockCard block={textBlock} TextWrapper={Wrapper} />;
}
