import type { FC } from "react";
import { Automation } from "./Automation";
import { BasicDetails } from "./BasicDetails";
import { Schedule } from "./Schedule";
import { Utilities } from "./Utilities";

export type Steps = "BasicDetails" | "Schedule" | "Utilities" | "Automation";

export interface StepProps {
  id?: string;
}

export type FormStep = {
  component: FC<StepProps>;
  title: string;
  description: string;
  hasDelete: boolean;
  next: Steps | null;
  prev: Steps | null;
};

export const FormSteps = new Map<Steps, FormStep>([
  [
    "BasicDetails",
    {
      component: BasicDetails,
      title: "Appointment Basics",
      description: "Create an appointment and accept bookings.",
      hasDelete: true,
      next: "Schedule",
      prev: null,
    },
  ],
  [
    "Schedule",
    {
      component: Schedule,
      title: "Availability",
      description: "Select availability times for this appointment.",
      hasDelete: false,
      next: "Utilities",
      prev: "BasicDetails",
    },
  ],
  [
    "Utilities",
    {
      component: Utilities,
      title: "Utilities",
      description: "Gather client details with a form or set up payments.",
      hasDelete: false,
      next: "Automation",
      prev: "Schedule",
    },
  ],
  [
    "Automation",
    {
      component: Automation,
      title: "Automations",
      description: "Manage automated messages sent to your attendees.",
      hasDelete: false,
      next: null,
      prev: "Utilities",
    },
  ],
]);

export const stepPath = (id: string, step: Steps | null) => {
  const base = `/appointments/${id}`;
  switch (step) {
    case "BasicDetails":
      return base;
    case "Schedule":
      return `${base}/schedule`;
    case "Utilities":
      return `${base}/utilities`;
    case "Automation":
      return `${base}/automation`;
    default:
      return "/content";
  }
};
