import classNames from "classnames";
import { textStyles } from "../styles";
import type { OnClickHandler, PropsWithClassNameAndChildren, PropsWithDir } from "../types";

interface Props extends PropsWithClassNameAndChildren, PropsWithDir {
  onClick?: OnClickHandler;
}
export function TextMain({ dir, children, className, onClick }: Props) {
  return (
    <p
      dir={dir}
      onClick={onClick}
      className={classNames(textStyles.size100, textStyles.lsColor, textStyles.weight400, className)}
    >
      {children}
    </p>
  );
}

export function TextItalic({ children, className, onClick }: Props) {
  return (
    <TextMain className={classNames(textStyles.italic, className)} onClick={onClick}>
      {children}
    </TextMain>
  );
}

export function TextSemiAccent({ children, className, onClick }: Props) {
  return (
    <TextMain className={classNames(textStyles.weight500, className)} onClick={onClick}>
      {children}
    </TextMain>
  );
}

export function TextAccent({ children, className, onClick }: Props) {
  return (
    <TextMain className={classNames(textStyles.weight600, className)} onClick={onClick}>
      {children}
    </TextMain>
  );
}
