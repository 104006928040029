"use client";

import classNames from "classnames";
import { useState } from "react";
import ReactCropper from "react-easy-crop";
import { Button } from "../Button";
import { Modal } from "../Modal";
import {
  bgStyles,
  flexStyles,
  heightStyles,
  iconStyles,
  marginStyles,
  overflowStyles,
  paddingStyles,
  positionStyles,
  widthStyles,
} from "../styles";
import type { OnClickHandler } from "../types";
import { type CroppedArea, type Point, getCroppedImg } from "./helpers";

export type CropperAspect = "Round" | "Square" | "Wide";

const Aspects: { [key in CropperAspect]: number } = {
  Square: 1,
  Round: 1,
  Wide: 16 / 9,
};

interface Props {
  image: string;
  onSubmit: (file: Blob) => Promise<void>;
  onCancel: OnClickHandler;
  aspect: CropperAspect;
}
export function Cropper({ image, onSubmit, onCancel, aspect }: Props) {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState<CroppedArea>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const handleCropComplete = (_unused: object, croppedAreaPixels: CroppedArea) => {
    setCroppedArea(croppedAreaPixels);
  };

  const handleSubmit = async () => {
    const croppedImage = await getCroppedImg(image, croppedArea);
    onSubmit(croppedImage);
  };

  return (
    <div
      className={classNames(
        iconStyles.neutral600,
        positionStyles.fixed,
        positionStyles.cover,
        positionStyles.zIndex200,
        overflowStyles.scroll,
      )}
    >
      <Modal header="Crop image" className={classNames(flexStyles.vert200, paddingStyles.p125)} onClose={onCancel}>
        <div
          className={classNames(
            bgStyles.checked,
            heightStyles.height2000,
            widthStyles.full,
            positionStyles.relative,
            widthStyles.maxContent,
            marginStyles.centered,
          )}
        >
          <ReactCropper
            image={image}
            crop={crop}
            zoom={zoom}
            cropShape={aspect === "Round" ? "round" : "rect"}
            aspect={Aspects[aspect]}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={handleCropComplete}
          />
        </div>
        <div className={classNames(flexStyles.horiz200, flexStyles.justifyCenter)}>
          <Button onClick={handleSubmit}>Crop and upload</Button>
        </div>
      </Modal>
    </div>
  );
}
