import type { SVGProps } from "react";

const SvgFollowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.63 23.77h4.795c.38 1.6 1.825 2.75 3.545 2.75h.055c1.72 0 3.165-1.15 3.545-2.75h4.795c.415 0 .73-.345.73-.76v-4.245c0-.685-.55-1.245-1.2-1.25v-2.68c0-4.095-3.155-7.47-7.15-7.845V5.55a.749.749 0 1 0-1.5 0v1.44c-3.995.38-7.15 3.755-7.15 7.845v2.68c-.65.005-1.2.565-1.2 1.25v4.245c.005.415.32.76.735.76Zm8.4 1.25h-.055c-.885 0-1.64-.5-1.97-1.25H18c-.33.75-1.09 1.25-1.97 1.25Zm7.57-2.75H8.4v-3.25h15.2v3.25ZM15.845 8.455a.727.727 0 0 0 .305 0c3.45.075 6.25 2.91 6.25 6.38v2.685H9.6v-2.685c0-3.47 2.795-6.305 6.245-6.38Z"
      fill="#F0A056"
    />
  </svg>
);
export default SvgFollowUp;
