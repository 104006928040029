"use client";

import classNames from "classnames";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { RawIcon } from "../Icon";
import { flexStyles, pointerStyles } from "../styles";
import { TextAccent, TextMain } from "../typography";

interface Props {
  options: string[];
  name: string;
  otherOption: boolean;
  label: string;
  disabled?: boolean;
}
export function SingleChoice({ options, name, label, otherOption, disabled = false }: Props) {
  const { register, setValue } = useFormContext();
  const selectedOption = useWatch({ name });

  useEffect(() => {
    register(name);
  }, [register, name]);

  function onClick(option: string) {
    return () => {
      if (disabled) return;
      setValue(name, option, { shouldValidate: true });
    };
  }
  function radioIcon(option: string) {
    return selectedOption === option ? "RadioChecked" : "RadioUnchecked";
  }

  return (
    <div className={flexStyles.vert050}>
      <TextAccent>{label}</TextAccent>
      {options.map((option, index) => (
        <div
          className={classNames(flexStyles.horiz062, pointerStyles.cursor)}
          // biome-ignore lint:
          key={`${option}_${index}`}
          onClick={onClick(option)}
        >
          <RawIcon iconType={radioIcon(option)} />
          <TextMain>{option}</TextMain>
        </div>
      ))}
      {otherOption && (
        <div className={classNames(flexStyles.horiz062, pointerStyles.cursor)} onClick={onClick("Other")}>
          <RawIcon iconType={radioIcon("Other")} />
          <TextMain>Other</TextMain>
        </div>
      )}
    </div>
  );
}
