import type { PersistedPage } from "schemas";
import { useLiteSiteStore } from "../store";

export function setPage(page: PersistedPage) {
  useLiteSiteStore.setState(({ pages }) => ({
    pages: pages.set(page.id, page),
  }));

  if (page.handle === "/") {
    useLiteSiteStore.setState({ homePage: page });
  }
}
