import classNames from "classnames";
import type { PersistedTheme } from "schemas";
import { RawCheckbox, TextMain, flexStyles, paddingStyles, pointerStyles } from "ui";
import { setDefaultCampaignTheme } from "../../store";

export interface Props {
  theme: PersistedTheme;
}
export function DefaultThemeCheckbox({ theme }: Props) {
  const on = theme.defaultCampaignTheme;

  const onClick = async () => {
    await setDefaultCampaignTheme(theme, !on);
  };

  return (
    <div className={classNames(paddingStyles.py125, flexStyles.horiz075)}>
      <RawCheckbox on={on} onClick={onClick} />
      <TextMain className={pointerStyles.cursor} onClick={onClick}>
        Remember my choices next time.
      </TextMain>
    </div>
  );
}
