import classNames from "classnames";
import { Fragment } from "react";
import type { PersistedBlock } from "schemas";
import { textStyles } from "../styles";
import { DescriptionItalic } from "../typography";
import { AppointmentCard } from "./AppointmentCard";
import { ButtonCard } from "./ButtonCard";
import { ImageCard } from "./ImageCard";
import { SocialLinksCard } from "./SocialLinksCard";
import { TextBlockCard } from "./TextBlockCard";
import { TwoColumnsBlockCard } from "./TwoColumnsBlockCard/TwoColumnsBlockCard";
import { VideoCard } from "./VideoCard";

interface Props {
  block: PersistedBlock;
  editable: boolean;
  lazy: boolean;
  navigate: (path: string) => Promise<void>;
}
export function BlockCard({ block, editable, lazy, navigate }: Props) {
  switch (block.kind) {
    case "TextBlock":
      return <TextBlockCard block={block} />;
    case "ImageBlock":
      return <ImageCard block={block} disabled={editable} />;
    case "VideoBlock":
      return <VideoCard disabled={editable} block={block} lazy={lazy} />;
    case "TwoColumnsBlock":
      return <TwoColumnsBlockCard block={block} />;
    case "EmailBlock":
    case "FormLinkBlock":
    case "PhoneBlock":
    case "LinkBlock":
    case "PageBlock":
      return <ButtonCard block={block} navigate={navigate} editable={editable} />;
    case "SocialLinksBlock":
      return <SocialLinksCard block={block} />;
    case "AppointmentBlock":
    case "AppointmentLinkBlock": {
      const onClick = async () => {
        navigate(block.buttonUrl);
      };

      return (
        <Fragment key={`appt-${block.id}`}>
          <AppointmentCard
            label={block.label}
            image={block.image}
            disabled={editable}
            header={block.header}
            description={block.description}
            tags={block.tags}
            onClick={onClick}
          />
          {editable && !block.public && (
            <DescriptionItalic className={classNames(textStyles.neutral300, textStyles.textCenter)}>
              Hidden
            </DescriptionItalic>
          )}
        </Fragment>
      );
    }
  }
}
