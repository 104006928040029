import classNames from "classnames";
import { borderStyles, flexStyles, heightStyles, iconStyles, squareStyles } from "../../styles";
import styles from "./FileDrop.module.css";
import { FilePrompt } from "./FilePrompt";

interface Props {
  prompt: string;
  round?: boolean;
}
export function FileLanding({ prompt, round = false }: Props) {
  return (
    <div
      className={classNames(
        flexStyles.alignSelfCenter,
        iconStyles.businessDarkOrSecondary300,
        heightStyles.min500,
        styles.wrapper,
        {
          [borderStyles.radius025]: !round,
          [borderStyles.radiusRound]: round,
          [squareStyles.square1000]: round,
        },
      )}
    >
      <FilePrompt prompt={prompt} />
    </div>
  );
}
