import classNames from "classnames";
import type { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { Icon, bgStyles, flexStyles, iconStyles, widthStyles } from "ui";

interface Props {
  dragHandleProps?: DraggableProvidedDragHandleProps | undefined | null;
}
export function DragHandle({ dragHandleProps }: Props) {
  if (!dragHandleProps) {
    return null;
  }

  return (
    <div
      className={classNames(
        iconStyles.neutral300,
        flexStyles.horiz,
        flexStyles.justifyCenter,
        flexStyles.alignCenter,
        flexStyles.shrink0,
        widthStyles.width200,
        bgStyles.neutral200,
      )}
      {...dragHandleProps}
    >
      <Icon iconType="Drag" />
    </div>
  );
}
