import { type Asserts, object, string } from "yup";
import { type FontFamily, FontFamilyValues, TextAlignmentValues } from "../../themes";

export const TextBlockSchema = object({
  text: string().nonNullable().defined(),
  fontFamily: string().oneOf(FontFamilyValues).nullable(),
  textAlignment: string().oneOf(TextAlignmentValues).required(),
  kind: string().oneOf(["TextBlock"]).required(),
}).noUnknown();

export const StrictTextBlockSchema = TextBlockSchema.shape({
  text: string().required("Text is required"),
});
export type TextBlockPayload = Asserts<typeof TextBlockSchema> & {
  kind: "TextBlock";
};
export type PersistedTextBlock = TextBlockPayload & {
  id: string;
  fontFamily: FontFamily;
};
