import type { SVGProps } from "react";

const SvgChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="ChevronDown_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#ChevronDown_svg__a)">
      <path d="m10 14.487-7-7L4.487 6 10 11.513 15.513 6 17 7.487l-7 7Z" fill="#465357" />
    </g>
  </svg>
);
export default SvgChevronDown;
