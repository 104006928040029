import type { SVGProps } from "react";

const SvgAddButtons = (props: SVGProps<SVGSVGElement>) => (
  <svg width={81} height={80} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#AddButtons_svg__a)">
      <path
        d="M62.58 38.1H18.13c-1.86 0-3.38-1.51-3.38-3.38V24.68c0-1.86 1.51-3.38 3.38-3.38h44.45c1.86 0 3.38 1.51 3.38 3.38v10.05c0 1.86-1.51 3.38-3.38 3.38v-.01ZM18.13 24.3a.38.38 0 0 0-.38.38v10.05c0 .21.17.38.38.38h44.45c.21 0 .38-.17.38-.38V24.68a.38.38 0 0 0-.38-.38H18.13ZM40.64 18.19c-.83 0-1.5-.67-1.5-1.5V10.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v6.19c0 .83-.67 1.5-1.5 1.5ZM33.76 18.08c-.46 0-.91-.21-1.21-.61l-3.41-4.63a1.501 1.501 0 1 1 2.42-1.78l3.41 4.63a1.501 1.501 0 0 1-1.21 2.39ZM47.53 18.08a1.501 1.501 0 0 1-1.21-2.39l3.41-4.63a1.501 1.501 0 1 1 2.42 1.78l-3.41 4.63c-.29.4-.75.61-1.21.61Z"
        fill="#0FD6CA"
      />
      <path
        d="M56.44 30.9H24.27c-.66 0-1.2-.54-1.2-1.2 0-.66.54-1.2 1.2-1.2h32.17c.66 0 1.2.54 1.2 1.2 0 .66-.54 1.2-1.2 1.2Z"
        fill="#B0F3EF"
      />
      <path
        d="M61.853 44.077c-1.605 0-2.897 1.324-2.897 2.947v-.966c0-1.836-1.462-3.333-3.277-3.333-1.814 0-3.276 1.488-3.276 3.333v-.783c0-1.835-1.463-3.333-3.277-3.333s-3.277 1.488-3.277 3.333V33.333c0-1.835-1.463-3.333-3.277-3.333-1.804 0-3.277 1.488-3.277 3.333v23.15l-1.32-1.845-2.28-3.295c-1.044-1.507-3.086-1.865-4.558-.802a3.367 3.367 0 0 0-.788 4.638l5.698 8.232 3.647 5.275c.57.83 1.501 1.314 2.498 1.314h17.581c1.444 0 2.697-1.034 2.992-2.473l.693-3.382a63.076 63.076 0 0 0 1.292-12.676v-4.445c0-1.633-1.301-2.947-2.897-2.947Z"
        fill="#B0F3EF"
      />
      <path
        d="M52.29 50.324c-.627 0-1.14-.512-1.14-1.139v-3.968a2.14 2.14 0 0 0-2.136-2.136 2.139 2.139 0 0 0-2.136 2.136v3.968c0 .627-.512 1.14-1.139 1.14-.626 0-1.139-.513-1.139-1.14v-3.968a4.418 4.418 0 0 1 4.414-4.414 4.412 4.412 0 0 1 4.414 4.414v3.968c0 .627-.512 1.14-1.139 1.14Z"
        fill="#0FD6CA"
      />
      <path
        d="M58.84 50.324c-.627 0-1.14-.512-1.14-1.139v-3.208a2.14 2.14 0 0 0-2.136-2.136 2.139 2.139 0 0 0-2.136 2.136v3.208c0 .627-.512 1.14-1.139 1.14-.626 0-1.139-.513-1.139-1.14v-3.208a4.418 4.418 0 0 1 4.414-4.414 4.412 4.412 0 0 1 4.414 4.414v3.208c0 .627-.512 1.14-1.139 1.14Z"
        fill="#0FD6CA"
      />
      <path
        d="M59.656 70.639H42.085a4.186 4.186 0 0 1-3.427-1.775l-9.341-13.271a4.41 4.41 0 0 1 1.063-6.142 4.419 4.419 0 0 1 3.294-.74c1.158.2 2.174.845 2.848 1.803l1.528 2.174V33.484a4.418 4.418 0 0 1 4.414-4.414 4.412 4.412 0 0 1 4.415 4.414v15.701c0 .627-.513 1.14-1.14 1.14-.626 0-1.139-.513-1.139-1.14v-15.7a2.139 2.139 0 0 0-2.136-2.136 2.14 2.14 0 0 0-2.136 2.135V56.23c0 .494-.313.93-.787 1.082a1.135 1.135 0 0 1-1.272-.408l-1.32-1.813-2.297-3.256a2.12 2.12 0 0 0-1.377-.873 2.078 2.078 0 0 0-1.594.36 2.12 2.12 0 0 0-.874 1.377c-.095.56.029 1.13.361 1.594l9.34 13.271a1.9 1.9 0 0 0 1.567.807h17.571a1.91 1.91 0 0 0 1.87-1.519l.693-3.322a60.038 60.038 0 0 0 1.263-12.217v-4.367c0-.968-.788-1.756-1.756-1.756-.969 0-1.757.788-1.757 1.756v2.25c0 .626-.512 1.139-1.139 1.139-.626 0-1.139-.513-1.139-1.14v-2.249a4.043 4.043 0 0 1 4.035-4.034 4.043 4.043 0 0 1 4.034 4.034v4.367c0 4.253-.446 8.524-1.32 12.692l-.693 3.322a4.213 4.213 0 0 1-4.1 3.332l.019-.02Z"
        fill="#0FD6CA"
      />
    </g>
    <defs>
      <clipPath id="AddButtons_svg__a">
        <path fill="#fff" transform="translate(14.75 9)" d="M0 0h57.01v61.86H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgAddButtons;
