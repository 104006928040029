import classNames from "classnames";
import { Card, TextMain, flexStyles, paddingStyles } from "ui";
import { Button } from "../ui";

export function EmptyDomains() {
  return (
    <Card className={classNames(flexStyles.vert125, flexStyles.alignCenter, paddingStyles.p125)}>
      <TextMain>Send your email campaigns from your domain</TextMain>
      <Button to="new">+ ADD DOMAIN</Button>
    </Card>
  );
}
