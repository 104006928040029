import type { EmailSettingsPayload, PersistedEmailSettings } from "schemas";
import { apiRequest } from "../apiRequest";

export async function updateEmailSettings(payload: EmailSettingsPayload) {
  const { emailSettings } = await apiRequest<{ emailSettings: PersistedEmailSettings }>({
    url: "emailSettings",
    method: "POST",
    body: JSON.stringify(payload),
  });

  return emailSettings;
}
