import classNames from "classnames";
import { positionStyles } from "../styles";
import type { PropsWithResize } from "../types";
import { Handle } from "./Handle";

interface Props extends PropsWithResize {
  type: "left" | "right";
}
export function ResizeHandle({ type, onMouseDown, onTouchStart }: Props) {
  return (
    <Handle
      style={{ top: "calc(50% - 1.5625rem)" }}
      className={classNames(positionStyles.absolute, {
        [positionStyles.right006]: type === "right",
        [positionStyles.left006]: type === "left",
      })}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
    />
  );
}
