import { Show } from "../components/booking";
import { Edit as EditContact, Note, Show as ShowContact } from "../components/contacts";
import { PrivateOutlet } from "./PrivateOutlet";

export const BookingRoutes = {
  path: "bookings",
  Component: PrivateOutlet,
  children: [
    {
      path: ":bookingId",
      Component: Show,
    },
    {
      path: ":bookingId/contacts/:contactId",
      Component: ShowContact,
    },
    {
      path: ":bookingId/contacts/:contactId/edit",
      Component: EditContact,
    },
    {
      path: ":bookingId/contacts/:contactId/note",
      Component: Note,
    },
  ],
};
