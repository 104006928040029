import { useLocation, useNavigate } from "react-router";
import { type BusinessPayload, BusinessSchema } from "schemas";
import { FormProvider, FormTag, HeaderMain, InlineTextInput, type PropsWithClassName, textStyles } from "ui";
import { updateBusiness, useCurrentBusiness } from "../../../store";
import { EditWrapper } from "../../blocks";
import { Menu } from "./Menu";

interface Props extends PropsWithClassName {
  hasLabel?: boolean;
}
export function EditBusinessName({ hasLabel, className }: Props) {
  const business = useCurrentBusiness();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const open = pathname.endsWith("blocks/name");

  const onClick = () => {
    const to = open ? "." : "blocks/name";
    navigate(to, { relative: "path", preventScrollReset: true });
  };

  const onSubmit = async (payload: BusinessPayload) => {
    await updateBusiness(payload);
    navigate(".");
  };

  const label = hasLabel ? "Edit Your Business Name" : undefined;

  const editor = open && (
    <FormProvider<BusinessPayload> schema={BusinessSchema} defaultValues={business}>
      <FormTag<BusinessPayload> onSubmit={onSubmit}>
        <InlineTextInput label={label} size="Header" name="name" placeholder="Your business name" onSubmit={onSubmit} />
        <Menu<BusinessPayload> onSubmit={onSubmit} onCancel={() => navigate(".")} />
      </FormTag>
    </FormProvider>
  );

  return (
    <EditWrapper className={textStyles.center} open={open} onClick={onClick} editElement={editor}>
      <HeaderMain className={className}>{business.name}</HeaderMain>
    </EditWrapper>
  );
}
