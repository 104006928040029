import classNames from "classnames";
import { type MouseEvent, type PropsWithChildren, useState } from "react";
import {
  Loader,
  type OnClickHandler,
  type PropsWithClassName,
  bgStyles,
  borderStyles,
  loaderStyles,
  pointerStyles,
} from "ui";

interface Props extends PropsWithClassName, PropsWithChildren {
  disabled: boolean;
  htmlButtonType: "button" | "submit";
  onClick?: OnClickHandler;
}

export function ButtonLink({ onClick, className, children, htmlButtonType, disabled }: Props) {
  const [loading, setLoading] = useState(false);
  const neuterClick = async (e: MouseEvent) => {
    if (onClick) {
      e.preventDefault();
      setLoading(true);
      await onClick();
      setLoading(false);
    }
  };

  return (
    <button
      disabled={disabled}
      className={classNames(bgStyles.none, borderStyles.none, { [pointerStyles.cursor]: !disabled }, className)}
      type={htmlButtonType}
      onClick={neuterClick}
    >
      {loading ? <Loader fitted className={loaderStyles.size150} /> : children}
    </button>
  );
}
