import classNames from "classnames";
import { DateTime } from "luxon";
import type { ReactElement } from "react";
import type { FormQuestionType } from "schemas";
import { DescriptionAccent, displayStyles, heightStyles, imageStyles, marginStyles, widthStyles } from "ui";

export function answerToComponent(questionType: FormQuestionType, answer: string): ReactElement {
  switch (questionType) {
    case "TextField":
    case "NumberField":
    case "SingleChoice":
      return <DescriptionAccent>{answer}</DescriptionAccent>;
    case "MultipleChoice":
      // @ts-expect-error state
      return <DescriptionAccent>{(answer as string[]).join(", ")}</DescriptionAccent>;
    case "DateField":
      return <DescriptionAccent>{DateTime.fromISO(answer).toLocaleString(DateTime.DATE_MED)}</DescriptionAccent>;
    case "FileField":
      return (
        <img
          className={classNames(
            displayStyles.block,
            imageStyles.fitContain,
            marginStyles.mbs050,
            widthStyles.maxWidthFull,
            heightStyles.max2000,
          )}
          alt="answer"
          src={answer}
        />
      );
  }
}
