import classNames from "classnames";
import type { FC, PropsWithChildren } from "react";
import type { PersistedTextBlock } from "schemas";
import { textStyles, widthStyles } from "../styles";
import { Markdown } from "../typography";

interface Props {
  block: PersistedTextBlock;
  TextWrapper?: FC<PropsWithChildren>;
}
export function TextBlockCard({ block, TextWrapper }: Props) {
  return (
    <Markdown
      fontFamily={block.fontFamily}
      className={classNames(textStyles.fontTheme, widthStyles.full, {
        [textStyles.center]: block.textAlignment === "Center",
      })}
      TextWrapper={TextWrapper}
    >
      {block.text}
    </Markdown>
  );
}
