import type { SVGProps } from "react";

const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#Close_svg__a)" fill="#465357">
      <path d="M12.95 1.636 1.636 12.95l1.414 1.414L14.364 3.05 12.95 1.636Z" />
      <path d="M1.636 3.05 12.95 14.364l1.414-1.414L3.05 1.636 1.636 3.05Z" />
    </g>
    <defs>
      <clipPath id="Close_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgClose;
