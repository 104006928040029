import type { SVGProps } from "react";

const SvgMultipleCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg width={80} height={80} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill="#FFF3E5" d="M5 32.163h42.956v35.935H5z" />
    <path fill="#FF6B00" d="M5 31.956h42.956v10.12H5z" />
    <path fill="#FFE1BE" d="M14.844 27h3.941v9.758h-3.941zM34.547 27h3.941v9.758h-3.941z" />
    <path fill="#FF6B00" d="M9.337 46h7.641v7.641H9.337z" />
    <path fill="#FBD09E" d="m30.585 20.121 42.605 5.48-4.585 35.642L26 55.763z" />
    <path fill="#FF6B00" d="m30.611 19.916 42.605 5.48-1.291 10.038-42.605-5.48z" />
    <path
      fill="#FFE1BE"
      d="m41.007 16.256 3.909.503-1.245 9.678-3.909-.503zM60.549 18.77l3.909.503-1.245 9.678-3.909-.503z"
    />
    <path fill="#F5A343" d="m33.121 34.398 7.579.975-.975 7.578-7.579-.974z" />
    <path
      d="M17.929 8.277c-1.536-.267-2.566 1.502-2.965 2.694-.374 1.12-.315 3.815 1.147 4.2.914.24 2.808-.258 2.477-1.467-.46-1.685-1.94-.413-2.493.514-.552.924-1.044 1.417-.985 2.556.071 1.358.705 2.55 1.912 3.238 1.037.59 2.584-.076 3.512-.632 1.034-.62 1.007-1.535.693-2.537-.232-.74-1.83.466-2.035.799-.54.873.021 2.023.46 2.853 1.204 2.282 4.34 1.58 6.285 1.049"
      stroke="#F5A343"
      strokeLinecap="round"
    />
  </svg>
);
export default SvgMultipleCalendar;
