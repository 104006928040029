import type {
  PersistedEmailBlock,
  PersistedFormLinkBlock,
  PersistedLinkBlock,
  PersistedPageBlock,
  PersistedPhoneBlock,
} from "schemas";
import type { IconType } from "../Icon";

import { CardButton } from "../Button";

type ButtonBlock =
  | PersistedEmailBlock
  | PersistedPhoneBlock
  | PersistedLinkBlock
  | PersistedPageBlock
  | PersistedFormLinkBlock;

interface Props {
  block: ButtonBlock;
  editable: boolean;
  navigate: (path: string) => Promise<void>;
}

const IconTypes: Record<"EmailBlock" | "PhoneBlock" | "LinkBlock" | "PageBlock" | "FormLinkBlock", IconType> = {
  EmailBlock: "Mail",
  FormLinkBlock: "FormsSymbol",
  LinkBlock: "OpenInNew",
  PageBlock: "ChevronRight",
  PhoneBlock: "PhoneEnabled",
};

export function ButtonCard({ block, editable, navigate }: Props) {
  const label = block.label;
  const iconType = IconTypes[block.kind];
  const onClick = editable ? undefined : async () => navigate(block.buttonUrl);

  return <CardButton onClick={onClick} label={label} iconType={iconType} />;
}
