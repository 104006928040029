import { Fragment, type ReactNode, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import type { BlockDestination, PersistedElement } from "schemas";
import { flexStyles } from "ui";
import { useNewBlock } from "../../hooks";
import { reorderPageElements } from "../../store";
import { reorderCampaignElements } from "../../store/campaigns/reorderCampaignElements";
import { Drop } from "../ui";
import { NewInlineBlock } from "../ui/InlineEditors";
import { BlockElement } from "./BlockElement";

interface Props {
  emptyBlock?: ReactNode;
  destination: BlockDestination;
  elements: PersistedElement[];
}
export function BlocksEditor({ destination, emptyBlock, elements }: Props) {
  const newBlock = useNewBlock();
  const maxPosition = elements.at(-1)?.position || 0;
  const [dirtyBlock, setDirtyBlock] = useState(false);

  // biome-ignore lint:
  const onDragEnd = async ({ draggableId, destination: dragDestination, source }: any) => {
    if (dragDestination && source.index !== dragDestination.index) {
      switch (destination.type) {
        case "Page":
          await reorderPageElements(destination.entity.id, draggableId, source.index + 1, dragDestination.index + 1);
          break;
        case "Campaign":
          await reorderCampaignElements(
            destination.entity.id,
            draggableId,
            source.index + 1,
            dragDestination.index + 1,
          );
          break;
      }
    }
  };

  return (
    <Drop onDragEnd={onDragEnd} className={flexStyles.vert100}>
      {!newBlock && emptyBlock}
      {elements.map((element, index) => (
        <Fragment key={element.id}>
          {newBlock?.destinationIndex === element.position && (
            <NewInlineBlock
              dirtyBlock={dirtyBlock}
              setDirtyBlock={setDirtyBlock}
              destination={destination}
              kind={newBlock.kind}
              destinationIndex={newBlock?.destinationIndex}
            />
          )}
          <Draggable draggableId={element.id} index={index}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.draggableProps}>
                <BlockElement
                  dirtyBlock={dirtyBlock}
                  setDirtyBlock={setDirtyBlock}
                  element={element}
                  destination={destination}
                  last={index === elements.length - 1}
                  dragHandleProps={provided.dragHandleProps}
                />
              </div>
            )}
          </Draggable>
        </Fragment>
      ))}
      {newBlock && (elements.length === 0 || newBlock.destinationIndex > maxPosition) && (
        <NewInlineBlock
          dirtyBlock={dirtyBlock}
          setDirtyBlock={setDirtyBlock}
          destination={destination}
          kind={newBlock.kind}
          destinationIndex={newBlock.destinationIndex}
        />
      )}
    </Drop>
  );
}
