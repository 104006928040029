"use client";

import classNames from "classnames";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import type { ScheduleItemPayload } from "schemas";
import { DayNames } from "../../consts/DayNames";
import { bgStyles, borderStyles, flexStyles, pointerStyles, squareStyles, textStyles } from "../styles";
import { TextSemiAccent } from "../typography";
import { Label } from "./Label";

interface Props {
  name: string;
  label: string;
}
export function DaySelector({ name, label }: Props) {
  const { register, setValue } = useFormContext();
  const value = useWatch({ name });

  useEffect(() => {
    register(name);
  });

  const onClick = (index: number, on: boolean) => {
    value[index].on = !on;
    setValue(name, value);
  };

  return (
    <div>
      <Label name={name}>{label}</Label>
      <div className={classNames(flexStyles.horiz125, flexStyles.alignCenter, flexStyles.justifyBetween)}>
        {value?.map((item: ScheduleItemPayload, index: number) => {
          const { on } = item;
          return (
            <button
              className={classNames(
                flexStyles.horiz,
                flexStyles.alignCenter,
                flexStyles.justifyCenter,
                borderStyles.none,
                borderStyles.radiusRound,
                pointerStyles.cursor,
                squareStyles.square225,
                {
                  [bgStyles.neutral200]: !on,
                  [textStyles.neutral400]: !on,
                  [bgStyles.secondary300]: on,
                  [textStyles.neutral0]: on,
                },
              )}
              // biome-ignore lint:
              key={`dsb-${index}`}
              type="button"
              onClick={() => onClick(index, on)}
            >
              <TextSemiAccent>{DayNames[index][0]}</TextSemiAccent>
            </button>
          );
        })}
      </div>
    </div>
  );
}
