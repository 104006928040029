import { Card, type PropsWithOnClick, SmallTextMain, TextAccent, flexStyles, paddingStyles, textStyles } from "ui";

interface Props extends PropsWithOnClick {
  label: string;
  text: string;
}
export function DetailsCard({ label, text, onClick }: Props) {
  return (
    <Card onClick={onClick} className={paddingStyles.p100}>
      <div className={flexStyles.vert025}>
        <SmallTextMain className={textStyles.neutral400}>{label}</SmallTextMain>
        <TextAccent>{text}</TextAccent>
      </div>
    </Card>
  );
}
