import type { SVGProps } from "react";

const SvgGoogleCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#GoogleCalendar_svg__a)">
      <path d="M14.583 5.417H5.417v9.166h9.166V5.417Z" fill="#fff" />
      <path
        d="m10.7 8.717.42.6.66-.48v3.48h.72v-4.56h-.6l-1.2.96ZM9.56 9.894c.26-.24.422-.571.422-.937 0-.728-.64-1.32-1.424-1.32-.668 0-1.238.42-1.388 1.022l.69.175c.07-.276.363-.477.698-.477.392 0 .712.269.712.6 0 .33-.32.6-.712.6h-.415v.72h.415c.45 0 .83.313.83.683 0 .377-.36.683-.804.683-.401 0-.744-.254-.798-.59l-.703.115c.11.681.755 1.195 1.5 1.195.837 0 1.517-.63 1.517-1.403 0-.426-.21-.809-.54-1.066Z"
        fill="#1E88E5"
      />
      <path d="M14.167 17.5H5.833l-.416-1.667.416-1.666h8.334l.416 1.666-.416 1.667Z" fill="#FBC02D" />
      <path d="m15.833 14.583 1.667-.416V5.833l-1.667-.416-1.666.416v8.334l1.666.416Z" fill="#4CAF50" />
      <path
        d="m14.167 5.833.416-1.666-.416-1.667H3.75c-.69 0-1.25.56-1.25 1.25v10.417l1.667.416 1.666-.416V5.833h8.334Z"
        fill="#1E88E5"
      />
      <path d="M14.167 14.167V17.5l3.333-3.333h-3.333Z" fill="#E53935" />
      <path
        d="M16.25 2.5h-2.083v3.333H17.5V3.75c0-.69-.56-1.25-1.25-1.25ZM3.75 17.5h2.083v-3.333H2.5v2.083c0 .69.56 1.25 1.25 1.25Z"
        fill="#1565C0"
      />
    </g>
    <defs>
      <clipPath id="GoogleCalendar_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGoogleCalendar;
