"use client";

import classNames from "classnames";
import { useEffect } from "react";
import { useTransitionState } from "react-transition-state";
import { DotMenu } from "../DotMenu";
import type { IconType } from "../Icon";
import { bgStyles, borderStyles, flexStyles, iconStyles, paddingStyles, textStyles, transitionStyles } from "../styles";
import type { OnClickHandler, PropsWithClassName } from "../types";
import { Item } from "./Item";
import styles from "./PopoverMenu.module.css";

export type PopoverLinkItem = {
  label: string;
  onClick: OnClickHandler;
  iconType: IconType;
  loading?: boolean;
};

interface Props extends PropsWithClassName {
  links: PopoverLinkItem[];
  open: boolean;
  maxLinks?: number;
}

export function PopoverMenu({ links, className, open, maxLinks = 3 }: Props) {
  const hasDots = links.length > maxLinks;
  const first = hasDots ? links.slice(0, maxLinks) : links;
  const rest = hasDots ? links.slice(maxLinks) : [];

  const [{ status, isMounted }, toggle] = useTransitionState({
    timeout: 200,
    preEnter: true,
    mountOnEnter: true,
    unmountOnExit: true,
  });

  useEffect(() => {
    toggle(open);
  }, [toggle, open]);

  if (!isMounted) {
    return null;
  }

  return (
    <div
      className={classNames(
        flexStyles.horiz,
        flexStyles.alignSelfCenter,
        paddingStyles.py050,
        paddingStyles.px075,
        bgStyles.primary,
        textStyles.neutral0,
        iconStyles.neutral0,
        borderStyles.radiusPill,
        transitionStyles.all200,
        styles.wrapper,
        status,
        className,
      )}
    >
      {first.map(({ label, onClick, iconType, loading }, index) => (
        <Item hasDivider={index !== 0} loading={loading} iconType={iconType} key={label} onClick={onClick}>
          {label}
        </Item>
      ))}
      {rest.length > 0 && (
        <DotMenu
          className={classNames(
            flexStyles.vert,
            flexStyles.alignCenter,
            flexStyles.justifyCenter,
            paddingStyles.px100,
            borderStyles.leftNeutral500,
          )}
          dark
          links={rest}
        />
      )}
    </div>
  );
}
