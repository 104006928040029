import type { ThemePayload } from "../schemas/Theme";
import { gradientForTheme } from "./gradientForTheme";
import { findContrastColor } from "./helpers/findContrastColor";
import { themeBackground } from "./themeBackground";

export function themeTextColor(theme: ThemePayload): string {
  switch (theme.type) {
    case "SolidColor":
      return findContrastColor(themeBackground(theme));
    case "Gradient":
      return gradientForTheme(theme).fontColor;
  }
}
