import { compact } from "es-toolkit";
import type { Path } from "react-hook-form";
import { type PropsWithOptionalPrefix, VisualTextArea } from "ui";

interface Props<T> extends PropsWithOptionalPrefix {
  onSubmit: (payload: T) => Promise<void>;
}
export function TextBlockEditor<T extends object>({ onSubmit, prefix }: Props<T>) {
  const name = compact([prefix, "text"]).join(".") as Path<T>;

  return (
    <VisualTextArea<T>
      onSubmit={onSubmit}
      type="inline"
      name={name}
      placeholder="Add a text block"
      fontSelectable
      textAlignmentSelectable
    />
  );
}
