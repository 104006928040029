import { compact } from "es-toolkit";
import type { Path } from "react-hook-form";
import { Help, ImageInput, InlineTextInput, type PropsWithOptionalPrefix, iconStyles } from "ui";
import { uploadImage } from "../../../store";

interface Props<T> extends PropsWithOptionalPrefix {
  widthName?: string;
  onSubmit: (payload: T) => Promise<void>;
}

export function ImageBlockEditor<T extends object>({ onSubmit, prefix, widthName }: Props<T>) {
  const getName = (name: string) => compact([prefix, name]).join(".") as Path<T>;

  const altHelp = (
    <Help className={iconStyles.size100}>Alt text is used by screen readers and when the image fails to load.</Help>
  );
  return (
    <>
      <ImageInput widthName={widthName} urlValue uploadImage={uploadImage} name={getName("image")} />
      <InlineTextInput<T>
        label="Image link URL"
        size="Text"
        name={getName("url")}
        placeholder="URL"
        onSubmit={onSubmit}
      />
      <InlineTextInput<T>
        label="Image caption"
        size="Text"
        name={getName("caption")}
        placeholder="Image caption"
        onSubmit={onSubmit}
      />
      <InlineTextInput<T>
        label="Image alt text"
        size="Text"
        name={getName("alt")}
        placeholder="Image's alt text"
        onSubmit={onSubmit}
        extra={altHelp}
      />
    </>
  );
}
