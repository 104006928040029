import type { CSSProperties } from "react";
import { type ThemePayload, businessThemeObject } from "schemas";
import type { PropsWithClassNameAndChildren } from "../types";

interface Props extends PropsWithClassNameAndChildren {
  theme: ThemePayload;
}
export function RawThemedWrapper({ theme, children, className }: Props) {
  return (
    <div className={className} style={businessThemeObject(theme) as CSSProperties}>
      {children}
    </div>
  );
}
