import type { SVGProps } from "react";

const SvgDontWaitSquiggle = (props: SVGProps<SVGSVGElement>) => (
  <svg width={106} height={38} fill="transparent" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M103.673 8c-28.5-5.962-62.432 9.346-63.524 18.754-.494 4.257 9.582 13.16 13.291 6.452 2.494-4.511 2.152-8.817-.456-13.509-1.43-2.572-2.873-2.938-5.31-4.889-9.84-7.879-31.908-4.743-44.054-2.477-3.757.702 8.535 1.968 9.625 3.163.966 1.06 3.813 1.625 5.4 1.854 2.02.292-4.982-1.034-6.112-1.346C11.107 15.61.89 13.612 2.138 12.16c3.04-3.535 10.954-5.481 11.493-10.12"
      stroke="#5159F5"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgDontWaitSquiggle;
