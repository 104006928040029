import type { LiteDomain } from "schemas";
import { Description, Icon, TextAccent } from "ui";
import { SuperCard } from "../ui";
import { validationIcon } from "./validationIcon";
import { verificationDescription } from "./verificationDescription";

interface Props {
  domain: LiteDomain;
}
export function DomainCard({ domain }: Props) {
  const { id, host, valid } = domain;
  return (
    <SuperCard
      key={id}
      to={`${id}`}
      left={<Icon iconType={validationIcon(valid)} />}
      title={<Description>{verificationDescription(valid)}</Description>}
      right={<Icon iconType="ChevronRight" />}
    >
      <TextAccent>{host}</TextAccent>
    </SuperCard>
  );
}
