import type { PersistedCampaign } from "schemas";
import { getContact } from "../contacts/getContact";
import { useLiteSiteStore } from "../store";

export function setCampaign(campaign: PersistedCampaign) {
  campaign.recipients.contactIds.map(getContact);
  useLiteSiteStore.setState(({ campaigns }) => ({
    campaigns: campaigns.set(campaign.id, campaign),
  }));
}
