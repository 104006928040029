import type {
  HobDomain,
  MeUser,
  NewItem,
  PersistedAppointment,
  PersistedAppointmentBlock,
  PersistedAppointmentLinkBlock,
  PersistedBlock,
  PersistedBooking,
  PersistedBusiness,
  PersistedCalendar,
  PersistedCampaign,
  PersistedContact,
  PersistedContactGroup,
  PersistedContactImport,
  PersistedElement,
  PersistedEmailBlock,
  PersistedForm,
  PersistedFormAnswer,
  PersistedFormLinkBlock,
  PersistedImageBlock,
  PersistedLinkBlock,
  PersistedPage,
  PersistedPageBlock,
  PersistedPhoneBlock,
  PersistedPrompt,
  PersistedSocialLinksBlock,
  PersistedTextBlock,
  PersistedTheme,
  PersistedTwoColumnsBlock,
  PersistedVideoBlock,
} from "schemas";
import { create } from "zustand";

export interface Store {
  authorized?: boolean;
  user?: MeUser;
  business?: PersistedBusiness;
  newItems?: NewItem;

  appointments: Map<string, PersistedAppointment>;
  blocks: Map<string, PersistedBlock>;

  blockAppointmentBlocks: Map<string, PersistedAppointmentBlock>;
  blockAppointmentLinkBlocks: Map<string, PersistedAppointmentLinkBlock>;
  blockEmailBlocks: Map<string, PersistedEmailBlock>;
  blockFormLinkBlocks: Map<string, PersistedFormLinkBlock>;
  blockImageBlocks: Map<string, PersistedImageBlock>;
  blockLinkBlocks: Map<string, PersistedLinkBlock>;
  blockPageBlocks: Map<string, PersistedPageBlock>;
  blockPhoneBlocks: Map<string, PersistedPhoneBlock>;
  blockSocialLinksBlocks: Map<string, PersistedSocialLinksBlock>;
  blockTwoColumnsBlocks: Map<string, PersistedTwoColumnsBlock>;
  blockTextBlocks: Map<string, PersistedTextBlock>;
  blockVideoBlocks: Map<string, PersistedVideoBlock>;

  campaignElements: Map<string | undefined, PersistedElement[]>;
  pageElements: Map<string | undefined, PersistedElement[]>;

  pages: Map<string, PersistedPage>;
  homePage?: PersistedPage;

  formsList: PersistedForm[];
  forms: Map<string, PersistedForm>;

  calendars: PersistedCalendar[];

  contacts: Map<string, PersistedContact>;
  contactBookings: Map<string, { pastBookings: PersistedBooking[]; futureBookings: PersistedBooking[] }>;
  contactFormAnswers: Map<string, PersistedFormAnswer[]>;

  contactGroups: Map<string, PersistedContactGroup>;
  csvContactImports: Map<string, PersistedContactImport>;

  bookings: Map<string, PersistedBooking>;
  upcomingBookings?: Map<string, PersistedBooking[]>;
  upcomingIntersectingDays: Array<string>;
  scrollBookingIntoView?: string;

  campaigns: Map<string, PersistedCampaign>;

  prompts: Map<string, PersistedPrompt>;

  themes: Map<string, PersistedTheme>;

  domains: Map<number, HobDomain>;

  googleAuthUrl?: string;
}

export const useLiteSiteStore = create<Store>(() => ({
  appointments: new Map(),
  blocks: new Map(),
  blockAppointmentBlocks: new Map(),
  blockAppointmentLinkBlocks: new Map(),
  blockEmailBlocks: new Map(),
  blockFormLinkBlocks: new Map(),
  blockImageBlocks: new Map(),
  blockLinkBlocks: new Map(),
  blockPageBlocks: new Map(),
  blockPhoneBlocks: new Map(),
  blockSocialLinksBlocks: new Map(),
  blockTwoColumnsBlocks: new Map(),
  blockTextBlocks: new Map(),
  blockVideoBlocks: new Map(),
  bookings: new Map(),
  calendars: [],
  campaigns: new Map(),
  campaignElements: new Map(),
  contactBookings: new Map(),
  contactFormAnswers: new Map(),
  contacts: new Map(),
  contactGroups: new Map(),
  csvContactImports: new Map(),
  domains: new Map(),
  forms: new Map(),
  formsList: [],
  pages: new Map(),
  pageElements: new Map(),
  prompts: new Map(),
  themes: new Map(),
  upcomingIntersectingDays: [],
}));
