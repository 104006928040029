"use client";

import classNames from "classnames";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { flexStyles } from "../styles";
import type { PropsWithClassName } from "../types";
import { AutoCompleteInput, type SearchItem } from "./AutoCompleteInput";
import { Label } from "./Label";

interface Props extends PropsWithClassName {
  label: string;
  name: string;
  collection: SearchItem[];
}
export function TagInput({ name, label, collection, className }: Props) {
  const { register } = useFormContext();

  useEffect(() => {
    register(name);
  }, [register, name]);

  return (
    <div className={classNames(flexStyles.vert025, className)}>
      <Label name={name}>{label}</Label>
      <AutoCompleteInput
        type="outer"
        collection={collection}
        name={name}
        extraSelections={[]}
        shortPlaceholder="Add more"
        placeholder="Search for contact groups"
      />
    </div>
  );
}
