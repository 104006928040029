import { useFormContext, useWatch } from "react-hook-form";
import type { OnlineConferenceProvider } from "schemas";
import { Error, RawIcon, TextInput, TextMain, flexStyles } from "ui";
import { RadioCard } from "../ui";

export function Conferencing() {
  const { setValue } = useFormContext();
  const conferenceType = useWatch({ name: "conferenceType" });

  const onClick = (newType: OnlineConferenceProvider) => async () => {
    setValue("conferenceType", newType, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
  };
  return (
    <div className={flexStyles.vert050}>
      <RadioCard
        checked={conferenceType === "GoogleMeet"}
        onClick={onClick("GoogleMeet")}
        left={<RawIcon iconType="GoogleMeet" />}
        title={<TextMain>Google Meet</TextMain>}
      />
      <RadioCard
        checked={conferenceType === "Zoom"}
        onClick={onClick("Zoom")}
        left={<RawIcon iconType="Zoom" />}
        title={<TextMain>Zoom</TextMain>}
      />
      <RadioCard
        checked={conferenceType === "OtherLink"}
        onClick={onClick("OtherLink")}
        left={<RawIcon iconType="Location" />}
        title={<TextMain>Other Conferencing</TextMain>}
      />
      {conferenceType !== "GoogleMeet" && <TextInput name="conferenceJoinLink" label="Conference join link" />}
      <Error name="conferenceType" />
    </div>
  );
}
