import type { PersistedElement } from "schemas";
import { apiRequest } from "../apiRequest";
import { setBlock } from "../blocks/setBlock";
import { useLiteSiteStore } from "../store";

export async function getPageElements(pageId: string) {
  const { elements } = await apiRequest<{ elements: PersistedElement[] }>({
    url: `pageElements/${pageId}/elements`,
  });
  useLiteSiteStore.setState(({ pageElements }) => ({
    pageElements: pageElements.set(pageId, elements),
  }));
  elements.map(({ block }) => {
    setBlock(block);
  });
}
