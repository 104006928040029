import classNames from "classnames";
import { useEffect, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import type { BlockPayload } from "schemas";
import { CheckboxWithLabel, Description, Handle, flexStyles, textStyles, useResize } from "ui";
import { ImageBlockEditor } from "./ImageBlockEditor";
import { TextBlockEditor } from "./TextBlockEditor";

// Handle width with gap in pixels
const HandleWidth = 16;

interface Props {
  onSubmit: (payload: BlockPayload) => Promise<void>;
}
export function TwoColumnsBlockEditor({ onSubmit }: Props) {
  const name = "widthPercentage";
  const { leftImageBlock, leftTextBlock, rightImageBlock, rightTextBlock, widthPercentage } = useWatch();
  const ref = useRef<HTMLDivElement>(null);
  const otherRef = useRef<HTMLDivElement>(null);
  const { onMouseDown, onTouchStart, width } = useResize(ref, { maxWidth: null });
  const { register, setValue } = useFormContext();
  const wrappable = (leftImageBlock || rightImageBlock) && (rightTextBlock || leftTextBlock);

  useEffect(() => {
    register(name);
  }, [register]);

  if (otherRef.current) {
    const newPercentage = Math.round(((width + HandleWidth / 2) / otherRef.current.clientWidth) * 100);
    if (newPercentage !== widthPercentage && newPercentage > 24 && newPercentage < 76) {
      setValue(name, newPercentage, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    }
  }
  return (
    <>
      <div ref={otherRef} className={classNames(flexStyles.horiz, flexStyles.gap031)}>
        <div ref={ref} style={{ flexBasis: `${widthPercentage}%` }} className={classNames(flexStyles.vert050)}>
          {leftImageBlock && <ImageBlockEditor onSubmit={onSubmit} prefix="leftImageBlock" />}
          {leftTextBlock && <TextBlockEditor onSubmit={onSubmit} prefix="leftTextBlock" />}
        </div>
        <Handle
          className={flexStyles.alignSelfCenter}
          onMouseDown={(event) => onMouseDown(event, "positive")}
          onTouchStart={(event) => onTouchStart(event, "positive")}
        />
        <div style={{ flexBasis: `${100 - widthPercentage}%` }} className={classNames(flexStyles.vert050)}>
          {rightImageBlock && <ImageBlockEditor onSubmit={onSubmit} prefix="rightImageBlock" />}
          {rightTextBlock && <TextBlockEditor onSubmit={onSubmit} prefix="rightTextBlock" />}
        </div>
      </div>
      {wrappable && (
        <CheckboxWithLabel name="wrapsText">
          <Description className={textStyles.businessTextColor}>Wrap text around the image</Description>
        </CheckboxWithLabel>
      )}
    </>
  );
}
