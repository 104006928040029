import type { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { Icon, IconButton, RawIcon, TextInput, flexStyles, marginStyles } from "ui";
import { ChoiceWrapper } from "./ChoiceWrapper";

interface Props {
  type: "single" | "multiple";
  index: number;
  dragHandleProps?: DraggableProvidedDragHandleProps | undefined | null;
  onDelete: () => Promise<void>;
}
export function ChoiceInput({ dragHandleProps, type, index, onDelete }: Props) {
  const iconType = type === "single" ? "RadioUnchecked" : "CheckboxUnchecked";
  return (
    <ChoiceWrapper>
      {dragHandleProps && (
        <div {...dragHandleProps}>
          <Icon className={marginStyles.mt125} iconType="Drag" />
        </div>
      )}

      <RawIcon className={marginStyles.mt125} iconType={iconType} />
      <TextInput className={flexStyles.grow} type="text" label="Option" name={`possibleAnswers.${index}`} />
      {index > 1 && <IconButton className={marginStyles.mt125} iconType="Delete" onClick={onDelete} />}
    </ChoiceWrapper>
  );
}
