import { useEffect, useState } from "react";
import type { PersistedPrompt } from "schemas";
import { apiRequest } from "../apiRequest";
import { useLiteSiteStore } from "../store";
import type { HookLoading } from "../types";
import { setPrompt } from "./setPrompt";

async function getPrompt(id: string) {
  const prompt = await apiRequest<PersistedPrompt>({ url: `prompts/${id}` });
  setPrompt(prompt);

  return prompt;
}

export function usePrompt(id: string): HookLoading<{ prompt?: PersistedPrompt }> {
  const [loading, setLoading] = useState<boolean>(!!id);
  const prompt = useLiteSiteStore((state) => state.prompts.get(id));

  useEffect(() => {
    if (loading && id) {
      getPrompt(id).then(() => setLoading(false));
    }
  }, [id, loading]);

  return { loading, prompt };
}
