import { Edit as EditContact, Note, Show as ShowContact } from "../components/contacts";
import { AddQuestion, Edit, EditQuestion, Editor, EmbedForm, List, NewQuestion, Responses } from "../components/forms";
import { PrivateOutlet } from "./PrivateOutlet";

export const FormRoutes = {
  path: "forms",
  Component: PrivateOutlet,
  children: [
    {
      path: "",
      Component: List,
      children: [
        {
          path: ":formId/embed",
          Component: EmbedForm,
        },
      ],
    },
    {
      path: "new",
      Component: Editor,
      children: [
        { path: "", Component: Edit },
        { path: "add", Component: AddQuestion },
        { path: "add/:questionType", Component: NewQuestion },
        { path: "edit/:index", Component: EditQuestion },
      ],
    },
    {
      path: "new/:appointmentId",
      Component: Editor,
      children: [
        { path: "", Component: Edit },
        { path: "add", Component: AddQuestion },
        { path: "add/:questionType", Component: NewQuestion },
        { path: "edit/:index", Component: EditQuestion },
      ],
    },
    {
      path: ":formId",
      Component: Editor,
      children: [
        { path: "", Component: Edit },
        { path: "add", Component: AddQuestion },
        { path: "add/:questionType", Component: NewQuestion },
        { path: "edit/:index", Component: EditQuestion },
      ],
    },
    { path: ":formId/responses", Component: Responses },
    {
      path: ":formId/responses/contacts/:contactId",
      Component: ShowContact,
    },
    {
      path: ":formId/responses/contacts/:contactId/edit",
      Component: EditContact,
    },
    {
      path: ":formId/responses/contacts/:contactId/note",
      Component: Note,
    },
  ],
};
