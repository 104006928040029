import type { AvailableValues } from "./AvailableValues";
import { BaseSelect } from "./BaseSelect";
import type { StandaloneProps } from "./Props";

export function StandaloneSelect<T extends AvailableValues>({
  options,
  onChange,
  value,
  raw,
  placeholder,
}: StandaloneProps<T>) {
  return <BaseSelect value={value} options={options} placeholder={placeholder} onChange={onChange} raw={raw} />;
}
