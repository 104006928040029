"use client";

import classNames from "classnames";
import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Tag } from "../../Tag";
import {
  bgStyles,
  borderStyles,
  flexStyles,
  heightStyles,
  outlineStyles,
  paddingStyles,
  positionStyles,
  tagStyles,
  textStyles,
  widthStyles,
} from "../../styles";
import type { OnClickHandler } from "../../types";
import { Drawer } from "./Drawer";
import { NakedInput } from "./NakedInput";

export type SearchItem = { id: string; label: string; subLabel?: string | null; searchable: string[] };

interface Props {
  name: string;
  collection: SearchItem[];
  extraSelections: { label: string; onClick: OnClickHandler }[];
  placeholder?: string;
  shortPlaceholder?: string;
  onAddNew?: (query: string) => Promise<string>;
  type: "inner" | "outer";
}
export function AutoCompleteInput({
  extraSelections,
  name,
  collection,
  onAddNew,
  placeholder,
  shortPlaceholder,
  type,
}: Props) {
  const [search, setSearch] = useState("");
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const list = useWatch({ name });
  const searchResults = collection.filter(
    ({ id, searchable }) => !list.includes(id) && searchable.join(" ").includes(search.toLowerCase()),
  );

  const { setValue } = useFormContext();
  const unselectHandler = (id: string) => async () => {
    if (list.includes(id)) {
      const newSelection = list.filter((item: string) => item !== id);
      setValue(name, newSelection, { shouldValidate: true });
    }
  };

  const onSelection = async (id: string) => {
    if (!list.includes(id)) {
      const newSelection = [...list, id].sort();
      setSearch("");
      setValue(name, newSelection, { shouldValidate: true });
    }
  };

  const onAddNewExtended = onAddNew
    ? async (query: string) => {
        const newContactId = await onAddNew(query);
        await onSelection(newContactId);
      }
    : undefined;

  const items = collection
    .filter((item) => list.includes(item.id))
    .map((item) => ({ label: item.label, onClick: unselectHandler(item.id) }));
  const allSelections = [...extraSelections, ...items];

  return (
    <>
      {type === "outer" && (
        <div className={classNames(flexStyles.horiz050, flexStyles.wrap, tagStyles.neutral200, textStyles.neutral600)}>
          {allSelections.map((selection) => (
            <Tag
              iconType="ContactGroup"
              type="Square"
              key={selection.label}
              label={selection.label}
              button={{ onClick: selection.onClick, iconType: "X" }}
            />
          ))}
        </div>
      )}
      <div
        className={classNames(
          paddingStyles.p0875,
          flexStyles.horiz050,
          flexStyles.alignCenter,
          flexStyles.wrap,
          positionStyles.relative,
          textStyles.size087,
          textStyles.neutral600,
          heightStyles.min312,
          widthStyles.full,
          bgStyles.neutral0,
          borderStyles.neutral400,
          outlineStyles.none,
          borderStyles.radius025,
          tagStyles.secondary100,
        )}
      >
        {type === "inner" &&
          allSelections.map((selection) => (
            <Tag
              type="Square"
              key={selection.label}
              label={selection.label}
              button={{ onClick: selection.onClick, iconType: "X" }}
            />
          ))}
        <NakedInput
          value={search}
          onChange={setSearch}
          placeholder={allSelections.length > 0 ? shortPlaceholder : placeholder}
          onFocus={() => setDrawerOpen(true)}
          onBlur={() => setTimeout(() => setDrawerOpen(false), 150)}
        />
        {(drawerOpen || !!search) && (
          <Drawer onClick={onSelection} search={search} searchResults={searchResults} onAddNew={onAddNewExtended} />
        )}
      </div>
    </>
  );
}
