import { type Asserts, boolean, number, object, string } from "yup";
import { ImageBlockSchema, type PersistedImageBlock } from "./ImageBlock";
import { type PersistedTextBlock, TextBlockSchema } from "./TextBlock";

export type TwoColumnsBlockVariant = "ImageAndText" | "TextAndImage" | "ImageAndImage" | "TextAndText";

export const TwoColumnsBlockSchema = object({
  leftTextBlock: TextBlockSchema.nullable().optional(),
  rightTextBlock: TextBlockSchema.nullable().optional(),
  leftImageBlock: ImageBlockSchema.nullable().optional(),
  rightImageBlock: ImageBlockSchema.nullable().optional(),
  widthPercentage: number().min(25).max(75).required(),
  wrapsText: boolean().nullable(),
  kind: string().oneOf(["TwoColumnsBlock"]).required(),
}).noUnknown();

export type TwoColumnsBlockPayload = Asserts<typeof TwoColumnsBlockSchema> & {
  kind: "TwoColumnsBlock";
};

export type PersistedTwoColumnsBlock = {
  kind: "TwoColumnsBlock";
  id: string;
  widthPercentage: number;
  wrapsText: boolean | null;
  leftTextBlock: PersistedTextBlock | null;
  rightTextBlock: PersistedTextBlock | null;
  leftImageBlock: PersistedImageBlock | null;
  rightImageBlock: PersistedImageBlock | null;
};
