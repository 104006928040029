import type { PersistedContact } from "schemas";
import { apiRequest } from "../apiRequest";
import { setContact } from "./setContact";

export async function unblockContact(id: string) {
  const contact = await apiRequest<PersistedContact>({
    url: `contacts/${id}/unblock`,
    method: "PATCH",
  });

  setContact(contact);
}
