import type { Upload } from "./Upload";
import { getCsvUploadUrl } from "./getCsvUploadUrl";

export async function uploadCsv(file: File): Promise<Upload> {
  const { key, bucket, uploadUrl } = await getCsvUploadUrl();
  await fetch(uploadUrl, {
    method: "PUT",
    body: file,
  });
  return { key, bucket };
}
