import type { PropsWithChildren } from "react";
import type { DefaultValues } from "react-hook-form";
import type { Schema } from "schemas";
import { FormProvider, FormTag, type OnClickHandler, stopPropagation } from "ui";
import { Menu } from "./Menu";

interface Props<T> extends PropsWithChildren {
  schema: Schema;
  defaultValues: DefaultValues<T>;
  onSubmit: (payload: T) => Promise<void>;
  onDelete?: OnClickHandler;
  onCancel: OnClickHandler;
  skipMenu?: boolean;
}

export function Form<T extends object>({
  children,
  schema,
  defaultValues,
  onSubmit,
  onCancel,
  onDelete,
  skipMenu,
}: Props<T>) {
  return (
    <FormProvider<T> schema={schema} defaultValues={defaultValues}>
      <FormTag<T> onSubmit={onSubmit} onClick={stopPropagation}>
        {children}
        {!skipMenu && <Menu<T> onDelete={onDelete} onSubmit={onSubmit} onCancel={onCancel} />}
      </FormTag>
    </FormProvider>
  );
}
