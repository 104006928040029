import { type Asserts, array, object, string } from "yup";

export const SocialLinksBlockSchema = object({
  links: array().of(string().url("Link must be a valid url")).required(),
  kind: string().oneOf(["SocialLinksBlock"]).required(),
}).noUnknown();

export type SocialLinksBlockPayload = Asserts<typeof SocialLinksBlockSchema> & {
  kind: "SocialLinksBlock";
};
export type PersistedSocialLinksBlock = SocialLinksBlockPayload & {
  id: string;
};
