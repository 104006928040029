import { setLightness } from "polished";
import type { ThemePayload } from "../schemas/Theme";
import { Gradients } from "./gradients";

export function themePrimaryDark(theme: ThemePayload): string {
  switch (theme.type) {
    case "SolidColor":
      return setLightness(0.24, theme.primaryColor);
    case "Gradient":
      return Gradients[theme.gradient].darkColor;
  }
}
