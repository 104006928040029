"use client";

import classNames from "classnames";
import { type PropsWithChildren, type ReactNode, useState } from "react";
import { useLongPress } from "react-use";
import { buttonStyles } from "../styles";
import type { OnClickHandler } from "../types";

interface Props extends PropsWithChildren {
  onPressed: OnClickHandler;
  pressedContent: ReactNode;
  disabled?: boolean;
}

export function HoldButton({ children, onPressed, pressedContent, disabled = false }: Props) {
  const [pressed, setPressed] = useState<boolean>(false);
  const onPressDone = () => {
    if (!pressed) {
      setPressed(true);
      onPressed();
    }
  };

  const longPress = useLongPress(onPressDone, { delay: 2500 });

  return (
    <button
      disabled={disabled || pressed}
      {...longPress}
      className={classNames(buttonStyles.btn, buttonStyles.hold, buttonStyles.primary, buttonStyles.large, {
        [buttonStyles.pressed]: pressed,
      })}
    >
      {pressed ? pressedContent : children}
    </button>
  );
}
