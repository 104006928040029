import type { AppointmentPayload, PersistedAppointment } from "schemas";
import { apiRequest } from "../apiRequest";
import { setAppointment } from "./setAppointment";

export async function updateAppointment(id: string, payload: AppointmentPayload) {
  const appointment = await apiRequest<PersistedAppointment>({
    url: `appointments/${id}`,
    method: "PATCH",
    body: JSON.stringify(payload),
  });
  setAppointment(appointment);
  return appointment;
}
