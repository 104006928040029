"use client";

import classNames from "classnames";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { IconButton } from "../IconButton";
import { bgStyles, flexStyles, iconStyles, positionStyles, textStyles } from "../styles";
import { Error } from "./Error";
import { Label } from "./Label";
import formStyles from "./forms.module.css";

interface Props {
  name: string;
  label: string;
  placeholder?: string;
}

export function PasswordInput({ name, label, placeholder }: Props) {
  const { register } = useFormContext();
  const [visible, setVisible] = useState<boolean>(false);
  const toggleVisible = async () => {
    setVisible(!visible);
  };

  return (
    <div className={classNames(flexStyles.vert025, positionStyles.relative)}>
      <IconButton
        className={classNames(
          iconStyles.neutral600,
          positionStyles.absolute,
          positionStyles.right087,
          positionStyles.top212,
        )}
        tabIndex={-1}
        iconType={visible ? "Hide" : "Show"}
        onClick={toggleVisible}
      />
      <Label name={name}>{label}</Label>
      <input
        className={classNames(textStyles.fontPoppins, bgStyles.neutral0, formStyles.input)}
        type={visible ? "text" : "password"}
        placeholder={placeholder}
        {...register(name)}
      />
      <Error name={name} />
    </div>
  );
}
