import type { PersistedCampaign } from "schemas";
import { apiRequest } from "../apiRequest";
import { setCampaign } from "./setCampaign";

export async function sendCampaign(id: string) {
  const campaign = await apiRequest<PersistedCampaign>({ url: `campaigns/${id}/send`, method: "PUT" });
  setCampaign(campaign);

  return campaign;
}
