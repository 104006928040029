"use client";

import classNames from "classnames";
import { type MouseEvent, useState } from "react";
import { Animation, type AnimationType } from "./Animation";
import { Button } from "./Button";
import { Drawer } from "./Drawer";
import { flexStyles, squareStyles, textStyles, widthStyles } from "./styles";
import type { OnClickHandler } from "./types";
import { TextMain } from "./typography";

interface Props {
  title: string;
  animationType?: AnimationType;
  text: string;
  confirmText?: string;
  onConfirm: OnClickHandler;
  onClose: OnClickHandler;
  open?: boolean;
}

export function Confirm({ title, animationType, text, confirmText, onConfirm, onClose, open = true }: Props) {
  const [confirming, setConfirming] = useState(false);
  const onConfirmClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setConfirming(true);
    try {
      await onConfirm();
    } finally {
      setConfirming(false);
    }
  };

  return (
    <Drawer title={title} open={open} onClose={onClose} containerId="litesite-confirm">
      <div className={classNames(flexStyles.vert200, flexStyles.alignCenter, textStyles.center)}>
        {animationType && <Animation className={squareStyles.square625} animationType={animationType} />}
        <TextMain>{text}</TextMain>
        <div className={classNames(flexStyles.vert100, flexStyles.alignStretch, widthStyles.full)}>
          <Button disabled={confirming} onClick={onConfirmClick}>
            {confirmText}
          </Button>
          <Button stopPropagation type="tertiary" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Drawer>
  );
}
