import classNames from "classnames";
import type { ChangeEvent } from "react";
import { heightStyles, opacityStyles, positionStyles, widthStyles } from "../../styles";
import type { FileInputType } from "./FileInputType";
import { RawFileInput } from "./RawFileInput";

interface Props {
  onSelectFile: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  type: FileInputType;
}

export function FileInput({ onSelectFile, disabled = false, type }: Props) {
  return (
    <RawFileInput
      onSelectFile={onSelectFile}
      disabled={disabled}
      type={type}
      className={classNames(widthStyles.full, heightStyles.full, opacityStyles.opacity0, positionStyles.absolute)}
    />
  );
}
