"use client";

import { DateTime } from "luxon";
import ReactSelect from "react-select";
import { Colors } from "schemas";
import type { SelectOption } from "../types";

const options: Array<SelectOption> = [];
for (let i = 0; i < 1440; i += 30) {
  options.push({
    value: i,
    label: DateTime.fromSeconds(i * 60, { zone: "utc" }).toLocaleString(DateTime.TIME_SIMPLE),
  });
}

interface TimeSelectProps {
  value: number;
  onChange: (value: SelectOption | null) => void;
}

export function TimeSelect({ value, onChange }: TimeSelectProps) {
  const selectValue = options.find((option) => option.value === value);
  return (
    <ReactSelect
      isSearchable={false}
      styles={{
        container: (baseStyles) => ({
          ...baseStyles,
          flexGrow: 1,
        }),
        control: (baseStyles) => ({
          ...baseStyles,
          boxShadow: "none",
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          fontSize: "0.875rem",
          lineHeight: "1.25rem",
        }),
        indicatorSeparator: () => ({ display: "none" }),
        dropdownIndicator: (baseStyles, state) => ({
          ...baseStyles,
          transform: state.isFocused ? "rotate(180deg)" : "",
        }),
      }}
      options={options}
      onChange={onChange}
      value={selectValue}
      blurInputOnSelect
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: Colors.Secondary300,
          neutral20: Colors.Neutral400,
        },
      })}
    />
  );
}
