import { toast } from "react-toastify";
import { ShareLink, bgStyles, flexStyles, iconStyles, imageStyles, paddingStyles, widthStyles } from "ui";

import classNames from "classnames";
import { useBreakpoint } from "../../hooks";
import fullLogo from "../../images/PopSendFullLogo.svg";
import logo from "../../images/PopSendLogo.svg";
import { useCurrentBusiness } from "../../store";

const { REACT_APP_PUBLIC_DOMAIN } = import.meta.env;

export function Header() {
  const business = useCurrentBusiness();
  const isLarge = useBreakpoint("mobile");

  if (!business) {
    return null;
  }

  return (
    <div
      className={classNames(
        iconStyles.primary,
        flexStyles.horizApart,
        flexStyles.gap100,
        paddingStyles.p125,
        paddingStyles.pb100,
        paddingStyles.pt325Tablet,
        paddingStyles.pb200Tablet,
        bgStyles.neutral0,
      )}
    >
      <img
        alt="PopSend Logo"
        className={classNames(imageStyles.fitContain, {
          [widthStyles.width500]: isLarge,
          [widthStyles.width950Tablet]: isLarge,
          [widthStyles.width250]: !isLarge,
        })}
        src={isLarge ? fullLogo : logo}
      />
      <ShareLink relativeUrl={`${business.relativeUrl}/subscribe`} host={REACT_APP_PUBLIC_DOMAIN} onCopy={toast} />
    </div>
  );
}
