import classNames from "classnames";
import type { ReactNode } from "react";
import { flexStyles, positionStyles, textStyles } from "../../styles";
import type { PropsWithClassNameAndChildren } from "../../types";

interface Props extends PropsWithClassNameAndChildren {
  name: string;
  extra?: ReactNode;
}

export function Label({ name, children, className, extra }: Props) {
  return (
    <div
      className={classNames(
        flexStyles.horiz,
        flexStyles.justifyBetween,
        flexStyles.alignCenter,
        positionStyles.relative,
        textStyles.neutral400,
        textStyles.weight500,
        textStyles.size062,
        textStyles.lsColor,
        className,
      )}
    >
      <label htmlFor={name}>{children}</label>
      {extra}
    </div>
  );
}
