import { omit } from "es-toolkit";
import type { PersistedCampaign } from "schemas";
import { apiRequest } from "../apiRequest";
import { setCampaign } from "./setCampaign";

export async function updateCampaign(id: string, payload: PersistedCampaign) {
  setCampaign({ ...payload, id });
  const campaign = await apiRequest<PersistedCampaign>({
    url: `campaigns/${id}`,
    method: "PATCH",
    body: JSON.stringify(omit(payload, ["recipients"])),
  });
  setCampaign(campaign);

  return campaign;
}
