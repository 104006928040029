import type { SVGProps } from "react";

const SvgBillingDownArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg width={35} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M29.458 15.367c.555.578.936 1.401 1.428 2.039.512.664 1.014 1.326 1.533 1.982.324.408.73.685 1.058 1.075.043.052.594.482.513.503-.27.072-.647.007-.929.007-1.328 0-2.694-.111-4.04-.127-1.251-.016-2.468 0-3.717 0-.907 0-1.806.127-2.697.127-.613 0 1.227-.058 1.836-.127 1.14-.13 2.277-.369 3.429-.383.854-.01 1.687-.127 2.538-.127.806 0 3.185.33 2.417.1-1.187-.358-2.439-.591-3.648-.878-2.275-.54-4.628-.887-6.851-1.6-4.394-1.408-8.69-3.217-12.54-5.67-2.687-1.712-4.474-3.846-6.103-6.447C2.792 4.414 1.83 2.72 2.024 1"
      stroke="#6ABB7B"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgBillingDownArrow;
