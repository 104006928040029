import { useEffect, useState } from "react";
import type { PersistedEmailSettings } from "schemas";
import { apiRequest } from "../apiRequest";
import type { HookLoading } from "../types";

async function getEmailSettings() {
  const { emailSettings } = await apiRequest<{ emailSettings: PersistedEmailSettings | null }>({
    url: "emailSettings",
  });
  return emailSettings;
}

export function useEmailSettings(): HookLoading<{ emailSettings: PersistedEmailSettings | null }> {
  const [emailSettings, setEmailSettings] = useState<PersistedEmailSettings | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getEmailSettings().then((emailSettings) => {
      if (emailSettings) {
        setEmailSettings(emailSettings);
      }
      setLoading(false);
    });
  }, []);

  return { loading, emailSettings };
}
