import classNames from "classnames";
import type { ReactNode } from "react";
import { useNavigate } from "react-router";
import {
  Card,
  CheckboxWithLabel,
  Icon,
  type IconType,
  Slide,
  SmallTextMain,
  TextAccent,
  TextMain,
  borderStyles,
  flexStyles,
  iconStyles,
  paddingStyles,
  positionStyles,
  textStyles,
} from "ui";
import { useUrlWithBack } from "../../hooks";
import { useCurrentBusiness } from "../../store";
import { Link } from "../ui";

interface Props {
  iconType: IconType;
  label: string;
  name?: string;
  description?: ReactNode;
  onClick?: () => void;
}
export function ReminderCard({ label, description, onClick, name, iconType }: Props) {
  const navigate = useNavigate();
  const business = useCurrentBusiness();
  const hasSmsReminders = business.paidFeatures.smsReminders;
  const discoveredSmsUrl = useUrlWithBack("/billing/discovered/sms");
  const getSmsReminders = () => {
    navigate(discoveredSmsUrl);
  };

  return (
    <Card
      className={classNames(flexStyles.gap075, positionStyles.zIndex3, paddingStyles.py125, iconStyles.secondary300)}
    >
      <div className={classNames(flexStyles.horizApart, paddingStyles.px125)}>
        <div className={classNames(flexStyles.horiz025, flexStyles.alignCenter)}>
          <Icon iconType={iconType} />
          <TextAccent>{label}</TextAccent>
        </div>
      </div>
      {description && (
        <div className={classNames(flexStyles.vert125, borderStyles.topNeutral300, paddingStyles.pxt125)}>
          <TextMain>{description}</TextMain>
          <div className={flexStyles.horiz150}>
            <CheckboxWithLabel
              className={classNames(textStyles.neutral600, flexStyles.gap125)}
              name={`reminders.${name}.emailEnabled`}
            >
              <SmallTextMain>Email</SmallTextMain>
            </CheckboxWithLabel>
            {hasSmsReminders ? (
              <CheckboxWithLabel
                className={classNames(textStyles.neutral600, flexStyles.gap125)}
                name={`reminders.${name}.smsEnabled`}
              >
                <div className={flexStyles.horiz050}>
                  <SmallTextMain>SMS</SmallTextMain>
                  <Icon iconType="Diamond" />
                </div>
              </CheckboxWithLabel>
            ) : (
              <div className={flexStyles.horiz050} onClick={getSmsReminders}>
                <SmallTextMain>SMS</SmallTextMain>
                <Icon iconType="Diamond" />
                <Slide on={false} name={`reminders.${name}.smsEnabled`} />
              </div>
            )}
          </div>
          {onClick && <Link onClick={onClick}>Preview & Edit</Link>}
        </div>
      )}
    </Card>
  );
}
