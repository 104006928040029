import {
  type BlockPayload,
  type BlockTypeMap,
  EmailBlockSchema,
  ImageBlockSchema,
  InlineEditableBlockKindValues,
  type InlineEditableBlockKinds,
  LinkBlockSchema,
  PhoneBlockSchema,
  type Schema,
  StrictTextBlockSchema,
  TwoColumnsBlockSchema,
  VideoBlockSchema,
} from "schemas";
import type { PropsWithDirtyBlock } from "../../../helpers";
import { DirtyBlockLock } from "./DirtyBlockLock";
import { EmailBlockEditor } from "./EmailBlockEditor";
import { Form } from "./Form";
import { ImageBlockEditor } from "./ImageBlockEditor";
import { LinkBlockEditor } from "./LinkBlockEditor";
import { PhoneBlockEditor } from "./PhoneBlockEditor";
import { TextBlockEditor } from "./TextBlockEditor";
import { TwoColumnsBlockEditor } from "./TwoColumnsBlockEditor";
import { VideoLinkEditor } from "./VideoLinkEditor";

function getComponent({
  kind,
  onSubmit,
}: {
  kind: InlineEditableBlockKinds;
  onSubmit: (payload: BlockPayload) => Promise<void>;
}) {
  switch (kind) {
    case "TextBlock":
      return <TextBlockEditor onSubmit={onSubmit} />;
    case "ImageBlock":
      return <ImageBlockEditor onSubmit={onSubmit} widthName="pixelWidth" />;
    case "VideoLinkBlock":
      return <VideoLinkEditor onSubmit={onSubmit} />;
    case "VideoBlock":
      return <VideoLinkEditor onSubmit={onSubmit} />;
    case "LinkBlock":
      return <LinkBlockEditor onSubmit={onSubmit} />;
    case "PhoneBlock":
      return <PhoneBlockEditor onSubmit={onSubmit} />;
    case "EmailBlock":
      return <EmailBlockEditor onSubmit={onSubmit} />;
    case "TwoColumnsBlock":
      return <TwoColumnsBlockEditor onSubmit={onSubmit} />;
  }
}

const Schemas: Record<InlineEditableBlockKinds, Schema> = {
  TextBlock: StrictTextBlockSchema,
  ImageBlock: ImageBlockSchema,
  VideoLinkBlock: VideoBlockSchema,
  VideoBlock: VideoBlockSchema,
  LinkBlock: LinkBlockSchema,
  PhoneBlock: PhoneBlockSchema,
  EmailBlock: EmailBlockSchema,
  TwoColumnsBlock: TwoColumnsBlockSchema,
};

interface Props extends PropsWithDirtyBlock {
  block: BlockPayload;
  onSubmit: (payload: BlockPayload) => Promise<void>;
  onDelete?: () => Promise<void>;
  onCancel: () => void;
  skipMenu?: boolean;
}
export function InlineEditor({ block, onSubmit, onDelete, onCancel, dirtyBlock, setDirtyBlock, skipMenu }: Props) {
  if (!InlineEditableBlockKindValues.includes(block.kind)) {
    return null;
  }

  const kind = block.kind as InlineEditableBlockKinds;

  const schema = Schemas[kind];

  return (
    <Form<BlockTypeMap[typeof kind]>
      schema={schema}
      defaultValues={block as BlockTypeMap[typeof kind]}
      onCancel={onCancel}
      onSubmit={onSubmit}
      onDelete={onDelete}
      skipMenu={skipMenu}
    >
      <DirtyBlockLock dirtyBlock={dirtyBlock} setDirtyBlock={setDirtyBlock} />
      {getComponent({ kind, onSubmit })}
    </Form>
  );
}
