import classNames from "classnames";
import { ThemeSolids, findContrastColor } from "schemas";
import { borderStyles, flexStyles, textStyles } from "../styles";
import type { OnClickHandler, PropsWithClassName } from "../types";

export type Contact = { initials: string };
export interface Props extends PropsWithClassName {
  contact: Contact;
  onClick?: OnClickHandler;
}

export function RawInitials({ contact: { initials }, onClick, className }: Props) {
  const colorIndex =
    initials
      .split("")
      .map((i) => i.charCodeAt(0))
      .reduce((acc, i) => acc + i, 0) % ThemeSolids.length;
  const background = ThemeSolids[colorIndex].color;

  return (
    <div
      className={classNames(
        flexStyles.horiz,
        flexStyles.alignCenter,
        flexStyles.justifyCenter,
        flexStyles.shrink0,
        borderStyles.radiusRound,
        textStyles.lineHeightFlat,
        className,
      )}
      onClick={onClick}
      style={{ background, color: findContrastColor(background) }}
    >
      {initials}
    </div>
  );
}
