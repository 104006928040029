"use client";

import { useEffect, useState } from "react";

import classNames from "classnames";
import { useFormContext, useWatch } from "react-hook-form";
import slug from "slug";
import { Animation } from "../Animation";
import { Icon } from "../Icon";
import {
  bgStyles,
  borderStyles,
  displayStyles,
  flexStyles,
  heightStyles,
  iconStyles,
  outlineStyles,
  paddingStyles,
  squareStyles,
  textStyles,
  widthStyles,
} from "../styles";
import { Error } from "./Error";
import { Label } from "./Label";

interface Props {
  name: string;
  label: string;
  base: string;
  check: (link: string, abortController: AbortController) => Promise<string>;
  slugFrom: string | null;
}

export function LinkInput({ check, slugFrom, name, label, base }: Props) {
  const {
    register,
    setValue,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useFormContext();
  const fieldValue = useWatch({ name });

  const [checking, setChecking] = useState<boolean>(false);
  const [touched, setTouched] = useState(!!fieldValue);
  const [lastValue, setLastValue] = useState(fieldValue);
  const [abortController, setAbortController] = useState<AbortController>(new AbortController());

  const fieldErrors = errors[name];
  const watchedSlugFrom = useWatch({ name: slugFrom || "" });

  const animation =
    (checking && <Animation className={squareStyles.square300} loop animationType="SmallLoading" />) ||
    (fieldErrors ? (
      <Icon className={classNames(squareStyles.square300, iconStyles.errorDark)} iconType="X" />
    ) : (
      <Animation className={squareStyles.square300} animationType="BouncyCheckmark" />
    ));

  useEffect(() => {
    if (!watchedSlugFrom || touched) {
      return;
    }
    const candidate = slug(watchedSlugFrom as string);

    if (candidate !== fieldValue) {
      setValue(name, candidate);
    }
  }, [setValue, fieldValue, watchedSlugFrom, touched, name]);

  useEffect(() => {
    if (fieldValue !== lastValue) {
      setLastValue(fieldValue);

      abortController.abort();

      const ac = new AbortController();
      setAbortController(ac);
      if (fieldValue.length > 0) {
        setChecking(true);
        check(fieldValue, ac).then((message) => {
          setChecking(false);
          if (message === "") {
            clearErrors(name);
            trigger(name);
          } else {
            setError(name, { type: "link", message });
          }
        });
      } else {
        clearErrors(name);
      }
    }
  }, [lastValue, fieldValue, check, name, clearErrors, trigger, setError, abortController]);

  return (
    <div className={flexStyles.vert025}>
      <Label name={name}>{label}</Label>
      <div
        className={classNames(
          textStyles.size087,
          textStyles.weight400,
          flexStyles.horiz,
          flexStyles.alignCenter,
          widthStyles.full,
          heightStyles.height312,
          paddingStyles.p080,
          bgStyles.neutral0,
          borderStyles.neutral400,
          borderStyles.radius025,
          borderStyles.secondary300ActiveWithin,
        )}
      >
        <div className={classNames(textStyles.neutral400, textStyles.size087, textStyles.nowrap)}>{base}</div>
        <input
          {...register(name, {})}
          className={classNames(
            textStyles.fontPoppins,
            textStyles.size087,
            textStyles.neutral600,
            paddingStyles.p0,
            displayStyles.block,
            borderStyles.none,
            outlineStyles.none,
            bgStyles.none,
            widthStyles.full,
          )}
          type="text"
          onFocus={() => setTouched(true)}
        />
        {animation}
      </div>
      <Error name={name} />
    </div>
  );
}
