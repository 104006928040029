import classNames from "classnames";
import { Animation, Icon, TextMain, flexStyles, iconStyles, textStyles } from "ui";
import { Button } from "../ui";

interface Props {
  newContactTo?: string;
  query?: string;
}
export function NoResults({ newContactTo, query }: Props) {
  const message = query ? (
    <>
      No contacts found for “{query}”<br />
      Try a different search or add this contact.
    </>
  ) : (
    <>
      No contacts found.
      <br />
      You can import or add contacts manually.
    </>
  );

  return (
    <div
      className={classNames(
        flexStyles.justifyCenter,
        flexStyles.vert100,
        flexStyles.alignCenter,
        flexStyles.grow,
        textStyles.center,
      )}
    >
      <Animation className={iconStyles.width625} animationType="AvatarCircle" />
      <TextMain>{message}</TextMain>
      {newContactTo && (
        <Button to={newContactTo} className={flexStyles.alignSelfStretch}>
          <Icon iconType="Plus" />
          Add contact
        </Button>
      )}
    </div>
  );
}
