import classNames from "classnames";
import { useWatch } from "react-hook-form";
import type { ThemePayload } from "schemas";
import { Description, bgStyles, flexStyles, paddingStyles, textStyles } from "ui";
import { ButtonPreview } from "./ButtonPreview";

export function FormButtonPreview() {
  const theme = useWatch<ThemePayload>() as ThemePayload;

  return (
    <div
      className={classNames(
        bgStyles.neutral200,
        paddingStyles.p075,
        flexStyles.vert050,
        flexStyles.alignStretch,
        textStyles.center,
      )}
    >
      <Description>Preview</Description>
      <ButtonPreview theme={theme} />
    </div>
  );
}
