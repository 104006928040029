import classNames from "classnames";
import { compact } from "es-toolkit";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import type { PersistedContact } from "schemas";
import {
  ContactInitials,
  Description,
  DotMenu,
  type DotMenuLink,
  type PropsWithClassName,
  type PropsWithOptionalClick,
  TextMain,
  flexStyles,
  textStyles,
  useConfirm,
} from "ui";
import { deleteContact, removeContactGroupMembershps, resendConfirmationEmail, useContactGroup } from "../../store";
import { SuperCard } from "../ui";
import { ContactTags } from "./ContactTags";

interface Props extends PropsWithClassName, PropsWithOptionalClick {
  contact: PersistedContact;
}

export function ContactCard({ contact, onClick, className }: Props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { confirm, Confirm } = useConfirm();
  const contactGroup = useContactGroup();

  const { name, id, email, confirmed } = contact;

  const onAddToList = () => {
    navigate("addToList", { state: { ...state, contactIds: [contact.id] } });
  };

  const links: DotMenuLink[] = compact([
    {
      iconType: "Edit",
      label: "Edit Contact",
      onClick: () => navigate(`contacts/${id}/edit`),
    },
    { iconType: "Plus", label: "Add to list", onClick: onAddToList },
    contactGroup &&
      !contactGroup.autogeneratedType && {
        iconType: "ContactRemove",
        label: "Remove from this group",
        onClick: async () => {
          await removeContactGroupMembershps({ contactGroupId: contactGroup.id, payload: { contacts: [contact.id] } });
        },
      },
    !confirmed && {
      iconType: "Mail",
      label: "Re-send confirmation email",
      onClick: async () => {
        await resendConfirmationEmail([contact.id]);
        toast.success("Confirmation email re-sent successfully");
      },
    },
    {
      iconType: "Delete",
      label: "Delete Contact",
      onClick: () => {
        confirm({
          text: `Are you sure you want to delete ${contact.name}?`,
          label: "Delete contact",
          onConfirm: async () => deleteContact(id),
        });
      },
    },
  ]);

  return (
    <>
      <SuperCard
        className={className}
        onClick={onClick}
        to={!onClick ? `contacts/${id}` : undefined}
        left={<ContactInitials contact={contact} />}
        right={<DotMenu links={links} />}
        title={
          <div className={classNames(flexStyles.vert025, textStyles.breakWord)}>
            <TextMain>{name}</TextMain>
            <Description>{email}</Description>
            <ContactTags start contact={contact} />
          </div>
        }
      />
      <Confirm />
    </>
  );
}
