import { useNavigate } from "react-router";
import type { BlockKind } from "schemas";
import { BlockCard } from "ui";
import { useBlock } from "../../store";

interface Props {
  kind: BlockKind;
  blockId: string;
}

export function ReadonlyBlock({ kind, blockId }: Props) {
  const navigate = useNavigate();
  const block = useBlock(kind, blockId);

  if (!block) {
    return null;
  }

  return <BlockCard block={block} lazy={false} editable={false} navigate={async (path) => navigate(path)} />;
}
