"use client";

import { type ChangeEvent, useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { useFormContext, useWatch } from "react-hook-form";
import type { UploadPayload } from "schemas";
import { Cropper, type CropperAspect } from "../../Cropper";
import { createImage } from "../../Cropper/createImage";
import { flexStyles, opacityStyles, positionStyles, squareStyles } from "../../styles";
import type { PropsWithClassName, PropsWithName, PropsWithOptionalLabel } from "../../types";
import { TextAccent } from "../../typography";
import { Error } from "../Error";
import { Label } from "../Label";
import { FileDrop, FileInput, RawFileInput } from "../upload";
import { ImagePreview } from "./ImagePreview";
import { ResizableImagePreview } from "./ResizableImagePreview";
import { readFile } from "./readFile";

interface Props extends PropsWithClassName, PropsWithName, PropsWithOptionalLabel {
  cropAspect?: CropperAspect;
  uploadImage?: (file: File) => Promise<UploadPayload>;
  disabled?: boolean;
  urlValue?: boolean;
  removeFeed?: boolean;
  prompt?: string;
  boldLabel?: boolean;
  widthName?: string;
}

export function ImageInput({
  cropAspect,
  name,
  label,
  uploadImage,
  prompt = "UPLOAD IMAGE",
  disabled = false,
  urlValue = false,
  removeFeed = false,
  boldLabel = false,
  widthName,
  className,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const { register, setValue, clearErrors, setError } = useFormContext();
  const value = useWatch({ name });
  const initialValue = urlValue ? value : undefined;
  const [fileUrl, setFileUrl] = useState<string>(initialValue);
  const [uploading, setUploading] = useState<boolean>(false);
  const [cropFile, setCropFile] = useState<string>();

  useEffect(() => {
    register(name);
  }, [register, name]);

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target || !e.target.files) {
      return;
    }

    const file = e.target.files[0];
    if (cropAspect) {
      setError(name, { message: " " });
      readFile(file).then((result) => setCropFile(result as string));
    } else {
      onCropCompleted(file);
    }
  };

  const cancelCropping = () => {
    setCropFile(undefined);
  };

  const onCropCompleted = async (blob: Blob) => {
    if (!uploadImage) {
      return;
    }
    setUploading(true);
    clearErrors(name);
    try {
      const {
        key,
        bucket,
        fileUrl: uploadedFile,
      } = await uploadImage(new File([blob], "image.webp", { type: "image/webp" }));
      setCropFile(undefined);
      setFileUrl(uploadedFile);
      await createImage(`${uploadedFile}/feed`);
      const newValue = urlValue ? uploadedFile : { key, bucket };
      setValue(name, newValue, { shouldValidate: true, shouldDirty: true });
    } catch (error) {
      const {
        response: {
          data: { message },
        },
      } = error as { response: { data: { message: string } } };
      if (message) {
        setError(name, { message });
      }
    } finally {
      setUploading(false);
    }
  };
  const showImage = !cropFile && !uploading;
  const isRound = cropAspect === "Round";

  const resizable = !!widthName;
  const maxWidth = ref.current && ref.current.clientWidth - 4;

  return (
    <div
      ref={ref}
      className={classNames(flexStyles.vert025, flexStyles.alignStretch, flexStyles.justifyStretch, className)}
    >
      {label ? boldLabel ? <TextAccent>{label}</TextAccent> : <Label name={name}>{label}</Label> : null}
      <FileDrop prompt={prompt} name={name} uploading={uploading} round={!cropFile && isRound}>
        {value &&
          showImage &&
          (resizable ? (
            <ResizableImagePreview
              maxWidth={maxWidth}
              name={widthName}
              src={`${fileUrl}${removeFeed ? "" : "/feed"}`}
            />
          ) : (
            <ImagePreview isRound={isRound} src={`${fileUrl}${removeFeed ? "" : "/feed"}`} />
          ))}
        {!uploading && cropFile && cropAspect && (
          <Cropper aspect={cropAspect} image={cropFile} onCancel={cancelCropping} onSubmit={onCropCompleted} />
        )}
        {!value && !uploading ? (
          <FileInput type="image" disabled={disabled} onSelectFile={onSelectFile} />
        ) : (
          <RawFileInput
            className={classNames(
              positionStyles.absolute,
              positionStyles.right0,
              positionStyles.bottom0,
              squareStyles.square375,
              opacityStyles.opacity0,
            )}
            type="image"
            disabled={disabled}
            onSelectFile={onSelectFile}
          />
        )}
      </FileDrop>
      <Error name={name} />
    </div>
  );
}
