import type { PersistedContact } from "schemas";
import { type PropsWithName, flexStyles } from "ui";
import { OuterSelectable } from "../ui/Selectable";
import { ContactCard } from "./ContactCard";

interface Props extends PropsWithName {
  contact: PersistedContact;
  disabled?: boolean;
}

export function SelectableContact({ contact, name, disabled }: Props) {
  return (
    <OuterSelectable id={contact.id.toString()} name={name} disabled={disabled}>
      {(toggle) => <ContactCard contact={contact} onClick={toggle} className={flexStyles.grow} />}
    </OuterSelectable>
  );
}
