import classNames from "classnames";
import type { CampaignPayload } from "schemas";
import { Description, FormTag, TextInput, flexStyles, textStyles } from "ui";
import { Submit } from "../ui";

interface Props {
  onSubmit: (payload: CampaignPayload) => Promise<void>;
}

export function InitialDetailsForm({ onSubmit }: Props) {
  return (
    <FormTag<CampaignPayload> onSubmit={onSubmit}>
      <div className={classNames(flexStyles.vert025, textStyles.neutral400)}>
        <TextInput autoFocus label="Campaign name (for internal use)" name="name" />
        <Description>Your customers cannot see this.</Description>
      </div>
      <Submit label="Save" cancelTo="../.." />
    </FormTag>
  );
}
