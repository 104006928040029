import type { PersistedPage } from "schemas";
import { apiRequest } from "../apiRequest";
import { useLiteSiteStore } from "../store";
import { getPageElements } from "./getPageElements";

export async function removePage(destinationPage: PersistedPage, id: string) {
  const params = new URLSearchParams({ destinationId: destinationPage.id, destinationType: "Page" });
  await apiRequest({ url: `pages/${id}?${params.toString()}`, method: "DELETE" });

  getPageElements(destinationPage.id);
  useLiteSiteStore.setState(({ pages }) => {
    pages.delete(id);
    return { pages: new Map(pages) };
  });
}
