import classNames from "classnames";
import type { MouseEvent } from "react";
import { bgStyles, borderStyles, flexStyles, paddingStyles, pointerStyles, textStyles } from "../styles";
import type { PropsWithClassNameAndChildren, PropsWithOnClick } from "../types";

interface Props extends PropsWithOnClick, PropsWithClassNameAndChildren {}

export function ButtonTextSmall({ children, className, onClick }: Props) {
  const wrapClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <button
      type="button"
      className={classNames(
        flexStyles.horiz025,
        flexStyles.alignCenter,
        pointerStyles.cursor,
        textStyles.size075,
        textStyles.weight600,
        textStyles.lsColor,
        paddingStyles.px100,
        paddingStyles.py038,
        bgStyles.none,
        borderStyles.none,
        className,
      )}
      onClick={wrapClick}
    >
      {children}
    </button>
  );
}
