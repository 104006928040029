import { useEffect, useState } from "react";
import type { PersistedCampaign } from "schemas";
import { useIdParam } from "../../hooks";
import { useLiteSiteStore } from "../store";
import type { HookLoading } from "../types";
import { getCampaign } from "./getCampaign";

export function useCampaign(): HookLoading<{ campaign?: PersistedCampaign }> {
  const [loading, setLoading] = useState<boolean>(true);
  const id = useIdParam("campaignId");
  const campaign = useLiteSiteStore((state) => state.campaigns.get(id));

  useEffect(() => {
    if (loading) {
      getCampaign(id).then(() => setLoading(false));
    }
  }, [id, loading]);

  return { loading, campaign };
}
