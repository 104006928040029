import type { SVGProps } from "react";

const SvgLocation = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 10.042c.472 0 .875-.167 1.208-.5.333-.333.5-.736.5-1.209 0-.472-.167-.875-.5-1.208a1.646 1.646 0 0 0-1.208-.5c-.472 0-.875.167-1.208.5-.333.333-.5.736-.5 1.208 0 .473.167.876.5 1.209.333.333.736.5 1.208.5ZM10 16c1.68-1.541 2.92-2.934 3.719-4.177.799-1.243 1.198-2.351 1.198-3.323 0-1.514-.472-2.74-1.417-3.677-.945-.937-2.111-1.406-3.5-1.406-1.389 0-2.555.469-3.5 1.406-.945.937-1.417 2.163-1.417 3.677 0 .972.4 2.08 1.198 3.323C7.08 13.066 8.319 14.459 10 16Zm0 2.333c-2.236-1.93-3.906-3.7-5.01-5.312C3.885 11.41 3.333 9.903 3.333 8.5c0-2.097.667-3.76 2-4.99C6.666 2.281 8.222 1.667 10 1.667s3.334.614 4.667 1.843c1.333 1.23 2 2.893 2 4.99 0 1.403-.552 2.91-1.657 4.521-1.104 1.611-2.774 3.382-5.01 5.312Z"
      fill="#DF349B"
    />
  </svg>
);
export default SvgLocation;
