"use client";

import classNames from "classnames";
import { useEffect, useRef } from "react";
import usePortal from "react-cool-portal";
import { useClickAway } from "react-use";
import {
  bgStyles,
  borderStyles,
  flexStyles,
  heightStyles,
  marginStyles,
  overflowStyles,
  paddingStyles,
  positionStyles,
  widthStyles,
} from "../styles";
import type { OnClickHandler, PropsWithClassNameAndChildren } from "../types";
import { Header } from "./Header";

interface Props extends PropsWithClassNameAndChildren {
  title: string;
  open: boolean;
  onClose: OnClickHandler;
  containerId?: string;
}

export function Drawer({ className, children, title, open, onClose, containerId = "litesite-drawer" }: Props) {
  const drawerRef = useRef(null);
  const { Portal, show, hide } = usePortal({
    containerId,
    clickOutsideToHide: false,
    defaultShow: open,
    autoRemoveContainer: false,
    escToHide: false,
  });
  useClickAway(drawerRef, onClose);

  useEffect(() => {
    open ? show() : hide();
  }, [open, show, hide]);

  return (
    <Portal>
      <div
        className={classNames(
          bgStyles.neutral600Alpha,
          flexStyles.vert,
          flexStyles.justifyEnd,
          flexStyles.justifyCenterTablet,
          flexStyles.alignStretch,
          positionStyles.fixed,
          positionStyles.cover,
          positionStyles.zIndex1000,
        )}
      >
        <div
          className={classNames(
            bgStyles.neutral100,
            borderStyles.topRadius050,
            borderStyles.radius025Tablet,
            heightStyles.maxFull,
            widthStyles.full,
            widthStyles.maxWidth4225,
            marginStyles.centered,
            overflowStyles.autoY,
            overflowStyles.overscrollNone,
          )}
          ref={drawerRef}
        >
          <Header title={title} onClose={onClose} />
          <div className={classNames(paddingStyles.p125, paddingStyles.p312Tablet, className)}>{children}</div>
        </div>
      </div>
    </Portal>
  );
}
