import type { SVGProps } from "react";
const SvgPaymentsStarTopRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width={34} height={34} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.413 2.732c-.092.747-.375 1.73-.723 2.427-.274.548-.776 1.665-.849 2.225-.124.948-.841 1.858-.898 2.832-.056.958-.495 2.502-1.01 3.337-.213.344-.323.694-.55 1-.396.535.679-1.132.999-1.708.64-1.152.622-2.369 1.122-3.54.83-1.944 1.55-4.518 1.685-6.573.128-1.966 1.122 3.851 1.122 5.82 0 2.24.899 4.437.899 6.62 0 1.704 5.566 1.314 6.837 1.314 1.367 0 2.72.202 4.067.202.464 0 2.526.06.998.214-.699.07-1.6.18-2.245.438-1.211.485-2.604.465-3.818 1.011-.98.442-1.947.686-2.92 1.124-.562.253-1.997.802-2.295 1.258-.298.457-.703.73-.899 1.259-.262.71-.264 1.48-.511 2.191-.329.947-.183 2.149-.35 3.124-.178 1.047-.885 1.964-.885 3.034 0 .307-.14.896-.237 1.202-.173.544-.448-.894-.537-1.101-.595-1.378-.162-3.002-.624-4.405-.673-2.046-1.158-3.981-1.297-6.113-.09-1.368-7.21-.809-8.646-.809-.804 0-1.556-.202-2.346-.202-.29 0-1.78.123-.886-.101 2.137-.535 4.44-.597 6.588-1.113 1.192-.286 1.996-.67 2.932-1.326.583-.408 1.607-.962 1.909-1.505"
      stroke="#FFCA28"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgPaymentsStarTopRight;
