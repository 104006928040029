import type { SVGProps } from "react";

const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.75 15.479h1.229l7-7L10.75 7.25l-7 7v1.229Zm11.938-8.208-3.73-3.73 1.021-1.02c.347-.347.76-.521 1.24-.521s.892.174 1.239.521l1.25 1.25c.333.333.5.746.5 1.239 0 .493-.167.907-.5 1.24l-1.02 1.021ZM14.458 8.5l-8.729 8.729H2V13.5l8.729-8.729L14.458 8.5Z"
      fill="#465357"
    />
  </svg>
);
export default SvgEdit;
