"use client";

import classNames from "classnames";
import { type ChangeEvent, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Icon } from "../Icon";
import { flexStyles, iconStyles, marginStyles, widthStyles } from "../styles";
import { SmallTextMain } from "../typography";
import { Error } from "./Error";
import { FileDrop, FileInput } from "./upload";

interface UploadResult {
  key: string;
  bucket: string;
}
interface Props<T> {
  name: string;
  uploadFile: (file: File) => Promise<UploadResult>;
  prompt?: string;
  onUpload?: (payload: T) => Promise<void>;
  initialValue?: UploadResult & { fileName: string };
}

export function FileUpload<T extends object>({
  name,
  uploadFile,
  prompt = "UPLOAD FILE",
  onUpload,
  initialValue,
}: Props<T>) {
  const { register, setValue, clearErrors, setError } = useFormContext();
  const value = useWatch({ name });
  const [uploading, setUploading] = useState<boolean>(false);
  register(name);

  useEffect(() => {
    if (initialValue) {
      setValue(name, initialValue);
    }
  }, [initialValue, name, setValue]);

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target || !e.target.files) {
      return;
    }

    setValue(name, undefined, { shouldValidate: true });

    const file = e.target.files[0];

    setUploading(true);
    clearErrors(name);
    const fileName = file.name;
    try {
      const { key, bucket } = await uploadFile(file);
      const newValue = { key, bucket, fileName };
      setValue(name, newValue, { shouldValidate: true });
      if (onUpload) {
        await onUpload(newValue as T);
      }
      // biome-ignore lint:
    } catch (error: any) {
      if (error?.message) {
        setError(name, { message: error.message });
      }
      setValue(name, undefined, { shouldValidate: true });
      e.target.value = "";
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className={classNames(flexStyles.vert025, flexStyles.justifyStretch, flexStyles.alignStretch)}>
      <FileDrop name={name} prompt={prompt} uploading={uploading}>
        {value && !uploading && (
          <div
            className={classNames(
              iconStyles.secondary500,
              flexStyles.horiz050,
              flexStyles.alignCenter,
              widthStyles.full,
              marginStyles.mis200,
            )}
          >
            <Icon iconType="FileCsv" />
            <SmallTextMain>{value.fileName}</SmallTextMain>
          </div>
        )}
        <FileInput type="csv" onSelectFile={onSelectFile} />
      </FileDrop>
      <Error name={name} />
    </div>
  );
}
