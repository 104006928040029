import { Draggable } from "react-beautiful-dnd";
import { useFormContext, useWatch } from "react-hook-form";
import { Checkbox, Icon, RawIcon, SmallTextMain, flexStyles } from "ui";
import { changeDraggableOrder } from "../../helpers/orders";
import { Button, ChoiceInput, ChoiceWrapper, Drop } from "../ui";

interface Props {
  type: "single" | "multiple";
}
export function ChoiceForm({ type }: Props) {
  const { setValue } = useFormContext();
  const answers = useWatch({ name: "possibleAnswers" });
  const addChoice = () => {
    setValue("possibleAnswers", [...answers, ""]);
  };
  function onDelete(index: number) {
    return async () => {
      const newAnswers = [...answers];
      newAnswers.splice(index, 1);
      setValue("possibleAnswers", newAnswers);
    };
  }
  // biome-ignore lint:
  const onDragEnd = ({ destination, source }: any) => {
    if (destination && source.index !== destination.index) {
      // biome-ignore lint:
      const reorderedAnswers = changeDraggableOrder<any>(destination.index, source.index, answers);
      setValue("possibleAnswers", reorderedAnswers);
    }
  };
  return (
    <div className={flexStyles.vert}>
      <Drop onDragEnd={onDragEnd}>
        {answers.map((_: string, index: number) => (
          // biome-ignore lint:
          <Draggable key={`choice_${index}`} draggableId={`choice_${index}`} index={index}>
            {(provided) => (
              <div {...provided.draggableProps} ref={provided.innerRef}>
                <ChoiceInput
                  dragHandleProps={provided.dragHandleProps}
                  // biome-ignore lint:
                  key={`choice_${index}_input`}
                  type={type}
                  index={index}
                  onDelete={onDelete(index)}
                />
              </div>
            )}
          </Draggable>
        ))}
      </Drop>
      <ChoiceWrapper>
        <Button buttonType="tertiary" onClick={addChoice}>
          <Icon iconType="Plus" />
          Add an option
        </Button>
      </ChoiceWrapper>
      <ChoiceWrapper>
        <RawIcon iconType="RadioUnchecked" />
        <SmallTextMain className={flexStyles.grow}>{type === "single" ? "Other" : "None of the above"}</SmallTextMain>
        <Checkbox name="otherOption" />
      </ChoiceWrapper>
    </div>
  );
}
