import classNames from "classnames";
import { type PropsWithChildren, useEffect, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useResize } from "../../hooks";
import { positionStyles } from "../styles";
import type { PropsWithClassName, PropsWithName } from "../types";
import { ResizeHandle } from "./ResizeHandle";

interface Props extends PropsWithClassName, PropsWithChildren, PropsWithName {
  maxWidth: number | null;
}
export function Resizable({ className, children, maxWidth, name }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const { onMouseDown, onTouchStart, width } = useResize(ref, { maxWidth });
  const pixelWidth = useWatch({ name });

  const { register, setValue } = useFormContext();

  useEffect(() => {
    register(name);
  }, [register, name]);

  useEffect(() => {
    if (width !== Number.POSITIVE_INFINITY) {
      setValue(name, width, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    }
  }, [width, setValue, name]);

  const style = pixelWidth ? { width: pixelWidth } : {};

  return (
    <div style={style} ref={ref} className={classNames(positionStyles.relative, className)}>
      <ResizeHandle
        type="left"
        onMouseDown={(event) => {
          onMouseDown(event, "negative");
        }}
        onTouchStart={(event) => {
          onTouchStart(event, "negative");
        }}
      />
      <ResizeHandle
        type="right"
        onMouseDown={(event) => {
          onMouseDown(event, "positive");
        }}
        onTouchStart={(event) => {
          onTouchStart(event, "positive");
        }}
      />
      {children}
    </div>
  );
}
