import { useEffect } from "react";
import type { PersistedContactGroup } from "schemas";
import { useIdParam } from "../../hooks";
import { apiRequest } from "../apiRequest";
import { useLiteSiteStore } from "../store";
import { setContactGroup } from "./setContactGroup";

export async function getContactGroup(id: string) {
  const contactGroup = await apiRequest<PersistedContactGroup>({ url: `contactGroups/${id}` });
  setContactGroup(contactGroup);
}

export function useContactGroup(passedId?: string) {
  const paramId = useIdParam("contactGroupId");
  const id = passedId || paramId;
  const contactGroup = useLiteSiteStore(({ contactGroups }) => contactGroups.get(id));
  useEffect(() => {
    if (id && !contactGroup) {
      getContactGroup(id);
    }
  }, [id, contactGroup]);

  return contactGroup;
}
