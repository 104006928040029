import type { RouteObject } from "react-router";
import { AddDrawer, Edit, New } from "../components/blocks";

export const OutletBlockRoutes: RouteObject = {
  path: "blocks",
  children: [
    { path: "logo" },
    { path: "name" },
    { path: "description" },
    { path: "address" },
    {
      path: ":blockKind/:blockId",
      children: [{ path: ":addDestinationIndex" }],
    },
    {
      path: ":blockKind/:blockId/:destinationIndex/new",
      Component: AddDrawer,
    },
    {
      path: "new/:destinationIndex",
      Component: AddDrawer,
    },
    { path: "newInline/:destinationIndex/:blockKind" },
  ],
};
export const StandaloneBlockRoutes: RouteObject = {
  path: "blocks",
  children: [
    {
      path: "new/:destinationIndex/:blockKind",
      Component: New,
    },
    {
      path: "edit/:blockKind/:blockId",
      Component: Edit,
    },
  ],
};
