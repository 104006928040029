import classNames from "classnames";
import { useState } from "react";
import { useWatch } from "react-hook-form";
import type { PersistedContactGroup } from "schemas";
import { Drawer, InfoCard, Strong, TextMain, flexStyles, textStyles } from "ui";
import { SelectableContactGroup } from "../contactGroups";
import { Button, Link } from "../ui";

interface Props {
  contactGroups: PersistedContactGroup[];
}
export function RecipientsContactGroupsExcludes({ contactGroups }: Props) {
  const name = "excludedContactGroupIds";
  const [open, setOpen] = useState<boolean>(false);
  const excludedIds = useWatch({ name });

  const filtered = contactGroups.filter((group) => !group.autogeneratedType);

  const selectedNames = contactGroups
    .filter((group) => excludedIds.includes(group.id))
    .map((group) => group.name)
    .join(", ");
  const label = excludedIds.length > 0 ? `Excluded groups: ${selectedNames}` : "Exclude Recipients";

  return (
    <>
      <Link className={classNames(flexStyles.alignSelfStart, textStyles.underline)} onClick={() => setOpen(true)}>
        <TextMain>{label}</TextMain>
      </Link>
      <Drawer open={open} onClose={() => setOpen(false)} title="Exclude contact groups">
        <div className={flexStyles.vert050}>
          <InfoCard>
            <TextMain>
              Contacts from excluded groups <Strong>will not</Strong> receive this campaign. Exclusion always overrides
              selection.
            </TextMain>
          </InfoCard>
          {filtered.map((contactGroup) => (
            <SelectableContactGroup key={contactGroup.id} contactGroup={contactGroup} name={name} />
          ))}
          <Button buttonType="secondary" onClick={() => setOpen(false)}>
            Done
          </Button>
        </div>
      </Drawer>
    </>
  );
}
