import classNames from "classnames";
import { Icon } from "./Icon";
import { flexStyles, iconStyles, textStyles } from "./styles";
import { SmallTextMain } from "./typography";

export function SecuredByStripe() {
  return (
    <div className={classNames(flexStyles.vert050, flexStyles.alignCenter, textStyles.primary)}>
      <SmallTextMain>Secured by</SmallTextMain>
      <Icon className={iconStyles.stripe} iconType="StripeLogo" />
    </div>
  );
}
