import { type SocialLinksBlockPayload, SocialLinksBlockSchema } from "schemas";
import { FormProvider, FormTag, MultipleTextInputs } from "ui";
import { Submit } from "../ui";

interface Props {
  initialValues: SocialLinksBlockPayload;
  onSubmit: (payload: SocialLinksBlockPayload) => Promise<void>;
  cancelPath: string;
}

export function SocialLinksForm({ initialValues, onSubmit, cancelPath }: Props) {
  const wrapSubmit = async (payload: SocialLinksBlockPayload) => {
    await onSubmit({ ...payload, links: payload.links.filter(Boolean) });
  };

  return (
    <FormProvider<SocialLinksBlockPayload> schema={SocialLinksBlockSchema} defaultValues={initialValues}>
      <FormTag<SocialLinksBlockPayload> onSubmit={wrapSubmit}>
        <MultipleTextInputs name="links" type="url" label="Your social links" />
        <Submit cancelTo={cancelPath} />
      </FormTag>
    </FormProvider>
  );
}
