import {
  type MouseEvent as ReactMouseEvent,
  type TouchEvent as ReactTouchEvent,
  type RefObject,
  useEffect,
  useState,
} from "react";

type Options = {
  maxWidth: number | null;
};
type Direction = "positive" | "negative";
export function useResize(ref: RefObject<HTMLElement>, options: Options) {
  const { maxWidth } = options;
  const [handleX, setHandleX] = useState(Number.POSITIVE_INFINITY);
  const [startingWidth, setStartingWidth] = useState(Number.POSITIVE_INFINITY);
  const [width, setWidth] = useState(Number.POSITIVE_INFINITY);
  const [direction, setDirection] = useState<Direction>("positive");
  const [dragging, setDragging] = useState(false);

  const onMouseDown = (event: ReactMouseEvent, direction: Direction) => {
    event.stopPropagation();
    initResize(event.clientX, direction);
  };

  const onTouchStart = (event: ReactTouchEvent, direction: Direction) => {
    event.stopPropagation();
    initResize(event.touches[0].clientX, direction);
  };

  const initResize = (clientX: number, direction: Direction) => {
    if (!ref.current) {
      return;
    }

    setDragging(true);
    setDirection(direction);
    setHandleX(clientX);
    const { width } = window.getComputedStyle(ref.current);
    setStartingWidth(Number.parseInt(width, 10));
    setWidth(Number.parseInt(width, 10));
  };

  useEffect(() => {
    const doMouseDrag = (event: MouseEvent) => {
      doDrag(event.clientX);
    };

    const doTouchDrag = (event: TouchEvent) => {
      doDrag(event.touches[0].clientX);
    };

    const doDrag = (clientX: number) => {
      if (!ref.current || !dragging) {
        return;
      }

      // Calculate the box size
      const newWidth = direction === "positive" ? startingWidth + clientX - handleX : startingWidth + handleX - clientX;

      setWidth(maxWidth ? Math.min(newWidth, maxWidth) : newWidth);
    };

    const stopDrag = () => {
      setDragging(false);
      document.removeEventListener("touchmove", doTouchDrag, false);
      document.removeEventListener("touchend", stopDrag, false);
      document.removeEventListener("mousemove", doMouseDrag, false);
      document.removeEventListener("mouseup", stopDrag, false);
    };

    document.addEventListener("touchmove", doTouchDrag, false);
    document.addEventListener("touchend", stopDrag, false);
    document.addEventListener("mousemove", doMouseDrag, false);
    document.addEventListener("mouseup", stopDrag, false);
  }, [startingWidth, handleX, maxWidth, direction, ref, dragging]);

  return { onMouseDown, onTouchStart, width };
}
