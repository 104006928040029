import classNames from "classnames";
import { type PropsWithClassName, flexStyles } from "ui";
import { useNewItems } from "../../store";
import { DesktopLink } from "./DesktopLink";

export function DesktopMenu({ className }: PropsWithClassName) {
  const { newItems } = useNewItems();
  const bookingsDot = newItems && newItems.bookings.length > 0;
  const formsDot = newItems && newItems.formAnswers.length > 0;
  return (
    <nav className={classNames(flexStyles.vert, className)}>
      <DesktopLink animationType="DesktopMenuCampaigns" href="/campaigns" label="Email" />
      <DesktopLink animationType="DesktopMenuContacts" href="/contacts" label="Contacts" />
      <DesktopLink animationType="DesktopMenuLink" href="/content" label="Link In Bio" />
      <DesktopLink smallIcon animationType="DesktopMenuForms" href="/forms" label="Forms" hasDot={formsDot} />
      <DesktopLink
        smallIcon
        animationType="DesktopMenuAppointments"
        href="/dashboard"
        label="Appointments"
        hasDot={bookingsDot}
      />
    </nav>
  );
}
