import Button from "react-google-button";
import type { GoogleAuthUrlType } from "schemas";
import type { PropsWithClassName } from "ui";
import { useGoogleAuthUrl } from "../../store";

interface Props extends PropsWithClassName {
  type: GoogleAuthUrlType;
  label: string;
  disabled?: boolean;
  link?: string;
  redirect?: string;
}

export function GoogleButton({ className, link, label, type, redirect, disabled = false }: Props) {
  const googleAuthUrl = useGoogleAuthUrl({ type, payload: { link, redirect } });

  return (
    <Button
      style={{ width: "100%", boxShadow: "none" }}
      className={className}
      label={label}
      onClick={() => {
        // biome-ignore lint/suspicious:
        googleAuthUrl && (window.location.href = googleAuthUrl);
      }}
      disabled={disabled || !googleAuthUrl}
    />
  );
}
