import { useNavigate } from "react-router";
import type { ContactPayload } from "schemas";
import { Loader, paddingStyles } from "ui";
import { updateContact, useContact } from "../../store";
import { Overlay } from "../ui";
import { Form } from "./Form";

export function Edit() {
  const navigate = useNavigate();
  const goBack = () => navigate("..", { relative: "path" });
  const contact = useContact();

  if (!contact) {
    return <Loader full />;
  }

  const onSubmit = async (values: ContactPayload) => {
    await updateContact(contact.id, values);
    goBack();
  };

  return (
    <Overlay className={paddingStyles.p125} header="Edit contact" backTo={{ to: "..", label: "Contact Card" }}>
      <Form values={contact} onSubmit={onSubmit} />
    </Overlay>
  );
}
