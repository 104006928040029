import type { SVGProps } from "react";
const SvgStripeLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={53} height={22} viewBox="0 0 53 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.388 11.31c0-3.682-1.783-6.588-5.192-6.588-3.423 0-5.494 2.906-5.494 6.559 0 4.329 2.445 6.515 5.954 6.515 1.712 0 3.006-.389 3.984-.935v-2.877c-.978.49-2.1.791-3.524.791-1.395 0-2.632-.489-2.79-2.186h7.033c0-.187.029-.934.029-1.28Zm-7.105-1.367c0-1.625.992-2.301 1.898-2.301.878 0 1.813.676 1.813 2.301h-3.71Zm-9.133-5.22c-1.409 0-2.315.661-2.818 1.121l-.187-.891H29.98v16.769l3.595-.762.014-4.07c.518.374 1.28.906 2.546.906 2.575 0 4.919-2.071 4.919-6.63-.015-4.171-2.388-6.444-4.904-6.444Zm-.862 9.909c-.849 0-1.352-.302-1.697-.676l-.015-5.336c.374-.417.892-.705 1.712-.705 1.308 0 2.214 1.467 2.214 3.351 0 1.928-.891 3.366-2.214 3.366ZM25.033 3.874l3.61-.777V.177l-3.61.763v2.934Zm0 1.093h3.61V17.55h-3.61V4.967ZM21.164 6.03l-.23-1.064h-3.106V17.55h3.595V9.023c.849-1.108 2.287-.906 2.733-.748V4.967c-.46-.173-2.143-.489-2.991 1.064Zm-7.19-4.185-3.51.748-.014 11.52c0 2.128 1.596 3.696 3.725 3.696 1.18 0 2.042-.216 2.517-.474v-2.92c-.46.187-2.733.848-2.733-1.28V8.03h2.733V4.967h-2.733l.014-3.12ZM4.25 8.62c0-.56.46-.777 1.223-.777 1.093 0 2.473.331 3.566.92V5.385c-1.193-.475-2.373-.662-3.566-.662-2.92 0-4.861 1.525-4.861 4.07 0 3.97 5.465 3.337 5.465 5.049 0 .661-.576.877-1.381.877-1.194 0-2.718-.489-3.926-1.15v3.422a9.97 9.97 0 0 0 3.926.82c2.992 0 5.048-1.481 5.048-4.056-.014-4.285-5.494-3.523-5.494-5.134Z"
      fill="#635bff"
    />
  </svg>
);
export default SvgStripeLogo;
