import { useEffect } from "react";
import { useIdParam } from "../../hooks";
import { useLiteSiteStore } from "../store";
import { getContact } from "./getContact";

export function useContact(passedId?: string) {
  const urlId = useIdParam("contactId");
  const id = passedId || urlId;
  const contact = useLiteSiteStore((state) => state.contacts.get(id));
  useEffect(() => {
    if (id) {
      getContact(id);
    }
  }, [id]);

  return contact;
}
