import type { SVGProps } from "react";

const SvgCustomersSquiggle = (props: SVGProps<SVGSVGElement>) => (
  <svg width={46} height={27} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M42.552 1.018c1.602 3.886 1.115 7.474 1.616 11.658.179 1.492-.411 3.058-1.78 3.657-1.728.757-2.115-.089-3.4-1.378-2.037-2.046-3.693-4.949-3.25-7.935.172-1.169-.755 2.218-1.16 3.315-.97 2.632-1.996 5.042-3.74 7.196-2.505 3.096-7.293 2.29-9.303-1.47-.952-1.778-.842-3.174-.32-4.975.322-1.112.922-1.818.472-.08-1.033 3.999-4.663 8.082-7.641 10.61-1.547 1.312-4.413 4.062-6.7 3.396-2.696-.785-8.03-4.826-4.012-7.31"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgCustomersSquiggle;
