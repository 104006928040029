import classNames from "classnames";
import { toast } from "react-toastify";
import { Card, Code, DescriptionAccent, Icon, flexStyles, iconStyles, paddingStyles } from "ui";
import { validationIcon } from "./validationIcon";
import { verificationDescription } from "./verificationDescription";

type Dns = {
  data: string;
  host: string;
  type: "cname";
  valid: boolean;
};

interface Props {
  name: string;
  record: Dns;
}
export function DnsRecord({ name, record }: Props) {
  const { valid, host, data } = record;
  const onCopy = () => {
    toast.success("Copied to clipboard", { autoClose: 500 });
  };

  return (
    <Card className={classNames(flexStyles.vert125, paddingStyles.p125)}>
      <DescriptionAccent>{name}</DescriptionAccent>
      <div className={flexStyles.vert025}>
        <DescriptionAccent>Record Type</DescriptionAccent>
        <Code>CNAME</Code>
      </div>
      <div className={flexStyles.vert025}>
        <DescriptionAccent>Host</DescriptionAccent>
        <Code copyable onCopy={onCopy}>
          {host}
        </Code>
      </div>
      <div className={flexStyles.vert025}>
        <DescriptionAccent>Value</DescriptionAccent>
        <Code copyable onCopy={onCopy}>
          {data}
        </Code>
      </div>
      <div
        className={classNames(flexStyles.horiz050, flexStyles.alignCenter, {
          [iconStyles.successMedium]: valid,
          [iconStyles.warningMedium]: !valid,
        })}
      >
        <Icon iconType={validationIcon(valid)} />
        <DescriptionAccent>{verificationDescription(valid)}</DescriptionAccent>
      </div>
    </Card>
  );
}
