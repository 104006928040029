import type { SVGProps } from "react";

const SvgEventCanceled = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="EventCanceled_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#EventCanceled_svg__a)">
      <path
        d="M8.312 15.5 7.25 14.438l1.688-1.688-1.688-1.688L8.312 10 10 11.688 11.688 10l1.062 1.062-1.688 1.688 1.688 1.688-1.062 1.062L10 13.812 8.312 15.5ZM4.5 18c-.417 0-.77-.15-1.062-.448A1.456 1.456 0 0 1 3 16.5v-11c0-.403.146-.753.438-1.052A1.428 1.428 0 0 1 4.5 4H6V2h1.5v2h5V2H14v2h1.5c.417 0 .77.15 1.062.448.292.299.438.65.438 1.052v11c0 .403-.146.753-.438 1.052A1.428 1.428 0 0 1 15.5 18h-11Zm0-1.5h11V9h-11v7.5Zm0-9h11v-2h-11v2Z"
        fill="#B7424F"
      />
    </g>
  </svg>
);
export default SvgEventCanceled;
