import classNames from "classnames";
import type { PropsWithChildren } from "react";
import { DefaultTheme, type FontFamily } from "schemas";
import { type PropsWithClassName, RawThemedWrapper, textStyles } from "ui";

interface Props extends PropsWithClassName, PropsWithChildren {
  fontFamily: FontFamily;
}
export function FontPreview({ className, fontFamily, children }: Props) {
  return (
    <RawThemedWrapper theme={{ ...DefaultTheme, fontFamily }} className={classNames(textStyles.fontTheme, className)}>
      {children}
    </RawThemedWrapper>
  );
}
