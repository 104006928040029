import type { SVGProps } from "react";

const SvgArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.76 17 2.12 10.295a.416.416 0 0 1-.09-.454.416.416 0 0 1 .09-.136L8.76 3 9.86 4.112l-5.829 5.889 5.83 5.887L8.76 17Z"
      fill="#fff"
    />
    <path d="M17 9.087H3.25v1.826H17V9.087Z" fill="#fff" />
  </svg>
);
export default SvgArrowLeft;
