import type { ThemePayload } from "../schemas/Theme";
import { gradientCss } from "./gradientCss";
import { gradientForTheme } from "./gradientForTheme";

export function themePrimary(theme: ThemePayload): string {
  switch (theme.type) {
    case "SolidColor":
      return theme.primaryColor;
    case "Gradient":
      return gradientCss(gradientForTheme(theme));
  }
}
