import classNames from "classnames";
import { TextMain, flexStyles, paddingStyles, textStyles } from "ui";
import { Link } from "../ui";

interface Props {
  destination: "login" | "signup";
}
export function TheOtherMode({ destination }: Props) {
  return (
    <div className={classNames(flexStyles.vert125, flexStyles.alignCenter, textStyles.center, paddingStyles.pb200)}>
      <TextMain>{destination === "login" ? "Already have an account?" : "Don't have an account?"}</TextMain>
      <Link to={`/auth/${destination}`}>{destination.toUpperCase()}</Link>
    </div>
  );
}
