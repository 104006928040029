import classNames from "classnames";
import {
  ContentWrapper,
  type PropsWithClassNameAndChildren,
  ResponsiveWrapper,
  bgStyles,
  flexStyles,
  paddingStyles,
} from "ui";

interface Props extends PropsWithClassNameAndChildren {
  style?: React.CSSProperties;
  responsive?: boolean;
}
export function HomeWrapper({ children, className, style, responsive }: Props) {
  const Wrapper = responsive ? ResponsiveWrapper : ContentWrapper;
  return (
    <div style={style} className={classNames(flexStyles.grow, bgStyles.neutral100, className)}>
      <Wrapper className={classNames(flexStyles.gap125, paddingStyles.pb625)}>{children}</Wrapper>
    </div>
  );
}
