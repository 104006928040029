import { type Asserts, object } from "yup";
import { BusinessNameString } from "./Business";
import { PasswordString } from "./password";
import { CaptchaString, EmailValidation } from "./shared";

export const SignupSchema = object({
  business: object({
    name: BusinessNameString,
  }),
  user: object({
    email: EmailValidation.required("Email is required"),
    password: PasswordString,
  }),
  captchaResponse: CaptchaString,
});
export type SignupPayload = Asserts<typeof SignupSchema>;

export const EmptySignup: SignupPayload = {
  business: {
    name: "",
  },
  user: {
    email: "",
    password: "",
  },
  captchaResponse: "",
};
