import { useEffect } from "react";
import type { BlockKind, PersistedBlock } from "schemas";
import { useLiteSiteStore } from "../store";
import { getBlock } from "./getBlock";

export function useBlock(kind: BlockKind, id: string) {
  const block: PersistedBlock | undefined = useLiteSiteStore((state) => {
    switch (kind) {
      case "AppointmentBlock":
        return state.blockAppointmentBlocks.get(id);
      case "AppointmentLinkBlock":
        return state.blockAppointmentLinkBlocks.get(id);
      case "EmailBlock":
        return state.blockEmailBlocks.get(id);
      case "FormLinkBlock":
        return state.blockFormLinkBlocks.get(id);
      case "ImageBlock":
        return state.blockImageBlocks.get(id);
      case "LinkBlock":
        return state.blockLinkBlocks.get(id);
      case "PageBlock":
        return state.blockPageBlocks.get(id);
      case "PhoneBlock":
        return state.blockPhoneBlocks.get(id);
      case "SocialLinksBlock":
        return state.blockSocialLinksBlocks.get(id);
      case "TextBlock":
        return state.blockTextBlocks.get(id);
      case "VideoBlock":
        return state.blockVideoBlocks.get(id);
      case "TwoColumnsBlock":
        return state.blockTwoColumnsBlocks.get(id);
    }
  });

  useEffect(() => {
    if (!block) {
      getBlock(kind, id);
    }
  }, [id, kind, block]);

  return block;
}
