import { useEffect, useState } from "react";
import type { PersisitedContactImportCandidate } from "schemas";
import { useIdParam } from "../../hooks";
import { apiRequest } from "../apiRequest";
import type { HookLoading } from "../types";

export function useContactImportCandidates(
  duplicates = false,
): HookLoading<{ candidates: PersisitedContactImportCandidate[] }> {
  const [loading, setLoading] = useState<boolean>(true);
  const [candidates, setCandidates] = useState<PersisitedContactImportCandidate[]>([]);
  const id = useIdParam<number>("contactImportId");
  useEffect(() => {
    if (id) {
      const params = new URLSearchParams(duplicates ? { duplicates: "1" } : {});
      apiRequest<{ candidates: PersisitedContactImportCandidate[] }>({
        url: `contactImports/${id}/candidates?${params.toString()}`,
      })
        .then(({ candidates }) => setCandidates(candidates))
        .then(() => setLoading(false));
    }
  }, [duplicates, id]);

  return { loading, candidates };
}
