"use client";

import classNames from "classnames";
import { useLayoutEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import LazyReactPlayer from "react-player/lazy";
import { useWindowSize } from "react-use";
import type { VideoBlockPayload } from "schemas";
import { Card } from "../Card";
import { overflowStyles, paddingStyles, positionStyles, widthStyles } from "../styles";

interface Props {
  block: VideoBlockPayload;
  lazy: boolean;
  disabled?: boolean;
}
export function VideoCard({ block, lazy, disabled = false }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const { width: windowWidth } = useWindowSize();

  useLayoutEffect(() => {
    if (ref?.current?.offsetWidth && windowWidth) {
      setWidth(ref.current.offsetWidth);
      setHeight((width * 9) / 16);
    }
  }, [width, windowWidth]);

  const playerWidth = width > 0 ? `${width}px` : "100%";

  const VideoPlayer = lazy ? LazyReactPlayer : ReactPlayer;

  return (
    <Card
      className={classNames(widthStyles.full, overflowStyles.hidden, paddingStyles.p0, positionStyles.relative)}
      ref={ref}
    >
      {disabled && (
        <div className={classNames(positionStyles.absolute, positionStyles.cover, positionStyles.zIndex1)} />
      )}
      <VideoPlayer url={block.video} controls height={height} width={playerWidth} />
    </Card>
  );
}
