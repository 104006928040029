import type { SVGProps } from "react";

const SvgError = (props: SVGProps<SVGSVGElement>) => (
  <svg width={81} height={80} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.037 13.416a2 2 0 0 1-2.642 1.01c-12.93-5.782-28.4-.96-35.639 11.575-7.237 12.537-3.678 28.347 7.795 36.654a2 2 0 1 1-2.346 3.24C8.09 56.398 4.019 38.332 12.292 24.003c8.273-14.33 25.954-19.838 40.736-13.228a2 2 0 0 1 1.01 2.642ZM25.964 66.583a2 2 0 0 1 2.642-1.01c12.93 5.781 28.401.96 35.639-11.576 7.237-12.536 3.677-28.345-7.795-36.652a2 2 0 1 1 2.346-3.24c13.115 9.497 17.187 27.563 8.912 41.892-8.273 14.33-25.953 19.838-40.735 13.228a2 2 0 0 1-1.01-2.642Z"
      fill="#B7424F"
    />
    <path
      d="M40 23a2.92 2.92 0 0 1 2.92 2.999l-.501 18.646a2.42 2.42 0 0 1-4.838 0L37.081 26A2.92 2.92 0 0 1 40 23Z"
      fill="#B7424F"
    />
    <circle cx={40} cy={53} r={3} fill="#B7424F" />
  </svg>
);
export default SvgError;
