import classNames from "classnames";
import {
  Icon,
  type PropsWithClassName,
  type PropsWithIconType,
  type PropsWithTo,
  TextMain,
  bgStyles,
  borderStyles,
  flexStyles,
  iconStyles,
  paddingStyles,
  pointerStyles,
  textStyles,
} from "ui";
import { RawLink } from "./Link";

interface Props extends PropsWithClassName, PropsWithTo, PropsWithIconType {
  title: string;
}

export function IconBoxLink({ className, title, iconType, to }: Props) {
  return (
    <RawLink
      className={classNames(
        bgStyles.neutral0,
        iconStyles.secondary300,
        textStyles.decorationNone,
        flexStyles.horiz050,
        flexStyles.alignStart,
        borderStyles.bottomNeutral200,
        borderStyles.topFirstChildNeutral200,
        paddingStyles.p125,
        pointerStyles.cursor,
        className,
      )}
      to={to}
    >
      <Icon className={paddingStyles.pt012} iconType={iconType} />
      <TextMain>{title}</TextMain>
    </RawLink>
  );
}
