import type { PersistedPrompt, PromptPayload } from "schemas";
import { apiRequest } from "../apiRequest";

interface Args {
  payload: PromptPayload;
}

export async function createPrompt({ payload }: Args) {
  const { prompt } = await apiRequest<{ prompt: PersistedPrompt }>({
    url: "prompts",
    method: "POST",
    body: JSON.stringify(payload),
  });

  return prompt;
}
