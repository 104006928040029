"use client";

import classNames from "classnames";
import type { ChangeEvent } from "react";
import { bgStyles, textStyles } from "../../styles";
import type { PropsWithClassName } from "../../types";
import styles from "../forms.module.css";

interface Props extends PropsWithClassName {
  name: string;
  type: "text" | "url" | "email";
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string | null;
}
export function SmallTextInput({ name, type = "text", placeholder, onChange, className, value }: Props) {
  return (
    <div className={className}>
      <input
        className={classNames(textStyles.fontPoppins, bgStyles.neutral0, styles.input, styles.small, className)}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        value={value || ""}
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
      />
    </div>
  );
}
