import { useNavigate } from "react-router";
import { type ThemePayload, ThemeSchema } from "schemas";
import { Drawer, FormProvider, FormTag, flexStyles } from "ui";
import { updateTheme, useTheme } from "../../store";
import { Submit } from "../ui";
import { AlignmentRadio } from "./AlignmentRadio";
import { ButtonColorRadio } from "./ButtonColorRadio";
import { FormButtonPreview } from "./FormButtonPreview";
import { RadiusRadio } from "./RadiusRadio";

export function Buttons() {
  const navigate = useNavigate();
  const theme = useTheme();
  const onClose = () => navigate("../../..", { relative: "path" });

  const onSubmit = async (payload: ThemePayload) => {
    await updateTheme(theme.id, payload);
    onClose();
  };

  return (
    <Drawer onClose={onClose} title="Adjust button shape" open>
      <FormProvider<ThemePayload> defaultValues={theme} schema={ThemeSchema}>
        <FormTag<ThemePayload> onSubmit={onSubmit}>
          <div className={flexStyles.vert200}>
            <FormButtonPreview />
          </div>
          <RadiusRadio />
          <AlignmentRadio />
          <ButtonColorRadio />
          <Submit cancelTo="../../.." label="apply changes" disableInvalid />
        </FormTag>
      </FormProvider>
    </Drawer>
  );
}
