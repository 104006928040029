import type { NewItem, ViewedItemsPayload } from "schemas";
import { apiRequest } from "../apiRequest";
import { setNewItems } from "./setNewItems";

export async function updateViewedItems(payload: ViewedItemsPayload) {
  const newItems = await apiRequest<NewItem>({
    url: "business/newItems/viewed",
    method: "PATCH",
    body: JSON.stringify(payload),
  });
  setNewItems(newItems);
}
