import classNames from "classnames";
import { Loader, flexStyles, paddingStyles } from "ui";
import { useForm } from "../../store";
import { Overlay } from "../ui";
import { FormResponseCard } from "./FormResponseCard";

export function Responses() {
  const { form } = useForm();

  if (!form) {
    return <Loader />;
  }

  return (
    <Overlay
      backTo={{ to: "/forms", label: "Form" }}
      header={`${form.name} - Responses`}
      title="Form Responses"
      className={classNames(flexStyles.gap100, paddingStyles.p125)}
    >
      {form.formAnswers.map((formAnswer) => (
        <FormResponseCard key={formAnswer.id} formAnswer={formAnswer} />
      ))}
    </Overlay>
  );
}
