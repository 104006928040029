import type { BlockKind, PersistedBlock } from "schemas";
import { apiRequest } from "../apiRequest";
import { setBlock } from "./setBlock";

export async function getBlock(kind: BlockKind, id: string) {
  try {
    const { block } = await apiRequest<{ block: PersistedBlock }>({
      url: `blocks/${kind}/${id}`,
    });
    setBlock(block);
  } catch {
    // not found
  }
}
