import type { SVGProps } from "react";

const SvgChevronSmallDown = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.219 12.66 6.244 9.506a.9.9 0 0 1-.228-.452.932.932 0 0 1 .047-.51c.064-.161.17-.299.307-.396A.801.801 0 0 1 6.833 8h6.33a.8.8 0 0 1 .463.149.873.873 0 0 1 .307.396c.063.162.08.34.047.51a.9.9 0 0 1-.228.452l-2.975 3.152a1.073 1.073 0 0 1-.78.341c-.291 0-.571-.122-.778-.34Z"
      fill="#465357"
    />
  </svg>
);
export default SvgChevronSmallDown;
