import { omit } from "es-toolkit";
import type { PropsWithChildren, ReactNode } from "react";
import { useSearchParams } from "react-router";
import { CaretLabel, Drawer, flexStyles } from "ui";

interface Props extends PropsWithChildren {
  label: string;
  drawerTitle: string;
  extra?: ReactNode;
}
export function SwitcherScaffold({ label, drawerTitle, extra, children }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const switcherVisible = !!searchParams.get("switcher");
  // @ts-expect-error will be gone
  const closeSwitcher = async () => setSearchParams(omit(searchParams, ["switcher"]));
  const openSwitcher = async () => setSearchParams({ ...searchParams, switcher: "1" });

  return (
    <>
      <div className={flexStyles.horizApart}>
        <CaretLabel onClick={openSwitcher}>{label}</CaretLabel>
        {extra}
      </div>
      <Drawer title={drawerTitle} open={switcherVisible} onClose={closeSwitcher}>
        {children}
      </Drawer>
    </>
  );
}
