import type { ImageBlockPayload } from "./ImageBlock";
import type { TextBlockPayload } from "./TextBlock";
import type { TwoColumnsBlockPayload, TwoColumnsBlockVariant } from "./TwoColumnsBlockSchema";
import { emptyBlock } from "./emptyBlock";

export function emptyTwoColumnsBlock(variant: TwoColumnsBlockVariant): TwoColumnsBlockPayload {
  const base = {
    kind: "TwoColumnsBlock" as const,
    widthPercentage: 50,
  };
  switch (variant) {
    case "ImageAndText":
      return {
        ...base,
        leftImageBlock: emptyBlock("ImageBlock") as ImageBlockPayload,
        leftTextBlock: null,
        rightTextBlock: emptyBlock("TextBlock") as TextBlockPayload,
        rightImageBlock: null,
      };
    case "TextAndImage":
      return {
        ...base,
        leftTextBlock: emptyBlock("TextBlock") as TextBlockPayload,
        leftImageBlock: null,
        rightImageBlock: emptyBlock("ImageBlock") as ImageBlockPayload,
        rightTextBlock: null,
      };
    case "ImageAndImage":
      return {
        ...base,
        leftImageBlock: emptyBlock("ImageBlock") as ImageBlockPayload,
        leftTextBlock: null,
        rightImageBlock: emptyBlock("ImageBlock") as ImageBlockPayload,
        rightTextBlock: null,
      };
    case "TextAndText":
      return {
        ...base,
        leftTextBlock: emptyBlock("TextBlock") as TextBlockPayload,
        leftImageBlock: null,
        rightTextBlock: emptyBlock("TextBlock") as TextBlockPayload,
        rightImageBlock: null,
      };
  }
}
