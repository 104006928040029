import { useEffect } from "react";
import type { PersistedFormAnswer } from "schemas";
import { apiRequest } from "../apiRequest";
import { useLiteSiteStore } from "../store";

async function getContactFormAnswers(id: string) {
  const { formAnswers } = await apiRequest<{ formAnswers: PersistedFormAnswer[] }>({
    url: `contacts/${id}/formAnswers`,
  });

  useLiteSiteStore.setState(({ contactFormAnswers }) => ({
    contactFormAnswers: contactFormAnswers.set(id, formAnswers),
  }));
}

export function useContactFormAnswers(id: string) {
  useEffect(() => {
    if (id) {
      getContactFormAnswers(id);
    }
  }, [id]);

  return useLiteSiteStore((state) => state.contactFormAnswers.get(id));
}
