import classNames from "classnames";
import { type PersistedTheme, ThemeVariationValues, nextValue } from "schemas";
import { Icon, flexStyles, paddingStyles } from "ui";
import { updateTheme } from "../../store";
import { Link } from "../ui";

interface Props {
  theme: PersistedTheme;
}
export function ShuffleTheme({ theme }: Props) {
  const shuffleTheme = async () => {
    const payload = { ...theme, variation: nextValue(theme.variation, ThemeVariationValues) };
    await updateTheme(theme.id, payload);
  };
  return (
    <Link
      className={classNames(paddingStyles.py025, flexStyles.horiz050, flexStyles.alignCenter)}
      onClick={shuffleTheme}
    >
      Shuffle
      <Icon iconType="Shuffle" />
    </Link>
  );
}
