import classNames from "classnames";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Description, Loader, TextAccent, TextMain, flexStyles, marginStyles, paddingStyles } from "ui";
import { useBackLocation } from "../../hooks";
import { getUrlWithBack } from "../../hooks/useUrlWithBack";
import { updateAppointment, useAppointment, useForms } from "../../store";
import { Button, Link, Overlay, RadioCard } from "../ui";

export function AddForm() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams() as { id: string };
  const appointment = useAppointment(id);
  const { loading, forms } = useForms();
  const [selectedForm, setSelectedForm] = useState<string>();
  const back = useBackLocation("/content");

  useEffect(() => {
    if (appointment?.formId) {
      setSelectedForm(appointment.formId);
    }
  }, [appointment]);

  if (appointment && !loading && forms.length === 0) {
    navigate(getUrlWithBack(`/forms/new/${id}`, back));
  }

  function selectForm(id: string) {
    return async () => {
      setSelectedForm(id);
    };
  }

  const onSubmit = async () => {
    if (selectedForm === "new") {
      navigate(getUrlWithBack(`/forms/new/${id}`, back));
    } else {
      if (appointment) {
        await updateAppointment(id, { ...appointment, formId: selectedForm });
        navigate(`/appointments/${id}/utilities`);
      }
    }
  };

  return (
    <Overlay
      className={classNames(paddingStyles.p125, flexStyles.gap100)}
      header="Add a Form"
      backTo={{ to: `/appointments/${id}/utilities`, label: "Utilities" }}
    >
      {!appointment ? (
        <Loader />
      ) : (
        <>
          <TextMain>Select a form to add this appointment or create a new form.</TextMain>
          <div className={flexStyles.vert050}>
            {forms.map((form) => (
              <RadioCard
                key={form.id}
                onClick={selectForm(form.id)}
                title={<TextAccent>{form.name}</TextAccent>}
                checked={selectedForm === form.id}
              >
                <Description>
                  {form.questions.length} questions
                  {form.usedInAppointments.length > 0 && (
                    <>
                      <br />
                      Used in {form.usedInAppointments.join(", ")}
                    </>
                  )}
                </Description>
                <Link to={getUrlWithBack(`/forms/${form.id}/preview`, pathname)}>Preview</Link>
              </RadioCard>
            ))}
            <RadioCard
              onClick={selectForm("new")}
              title={<TextAccent>Create a form</TextAccent>}
              checked={selectedForm === "new"}
            />
          </div>
          <Button className={marginStyles.mt100} disabled={!selectedForm} onClick={onSubmit}>
            {selectedForm === "new" ? "Continue" : "Save and continue"}
          </Button>
        </>
      )}
    </Overlay>
  );
}
