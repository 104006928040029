import type { SVGProps } from "react";
const SvgFormMultipleChoiceField = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="FormMultipleChoiceField_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#FormMultipleChoiceField_svg__a)">
      <path
        d="m8.938 13 4.958-4.938L12.833 7l-3.896 3.875-1.77-1.75-1.063 1.063L8.937 13ZM10 18a7.796 7.796 0 0 1-3.104-.625 8.064 8.064 0 0 1-2.552-1.719 8.063 8.063 0 0 1-1.719-2.552A7.797 7.797 0 0 1 2 10c0-1.111.208-2.15.625-3.115a8.064 8.064 0 0 1 4.27-4.26A7.797 7.797 0 0 1 10 2c1.111 0 2.15.208 3.115.625a8.095 8.095 0 0 1 4.26 4.26C17.792 7.851 18 8.89 18 10a7.797 7.797 0 0 1-.625 3.104 8.063 8.063 0 0 1-4.26 4.271A7.774 7.774 0 0 1 10 18Zm0-1.5c1.806 0 3.34-.632 4.604-1.896C15.868 13.34 16.5 11.806 16.5 10s-.632-3.34-1.896-4.604C13.34 4.132 11.806 3.5 10 3.5s-3.34.632-4.604 1.896C4.132 6.66 3.5 8.194 3.5 10s.632 3.34 1.896 4.604C6.66 15.868 8.194 16.5 10 16.5Z"
        fill="#F0A056"
      />
    </g>
  </svg>
);
export default SvgFormMultipleChoiceField;
