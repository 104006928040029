import classNames from "classnames";
import { Icon } from "../Icon";
import { bgStyles, borderStyles, flexStyles, iconStyles, paddingStyles, positionStyles } from "../styles";
import { SmallTextMain } from "../typography";

interface Props {
  label: string;
  onClick: (toggle: boolean) => void;
  open: boolean;
}
export function MoreRow({ open, onClick, label }: Props) {
  return (
    <div
      className={classNames(
        flexStyles.horiz062,
        flexStyles.alignCenter,
        iconStyles.successMedium,
        bgStyles.neutral0,
        paddingStyles.py050,
        paddingStyles.px125,
        borderStyles.bottomNeutral200,
        borderStyles.topFirstChildNeutral200,
        positionStyles.relative,
      )}
      onClick={() => onClick(!open)}
    >
      <SmallTextMain>{label}</SmallTextMain>
      <Icon
        className={classNames(iconStyles.neutral600, positionStyles.absolute, positionStyles.right100)}
        iconType={open ? "ChevronUp" : "ChevronDown"}
      />
    </div>
  );
}
