import classNames from "classnames/dedupe";
import { flexStyles } from "../../styles";
import { ImageCard } from "../ImageCard";
import { TextBlockCard } from "../TextBlockCard";
import type { Props } from "./Props";

export function SideBySide({ block }: Props) {
  const { leftTextBlock, rightTextBlock, leftImageBlock, rightImageBlock, widthPercentage } = block;
  return (
    <div className={classNames(flexStyles.horiz, flexStyles.gap100)}>
      <div style={{ flexBasis: `${widthPercentage}%` }}>
        {leftTextBlock && <TextBlockCard block={leftTextBlock} />}
        {leftImageBlock && <ImageCard disabled block={leftImageBlock} />}
      </div>
      <div style={{ flexBasis: `${100 - widthPercentage}%` }}>
        {rightTextBlock && <TextBlockCard block={rightTextBlock} />}
        {rightImageBlock && <ImageCard disabled block={rightImageBlock} />}
      </div>
    </div>
  );
}
