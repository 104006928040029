import classNames from "classnames";
import { Outlet, useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  BigLink,
  EmptyElements,
  Loader,
  type PopoverLinkItem,
  ThemedWrapper,
  flexStyles,
  marginStyles,
  paddingStyles,
  useShareHandler,
  widthStyles,
} from "ui";
import { useIdParam } from "../../hooks";
import { useCurrentBusiness, usePage, usePageElements, useTheme } from "../../store";
import { BlocksEditor } from "../blocks";
import { Overlay } from "../ui";
import { EditPageDetails } from "./EditPageDetails";

export function Content() {
  const id = useIdParam("pageId");
  const navigate = useNavigate();
  const business = useCurrentBusiness();
  const page = usePage(id);
  const pageElements = usePageElements(page?.id);
  const theme = useTheme(page?.themeId);
  const { onClick: sharePage } = useShareHandler(page?.url || "", toast);

  if (!page || !theme) {
    return <Loader />;
  }

  const linkLinks: PopoverLinkItem[] = [
    {
      label: "Preview",
      onClick: async () => {
        window.open(page.url);
      },
      iconType: "Show",
    },
    {
      label: "Edit Link",
      onClick: () => navigate("edit"),
      iconType: "Edit",
    },
    {
      label: "Share",
      onClick: sharePage,
      iconType: "Share",
    },
  ];
  const empty = pageElements?.length === 0 && (
    <EmptyElements
      addElement={async () => {
        navigate("blocks/new/1");
      }}
    />
  );

  return (
    <Overlay full backTo={{ to: "/content", label: "Link in Bio" }} header="Page Block" title="Edit Page">
      <ThemedWrapper theme={theme}>
        <BigLink
          border
          links={linkLinks}
          relativeUrl={`${business.relativeUrl}/${page.handle}`}
          host={import.meta.env.REACT_APP_PUBLIC_DOMAIN}
        />
        <div className={paddingStyles.p125}>
          <div className={classNames(widthStyles.full, widthStyles.maxWidth4225, marginStyles.centered)}>
            <EditPageDetails page={page} />
          </div>
        </div>
      </ThemedWrapper>
      <ThemedWrapper
        theme={{ ...theme, variation: "Gray" }}
        className={classNames(paddingStyles.p125, flexStyles.grow)}
      >
        <div className={classNames(widthStyles.full, widthStyles.maxWidth4225, marginStyles.centered)}>
          <BlocksEditor emptyBlock={empty} elements={pageElements || []} destination={{ type: "Page", entity: page }} />
        </div>
      </ThemedWrapper>
      <Outlet />
    </Overlay>
  );
}
