import { type Asserts, object, string } from "yup";
import type { ContactSource } from "../enums";
import { EmailValidation, NumberIdArray, PhoneNumber } from "./shared";

export const PublicContactSchema = object({
  name: string().nullable(),
  email: EmailValidation.required("Email is required").trim(),
  phone: PhoneNumber.nullable(),
}).noUnknown();
export type PublicContactPayload = Asserts<typeof PublicContactSchema>;

export const ContactSchema = PublicContactSchema.shape({
  note: string().nullable(),
  context: object().notRequired().noUnknown(false),
});
export type ContactPayload = Asserts<typeof ContactSchema>;
export type ContactKey = keyof ContactPayload;

export const ContactWithGroupsSchema = ContactSchema.shape({
  contactGroups: NumberIdArray,
});
export type ContactWithGroupsPayload = Asserts<typeof ContactWithGroupsSchema>;

export const EmptyContact: ContactPayload = {
  name: "",
  email: "",
  phone: "",
  note: "",
};

export type PersistedContact = ContactPayload & {
  id: string;
  displayName: string;
  confirmed: boolean;
  initial: string;
  initials: string;
  blocked: boolean;
  sendable: boolean;
  pending: boolean;
  source: ContactSource;
  context: Record<string, string>;
};
