import classNames from "classnames";
import {
  Icon,
  type PropsWithClassNameAndChildren,
  RawIcon,
  bgStyles,
  flexStyles,
  iconStyles,
  imageStyles,
  marginStyles,
  paddingStyles,
  positionStyles,
  textStyles,
  widthStyles,
} from "ui";
import logo from "../../images/PopSendFullLogo.svg";
import { AuthUnderline, Link } from "../ui";

const { REACT_APP_PUBLIC_DOMAIN } = import.meta.env;

interface Props extends PropsWithClassNameAndChildren {
  noTagline?: boolean;
}

export function AuthScreen({ className, children, noTagline = false }: Props) {
  return (
    <div className={classNames(bgStyles.secondary200, flexStyles.vert, flexStyles.grow)}>
      <div
        className={classNames(
          flexStyles.vert,
          marginStyles.centered,
          widthStyles.maxWidth2500,
          widthStyles.full,
          paddingStyles.px125,
        )}
      >
        <div
          className={classNames(iconStyles.primary, flexStyles.horizApart, flexStyles.alignCenter, paddingStyles.py125)}
        >
          <img alt="PopSend Logo" className={classNames(widthStyles.width500, imageStyles.fitContain)} src={logo} />
          <Link to={REACT_APP_PUBLIC_DOMAIN}>
            <Icon iconType="X" />
          </Link>
        </div>
        {!noTagline && (
          <div className={classNames(textStyles.fontCTA, paddingStyles.pb100, positionStyles.relative)}>
            Your place on the <AuthUnderline>internet</AuthUnderline>
            <RawIcon
              className={classNames(positionStyles.absolute, positionStyles.right0, positionStyles.top1)}
              iconType="AuthStarTop"
            />
            <RawIcon
              className={classNames(positionStyles.absolute, positionStyles.right0, positionStyles.bottom2)}
              iconType="AuthStarBottom"
            />
          </div>
        )}
      </div>
      <div
        className={classNames(
          flexStyles.vert,
          flexStyles.grow,
          bgStyles.neutral100,
          paddingStyles.pt100,
          positionStyles.relative,
        )}
      >
        <div
          className={classNames(
            flexStyles.vert125,
            flexStyles.alignStretch,
            marginStyles.centered,
            widthStyles.maxWidth2500,
            widthStyles.full,
            paddingStyles.px125,
            className,
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
