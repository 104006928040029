"use client";

import classNames from "classnames";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { RawIcon } from "../Icon";
import { flexStyles, pointerStyles } from "../styles";
import { TextAccent, TextMain } from "../typography";

interface Props {
  options: string[];
  name: string;
  otherOption: boolean;
  label: string;
  disabled?: boolean;
}
export function MultipleChoice({ options, name, label, otherOption, disabled = false }: Props) {
  const { register, setValue } = useFormContext();
  const selectedOptions = useWatch({ name });

  useEffect(() => {
    register(name);
  }, [register, name]);

  function onClick(option: string) {
    return () => {
      if (disabled) return;
      if (selectedOptions?.indexOf(option) > -1) {
        const newSelection = selectedOptions.filter((item: string) => item !== option);
        setValue(name, newSelection, { shouldValidate: true });
      } else {
        const newSelection = selectedOptions ? [...selectedOptions, option].sort() : [option];
        setValue(name, newSelection, { shouldValidate: true });
      }
    };
  }
  function radioIcon(option: string) {
    return selectedOptions?.indexOf(option) > -1 ? "CheckboxChecked" : "CheckboxUnchecked";
  }

  return (
    <div className={flexStyles.vert050}>
      <TextAccent>{label}</TextAccent>
      {options.map((option, index) => (
        <div
          className={classNames(flexStyles.horiz062, pointerStyles.cursor)}
          // biome-ignore lint/suspicious:
          key={`${option}_${index}`}
          onClick={onClick(option)}
        >
          <RawIcon iconType={radioIcon(option)} />
          <TextMain>{option}</TextMain>
        </div>
      ))}
      {otherOption && (
        <div className={classNames(flexStyles.horiz062, pointerStyles.cursor)} onClick={onClick("None of the above")}>
          <RawIcon iconType={radioIcon("None of the above")} />
          <TextMain>None of the above</TextMain>
        </div>
      )}
    </div>
  );
}
