import type { SVGProps } from "react";

const SvgHeroTwitter = (props: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={18} viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.11 5.91c.027-.545.02-1.007.08-1.465.362-2.904 3.852-4.76 6.302-3.172 1.154.747 2.003.622 3.039.112.211-.104.447-.158.978-.34l-1.333 2.127 1.72-.37c-.451.569-.68 1.036-1.054 1.293-.975.676-1.104 1.623-1.184 2.699-.813 10.808-11.812 13.032-18.106 9.354-.117-.067-.223-.151-.573-.391 2.308.054 4.255-.333 6.165-1.577-1.718-.55-3.174-1.215-3.747-3.045l1.15-.231C2.887 10 1.91 8.739 1.839 6.779c.454.088.847.166 1.433.283-1.394-1.757-1.94-3.507-.784-5.636C4.82 4.093 7.704 5.45 11.11 5.91h-.002Z"
      fill="#fff"
    />
  </svg>
);
export default SvgHeroTwitter;
