import classNames from "classnames";
import { type FC, Fragment, type PropsWithChildren } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { type FontFamily, fontFamilyCSS } from "schemas";
import { textStyles } from "../styles";
import type { PropsWithClassName } from "../types";
import styles from "./Markdown.module.css";

type MarkdownSize = "small" | "regular";
interface Props extends PropsWithClassName, PropsWithChildren {
  size?: MarkdownSize;
  fontFamily?: FontFamily;
  TextWrapper?: FC<PropsWithChildren>;
}

export function Markdown({ children, fontFamily, className, TextWrapper, size = "regular" }: Props) {
  const fontStyles = fontFamily ? fontFamilyCSS(fontFamily) : {};
  const components = {
    a(props: PropsWithChildren) {
      return (
        <a {...props} target="_blank">
          {props.children}
        </a>
      );
    },
    p(props: PropsWithChildren) {
      return (
        <p {...props} dir="auto">
          {props.children}
        </p>
      );
    },
    h1(props: PropsWithChildren) {
      return (
        <h1 {...props} dir="auto">
          {props.children}
        </h1>
      );
    },
    h2(props: PropsWithChildren) {
      return (
        <h1 {...props} dir="auto">
          {props.children}
        </h1>
      );
    },
    h3(props: PropsWithChildren) {
      return (
        <h1 {...props} dir="auto">
          {props.children}
        </h1>
      );
    },
  };
  const Wrapper = TextWrapper || Fragment;

  return (
    <div
      style={fontStyles}
      dir="auto"
      className={classNames(
        textStyles.lsColor,
        textStyles.weight600Strong,
        textStyles.preWrap,
        styles.wrapper,
        { [styles.small]: size === "small" },
        className,
      )}
    >
      <Wrapper>
        <ReactMarkdown remarkPlugins={[remarkGfm]} components={components}>
          {children as string}
        </ReactMarkdown>
      </Wrapper>
    </div>
  );
}
