import type { SVGProps } from "react";

const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="Settings_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#Settings_svg__a)">
      <path
        d="m7.792 18.028-.297-2.497c-.182-.088-.393-.2-.635-.335a5.835 5.835 0 0 1-.618-.39l-2.31.972-1.985-3.437 1.971-1.496c-.005-.11-.012-.23-.02-.363a5.715 5.715 0 0 1 0-.714c.008-.129.015-.26.02-.39L1.947 7.881l1.986-3.421 2.3.965a10.353 10.353 0 0 1 1.253-.734l.306-2.497h3.97L12.06 4.7c.209.104.417.217.627.339.21.12.41.25.602.387l2.334-.965 1.985 3.421-1.982 1.52c.016.12.025.243.028.366.002.124.004.239.004.343 0 .1-.003.211-.008.335l-.016.391 1.966 1.504-1.985 3.437-2.326-.98a8.518 8.518 0 0 1-1.229.725l-.296 2.505H7.792Zm1.953-5.354c.707 0 1.311-.25 1.812-.75.5-.501.75-1.105.75-1.813 0-.707-.25-1.31-.75-1.811-.5-.5-1.105-.751-1.812-.751-.702 0-1.304.25-1.808.75a2.462 2.462 0 0 0-.754 1.812c0 .708.251 1.312.754 1.812.504.5 1.106.75 1.808.75Zm0-1.333c-.333 0-.621-.122-.865-.365a1.182 1.182 0 0 1-.364-.865c0-.333.121-.621.364-.864.244-.243.532-.365.865-.365.333 0 .622.122.865.365s.364.531.364.864c0 .334-.121.622-.364.865a1.181 1.181 0 0 1-.865.365Zm-.801 5.354h1.658l.258-2.191a4.738 4.738 0 0 0 1.135-.484 5.33 5.33 0 0 0 1.029-.8l2.029.87.841-1.374-1.787-1.363c.07-.215.117-.423.142-.624.025-.2.038-.406.038-.618 0-.189-.013-.377-.038-.565a6.852 6.852 0 0 0-.12-.64l1.802-1.399-.841-1.375-2.053.883a4.903 4.903 0 0 0-1.024-.79c-.389-.228-.769-.39-1.14-.485l-.263-2.212H8.936L8.68 5.732c-.397.1-.776.254-1.136.461-.36.207-.704.475-1.03.801l-2.03-.862-.84 1.375L5.401 8.87a5.095 5.095 0 0 0-.167 1.25c0 .19.014.385.042.586.028.201.067.41.117.628l-1.75 1.383.841 1.375 2.021-.855a4.858 4.858 0 0 0 2.167 1.255l.27 2.204Z"
        fill="#F0A056"
      />
    </g>
  </svg>
);
export default SvgSettings;
