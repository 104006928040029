import type { SVGProps } from "react";

const SvgEditCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="EditCalendar_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#EditCalendar_svg__a)">
      <path
        d="M4.5 18c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 3 16.5v-11c0-.403.15-.753.448-1.052A1.44 1.44 0 0 1 4.5 4H6V2h1.5v2h5V2H14v2h1.5c.403 0 .753.15 1.052.448.299.299.448.65.448 1.052V10h-1.5V9h-11v7.5h5V18h-5Zm13.27-4L16 12.23l.646-.668a.73.73 0 0 1 1.062 0l.709.709a.73.73 0 0 1 0 1.062L17.77 14ZM11 19v-1.77l4.417-4.418 1.77 1.771L12.771 19H11ZM4.5 7.5h11v-2h-11v2Z"
        fill="#465357"
      />
    </g>
  </svg>
);
export default SvgEditCalendar;
