import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import {
  bgStyles,
  borderStyles,
  displayStyles,
  flexStyles,
  heightStyles,
  outlineStyles,
  paddingStyles,
  textStyles,
  widthStyles,
} from "../styles";
import type { PropsWithLabel, PropsWithName } from "../types";
import { Error } from "./Error";
import { Label } from "./Label";

interface Props extends PropsWithName, PropsWithLabel {
  domain: string;
}
export function EmailLocalInput({ name, label, domain }: Props) {
  const { register } = useFormContext();

  return (
    <div className={flexStyles.vert025}>
      <Label name={name}>{label}</Label>
      <div
        className={classNames(
          textStyles.size087,
          textStyles.weight400,
          flexStyles.horiz,
          flexStyles.alignCenter,
          widthStyles.full,
          borderStyles.secondary300ActiveWithin,
          heightStyles.height312,
          paddingStyles.p080,
          bgStyles.neutral0,
          borderStyles.neutral400,
          borderStyles.radius025,
        )}
      >
        <input
          {...register(name, {})}
          className={classNames(
            textStyles.fontPoppins,
            textStyles.size087,
            textStyles.neutral600,
            paddingStyles.p0,
            displayStyles.block,
            borderStyles.none,
            outlineStyles.none,
            bgStyles.none,
            flexStyles.grow,
          )}
          type="text"
        />
        <div className={classNames(textStyles.neutral400, textStyles.size087, textStyles.nowrap)}>@{domain}</div>
      </div>
      <Error name={name} />
    </div>
  );
}
