"use client";

import classNames from "classnames";
import { type PropsWithChildren, useState } from "react";
import { Icon } from "./Icon";
import { Link } from "./Link";
import { flexStyles, widthStyles } from "./styles";

interface Props {
  label: string;
  startOpen?: boolean;
}

export function Accordeon({ children, label, startOpen = false }: PropsWithChildren<Props>) {
  const [open, setOpen] = useState<boolean>(startOpen);

  const iconType = open ? "ChevronSmallUp" : "ChevronSmallDown";
  const prefix = open ? "Hide" : "Show";
  const fullLabel = (
    <>
      {prefix} {label}
    </>
  );

  const toggle = () => setOpen(!open);

  return (
    <div className={classNames(flexStyles.vert125, flexStyles.alignStart)}>
      <Link className={classNames(flexStyles.horiz050, flexStyles.alignCenter)} onClick={toggle}>
        {fullLabel}
        <Icon iconType={iconType} />
      </Link>
      {open && (
        <div
          className={classNames(flexStyles.vert125, flexStyles.alignStart, flexStyles.alignStretch, widthStyles.full)}
        >
          {children}
        </div>
      )}
    </div>
  );
}
