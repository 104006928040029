import classNames from "classnames";
import {
  Icon,
  type PropsWithClassName,
  type PropsWithIconType,
  type PropsWithOnClick,
  TextMain,
  bgStyles,
  borderStyles,
  flexStyles,
  iconStyles,
  paddingStyles,
  pointerStyles,
} from "ui";

interface Props extends PropsWithClassName, PropsWithOnClick, PropsWithIconType {
  title: string;
}

export function IconBox({ className, title, iconType, onClick }: Props) {
  return (
    <div
      className={classNames(
        bgStyles.neutral0,
        iconStyles.secondary300,
        flexStyles.horiz050,
        flexStyles.alignStart,
        borderStyles.bottomNeutral200,
        borderStyles.topFirstChildNeutral200,
        paddingStyles.p125,
        pointerStyles.cursor,
        className,
      )}
      onClick={onClick}
    >
      <Icon className={paddingStyles.pt012} iconType={iconType} />
      <TextMain>{title}</TextMain>
    </div>
  );
}
