import type { SVGProps } from "react";
const SvgColumnsTextAndText = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={20} fill="none" {...props}>
    <path
      fill="#465357"
      d="M6 16V6H2V4h10v2H8v10zm7.5 0v-6H11V8h7v2h-2.5v6zM26 16V6h-4V4h10v2h-4v10zm7.5 0v-6H31V8h7v2h-2.5v6z"
    />
  </svg>
);
export default SvgColumnsTextAndText;
