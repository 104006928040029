import type { BuiltInGradients } from "../enums";
import { Colors } from "./colors";

type GradientSpecs = {
  fromColor: string;
  toColor: string;
  fontColor: string;
};
export type Gradient = {
  name: string;
  Primary: GradientSpecs;
  Dark: GradientSpecs;
  Light: GradientSpecs;
  lightColor: string;
  darkColor: string;
};

export const Gradients: Record<BuiltInGradients, Gradient> = {
  Yellow: {
    name: "Yellow",
    Primary: {
      fontColor: Colors.Neutral600,
      fromColor: "#FFD600",
      toColor: "#FEEE5A",
    },
    Light: {
      fontColor: Colors.Neutral600,
      fromColor: "#FFFDF0",
      toColor: "#FFEF99",
    },
    Dark: {
      fontColor: Colors.Neutral0,
      fromColor: "#BBA901",
      toColor: "#665600",
    },
    lightColor: "#FFFBDC",
    darkColor: "#EAC502",
  },
  Lime: {
    name: "Lime",
    Primary: {
      fontColor: Colors.Neutral600,
      fromColor: "#F5FF82",
      toColor: "#B7FF1E",
    },
    Light: {
      fontColor: Colors.Neutral600,
      fromColor: "#FDFFE5",
      toColor: "#E7FFB2",
    },
    Dark: {
      fontColor: Colors.Neutral0,
      fromColor: "#96A300",
      toColor: "#456600",
    },
    lightColor: "#FCFFDD",
    darkColor: "#98DC05",
  },
  SeaGreen: {
    name: "Sea Green",
    Primary: {
      fontColor: Colors.Neutral600,
      fromColor: "#B9FFBC",
      toColor: "#00FFC2",
    },
    Light: {
      fontColor: Colors.Neutral600,
      fromColor: "#EBFFEB",
      toColor: "#99FFE7",
    },
    Dark: {
      fontColor: Colors.Neutral0,
      fromColor: "#00A372",
      toColor: "#00664E",
    },
    lightColor: "#F3FFF4",
    darkColor: "#07DFAA",
  },
  Turquoise: {
    name: "Turquoise",
    Primary: {
      fontColor: Colors.Neutral600,
      fromColor: "#37FFF3",
      toColor: "#00CBE7",
    },
    Light: {
      fontColor: Colors.Neutral600,
      fromColor: "#D6FFFD",
      toColor: "#99F3FF",
    },
    Dark: {
      fontColor: Colors.Neutral0,
      fromColor: "#009990",
      toColor: "#005A66",
    },
    lightColor: "#EFFFFE",
    darkColor: "#01CCE7",
  },
  Blue: {
    name: "Blue",
    Primary: {
      fontColor: Colors.Neutral0,
      fromColor: "#0094FF",
      toColor: "#001AFF",
    },
    Light: {
      fontColor: Colors.Neutral600,
      fromColor: "#99D4FF",
      toColor: "#99B4FF",
    },
    Dark: {
      fontColor: Colors.Neutral0,
      fromColor: "#005999",
      toColor: "#000F99",
    },
    lightColor: "#EAF2FF",
    darkColor: "#109BFF",
  },
  NavyBlue: {
    name: "Navy Blue",
    Primary: {
      fontColor: Colors.Neutral0,
      fromColor: "#6B79FF",
      toColor: "#1A00B8",
    },
    Light: {
      fontColor: Colors.Neutral600,
      fromColor: "#D1DBFF",
      toColor: "#85A0FF",
    },
    Dark: {
      fontColor: Colors.Neutral0,
      fromColor: "#0015D1",
      toColor: "#0B0052",
    },
    lightColor: "#F1F2FF",
    darkColor: "#4B51E4",
  },
  Purple: {
    name: "Purple",
    Primary: {
      fontColor: Colors.Neutral0,
      fromColor: "#AF04CB",
      toColor: "#6B03BD",
    },
    Light: {
      fontColor: Colors.Neutral600,
      fromColor: "#F8D2FE",
      toColor: "#E4C3FE",
    },
    Dark: {
      fontColor: Colors.Neutral0,
      fromColor: "#5B0269",
      toColor: "#33015A",
    },
    lightColor: "#FEF5FF",
    darkColor: "#A55ADC",
  },
  Pink: {
    name: "Pink",
    Primary: {
      fontColor: Colors.Neutral0,
      fromColor: "#D404A7",
      toColor: "#B5073C",
    },
    Light: {
      fontColor: Colors.Neutral600,
      fromColor: "#FED7F6",
      toColor: "#FDBFD2",
    },
    Dark: {
      fontColor: Colors.Neutral0,
      fromColor: "#6E0257",
      toColor: "#53031C",
    },
    lightColor: "#FFF1FC",
    darkColor: "#DF349B",
  },
  Orange: {
    name: "Orange",
    Primary: {
      fontColor: Colors.Neutral600,
      fromColor: "#E57105",
      toColor: "#D63300",
    },
    Light: {
      fontColor: Colors.Neutral600,
      fromColor: "#FDDAB9",
      toColor: "#FFB9A3",
    },
    Dark: {
      fontColor: Colors.Neutral0,
      fromColor: "#B45904",
      toColor: "#852000",
    },
    lightColor: "#FFF6ED",
    darkColor: "#DF6530",
  },
  Black: {
    name: "Black",
    Primary: {
      fontColor: Colors.Neutral0,
      fromColor: "#666666",
      toColor: "#000000",
    },
    Light: {
      fontColor: Colors.Neutral600,
      fromColor: "#CCCCCC",
      toColor: "#8C8C8C",
    },
    Dark: {
      fontColor: Colors.Neutral0,
      fromColor: "#333333",
      toColor: "#000000",
    },
    lightColor: "#F5F5F5",
    darkColor: "#797979",
  },
};

export const BlackGradient: GradientSpecs = {
  fontColor: Colors.Neutral0,
  fromColor: "#465357",
  toColor: "#232A2C",
};

export const GrayGradient: GradientSpecs = {
  fontColor: Colors.Neutral600,
  fromColor: "#F5F5F5",
  toColor: "#D1D4D5",
};
