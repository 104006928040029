import classNames from "classnames";
import { useEditMode } from "../hooks/useEditMode";
import { type PopoverLinkItem, PopoverMenu } from "./PopoverMenu";
import {
  bgStyles,
  borderStyles,
  flexStyles,
  heightStyles,
  marginStyles,
  paddingStyles,
  textStyles,
  widthStyles,
} from "./styles";
import type { PropsWithClassName } from "./types";
import { Description } from "./typography";

interface Props extends PropsWithClassName {
  host: string;
  relativeUrl: string;
  border?: boolean;
  links: PopoverLinkItem[];
}
export function BigLink({ className, host, relativeUrl, links, border = false }: Props) {
  const { ref, editMode, toggleEditMode } = useEditMode();

  return (
    <div
      className={classNames(
        flexStyles.vert050,
        bgStyles.businessLightOrNeutral200,
        paddingStyles.p125,
        textStyles.fontPoppins,
        className,
      )}
      ref={ref}
      onClick={toggleEditMode}
    >
      <div
        className={classNames(
          flexStyles.horiz,
          flexStyles.alignCenter,
          flexStyles.justifyCenter,
          borderStyles.radius025,
          bgStyles.neutral0,
          marginStyles.centered,
          heightStyles.height200,
          widthStyles.maxContent,
          widthStyles.full,
          { [borderStyles.neutral400]: border },
        )}
      >
        <Description className={textStyles.neutral400}>{host.replace("https://", "")}/</Description>
        <Description className={textStyles.neutral600}>{relativeUrl.slice(1)}</Description>
      </div>
      <PopoverMenu links={links} open={editMode} />
    </div>
  );
}
