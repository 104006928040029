import { type PersistedContactImport, type UserUploadPayload, UserUploadSchema } from "schemas";
import { Accordeon, FileUpload, FormProvider, RawIcon } from "ui";
import { addCsvToContactImport, uploadCsv } from "../../store";

interface Props {
  contactImport: PersistedContactImport;
}
export function Csv({ contactImport }: Props) {
  if (contactImport.type !== "csv") {
    return null;
  }

  const onUpload = async (payload: UserUploadPayload) => {
    await addCsvToContactImport({ contactImport, payload });
  };

  const { userUpload } = contactImport;
  return (
    <>
      <Accordeon label="example" startOpen={!userUpload}>
        <RawIcon iconType="CsvExample" />
      </Accordeon>
      <FormProvider<UserUploadPayload> defaultValues={{}} schema={UserUploadSchema}>
        <FileUpload<UserUploadPayload>
          name="csvFile"
          uploadFile={uploadCsv}
          initialValue={userUpload}
          onUpload={onUpload}
        />
      </FormProvider>
    </>
  );
}
