import type { PersistedCampaign } from "schemas";
import { apiRequest } from "../apiRequest";
import { setCampaign } from "./setCampaign";

export async function cloneCampaign(id: string): Promise<PersistedCampaign> {
  const clone = await apiRequest<PersistedCampaign>({ url: `campaigns/${id}/clone`, method: "POST" });
  setCampaign(clone);

  return clone;
}
