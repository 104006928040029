import type { ContactGroupPayload, PersistedContactGroup } from "schemas";
import { apiRequest } from "../apiRequest";
import { setContactGroup } from "./setContactGroup";

export async function addContactGroup(payload: ContactGroupPayload) {
  const contactGroup = await apiRequest<PersistedContactGroup>({
    url: "contactGroups",
    method: "POST",
    body: JSON.stringify(payload),
  });
  setContactGroup(contactGroup);

  return contactGroup;
}
