import type { ThemePayload } from "../schemas/Theme";
import { Colors } from "./colors";
import { isGradientTheme } from "./helpers/isGradientTheme";
import { themePrimary } from "./themePrimary";
import { themePrimaryDark } from "./themePrimaryDark";
import { themePrimaryLight } from "./themePrimaryLight";

export function themeCalloutBackground(theme: ThemePayload) {
  const isGrandient = isGradientTheme(theme);
  switch (theme.variation) {
    case "Black":
      return Colors.Neutral200;
    case "Gray":
      return Colors.Neutral600;
    case "Primary":
      return isGrandient ? themePrimaryLight(theme) : themePrimaryDark(theme);
    case "Light":
      return isGrandient ? Colors.Neutral200 : themePrimary(theme);
    case "Dark":
      return themePrimaryLight(theme);
  }
}
