import { DateTime } from "luxon";
import { useEffect } from "react";
import type { PersistedBooking } from "schemas";
import { apiRequest } from "../apiRequest";
import { useLiteSiteStore } from "../store";

function setDate(date: string, bookings: PersistedBooking[]) {
  useLiteSiteStore.setState((state) => {
    const map = state.upcomingBookings || new Map();
    map.set(date, bookings);
    return { upcomingBookings: map };
  });
}

async function fetchUpcomingBookings(): Promise<PersistedBooking[]> {
  try {
    const bookings = await apiRequest<PersistedBooking[]>({ url: "bookings/upcoming" });

    return bookings;
  } catch {
    return [];
  }
}

async function getUpcomingBookings() {
  const bookings = await fetchUpcomingBookings();
  const map = new Map<string, PersistedBooking[]>();
  for (const booking of bookings) {
    // biome-ignore lint/style/noNonNullAssertion:
    const date = DateTime.fromISO(booking.startDate, { setZone: true }).toISODate()!;

    const setBookings = map.get(date);
    if (setBookings) {
      setBookings.push(booking);
    } else {
      map.set(date, [booking]);
    }
  }
  // biome-ignore lint/style/noNonNullAssertion:
  setDate(DateTime.now().toISODate()!, []);
  map.forEach((values: PersistedBooking[], key: string) => {
    setDate(key, values);
  });
}

export function useUpcomingBookings() {
  useEffect(() => {
    getUpcomingBookings();
  }, []);

  return useLiteSiteStore(({ upcomingBookings }) => upcomingBookings);
}
