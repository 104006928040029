import type { SVGProps } from "react";

const SvgChevronUp = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="ChevronUp_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#ChevronUp_svg__a)">
      <path d="M4.487 14.487 3 13l7-7 7 7-1.487 1.487L10 8.974l-5.513 5.513Z" fill="#465357" />
    </g>
  </svg>
);
export default SvgChevronUp;
