import classNames from "classnames";
import { type PropsWithChildren, useEffect, useState } from "react";
import { useTransitionState } from "react-transition-state";
import { flexStyles, gridStyles, transitionStyles, useScrollIntoView } from "ui";

interface Props extends PropsWithChildren {
  open: boolean;
}

export function AddGrid({ children, open }: Props) {
  const [{ status }, toggle] = useTransitionState({
    timeout: 200,
    preEnter: true,
    preExit: true,
    mountOnEnter: true,
    unmountOnExit: true,
  });
  const [scrollOpen, setScrollOpen] = useState(open);
  const ref = useScrollIntoView(scrollOpen, true);

  useEffect(() => {
    toggle(open);
    setScrollOpen(open);
  }, [toggle, open]);

  return (
    <div
      ref={ref}
      className={classNames(
        gridStyles.grid3rows,
        flexStyles.gap050,
        transitionStyles.all200,
        transitionStyles.fadeIn,
        status,
      )}
    >
      {children}
    </div>
  );
}
