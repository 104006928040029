import type { PropsWithChildren } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { type BusinessPayload, BusinessSchema } from "schemas";
import { FormProvider, FormTag, TextMain, paddingStyles } from "ui";
import { updateBusiness, useCurrentBusiness } from "../../store";
import { Overlay, Submit } from "../ui";

interface Props extends PropsWithChildren {
  header: string;
  closeUrl: string;
  submitText: string;
  title?: string;
  subtitle?: string;
  toastText?: string;
}
export function BusinessForm({ children, header, closeUrl, submitText, title, subtitle, toastText }: Props) {
  const business = useCurrentBusiness();
  const navigate = useNavigate();
  const onClose = () => navigate(closeUrl, { relative: "path" });

  const onSubmit = async (payload: BusinessPayload) => {
    await updateBusiness(payload);
    toastText && toast.success(toastText);
    onClose();
  };

  return (
    <Overlay
      className={paddingStyles.p125}
      header={header}
      backTo={{ to: closeUrl }}
      title={title}
      subtitle={<TextMain>{subtitle}</TextMain>}
    >
      <FormProvider<BusinessPayload> defaultValues={business} schema={BusinessSchema}>
        <FormTag onSubmit={onSubmit}>
          {children}
          <Submit cancelTo={closeUrl} label={submitText} />
        </FormTag>
      </FormProvider>
    </Overlay>
  );
}
