import type { ThemePayload } from "schemas";
import { CardButton, type PropsWithClassName, RawThemedWrapper } from "ui";

interface Props extends PropsWithClassName {
  theme: ThemePayload;
}
export function ButtonPreview({ className, theme }: Props) {
  return (
    <RawThemedWrapper theme={theme} className={className}>
      <CardButton label="Button" iconType="OpenInNew" />
    </RawThemedWrapper>
  );
}
