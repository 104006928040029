import { flexStyles } from "ui";
import { useCustomerPaymentsSetup } from "../../store";
import { SetupPaymentsBox } from "../ui";
import { FormsSection } from "./FormsSection";
import { PaymentsInputs } from "./PaymentsInputs";

export function Utilities() {
  const paymentsSetup = useCustomerPaymentsSetup();

  return (
    <div className={flexStyles.vert200}>
      <FormsSection />
      {paymentsSetup ? <PaymentsInputs /> : <SetupPaymentsBox />}
    </div>
  );
}
