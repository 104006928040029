import type { SVGProps } from "react";
const SvgFormNumberFieldBox = (props: SVGProps<SVGSVGElement>) => (
  <svg width={295} height={53} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.5} y={0.5} width={294} height={52} rx={3.5} fill="#fff" />
    <path
      d="m24.806 24.56-.532 2.52h1.932v1.12h-2.17l-.602 2.8h-1.218l.602-2.8h-2.94l-.602 2.8h-1.218l.602-2.8h-2.198v-1.12h2.436l.532-2.52h-2.17v-1.12h2.408l.602-2.8h1.218l-.602 2.8h2.94l.602-2.8h1.218l-.602 2.8h1.946v1.12h-2.184Zm-1.218 0h-2.94l-.532 2.52h2.94l.532-2.52Z"
      fill="#D1D4D5"
    />
    <rect x={0.5} y={0.5} width={294} height={52} rx={3.5} stroke="#848C8F" />
  </svg>
);
export default SvgFormNumberFieldBox;
