import type { SVGProps } from "react";

const SvgEventCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="EventCheck_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#EventCheck_svg__a)">
      <path
        d="m9.125 15.667-2.833-2.834 1.062-1.062 1.771 1.771L12.646 10l1.062 1.062-4.583 4.605ZM4.5 18c-.417 0-.77-.15-1.062-.448A1.456 1.456 0 0 1 3 16.5v-11c0-.403.146-.753.438-1.052A1.428 1.428 0 0 1 4.5 4H6V2h1.5v2h5V2H14v2h1.5c.417 0 .77.15 1.062.448.292.299.438.65.438 1.052v11c0 .403-.146.753-.438 1.052A1.428 1.428 0 0 1 15.5 18h-11Zm0-1.5h11V9h-11v7.5Zm0-9h11v-2h-11v2Z"
        fill="#6ABB7B"
      />
    </g>
  </svg>
);
export default SvgEventCheck;
