import { useEffect } from "react";
import { Navigate } from "react-router";
import { useCustomerPaymentsRedirect } from "../../hooks";

export function Exit() {
  const { customerPaymentsRedirect, removeCustomerPaymentsRedirect } = useCustomerPaymentsRedirect();
  const location = customerPaymentsRedirect || "/";

  useEffect(() => {
    removeCustomerPaymentsRedirect();
  }, [removeCustomerPaymentsRedirect]);

  return <Navigate replace to={location} />;
}
