import { type ContactGroupPayload, ContactGroupSchema } from "schemas";
import { FormProvider, Loader, paddingStyles } from "ui";
import { useBackLocation, useGoBack } from "../../hooks";
import { updateContactGroup, useContactGroup } from "../../store";
import { Overlay } from "../ui";
import { Form } from "./Form";

export function Edit() {
  const contactGroup = useContactGroup();
  const backLocation = useBackLocation("..");
  const goBack = useGoBack("..");

  if (!contactGroup) {
    return <Loader />;
  }

  const onSubmit = async (payload: ContactGroupPayload) => {
    await updateContactGroup(contactGroup.id, payload);
    goBack();
  };

  return (
    <FormProvider defaultValues={contactGroup} schema={ContactGroupSchema}>
      <Overlay
        className={paddingStyles.p125}
        backTo={{ to: backLocation, label: `${contactGroup.name} List` }}
        header={`Edit ${contactGroup.name}`}
      >
        <Form onSubmit={onSubmit} cancelTo={backLocation} />
      </Overlay>
    </FormProvider>
  );
}
