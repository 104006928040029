import classNames from "classnames";
import { Outlet, useNavigate } from "react-router";
import {
  type DotMenuLink,
  InfoCard,
  Loader,
  ResponsiveWrapper,
  TextAccent,
  TextMain,
  flexStyles,
  paddingStyles,
  useConfirm,
} from "ui";
import { filterContacts } from "../../helpers/filterContacts";
import { usePagination } from "../../hooks";
import { deleteContactGroup, getContacts, useContactGroup, useContacts } from "../../store";
import { ContactsList } from "../contacts/ContactsList";
import { LoadMore, Overlay } from "../ui";
import { Panel } from "./Panel";

export function Show() {
  const navigate = useNavigate();
  const contactGroup = useContactGroup();
  const { page, setPage } = usePagination();
  const contacts = useContacts();
  const { confirm, Confirm } = useConfirm();

  if (!contactGroup) {
    return <Loader />;
  }

  const onMore = async () => {
    if (page.on) {
      const nextPage = page.page + 1;
      const contacts = await getContacts(contactGroup.id.toString(), nextPage);
      setPage({ page: nextPage, on: contacts.length > 0 });
    }
  };

  const links: DotMenuLink[] = [
    {
      label: "Edit",
      iconType: "Edit",
      onClick: () => navigate(`/contactGroups/${contactGroup.id}/edit`),
    },
    {
      label: "Delete",
      iconType: "Delete",
      onClick: () => {
        confirm({
          text: `Deleting the “${contactGroup.name}” group will remove it from your contact groups. The contacts within the group will remain unaffected and can still be accessed individually. Are you sure you want to delete this group?`,
          label: "Delete Group",
          animationType: "Thrash",
          onConfirm: async () => deleteContactGroup(contactGroup.id),
        });
      },
    },
  ];

  const filteredContacts = filterContacts(contacts, contactGroup);
  const hasPendingContacts = !!filteredContacts.find((contact) => contact.pending);

  return (
    <Overlay
      full
      header={contactGroup.name}
      backTo={{ to: "/contacts", label: "Contacts" }}
      links={!contactGroup.autogeneratedType ? links : undefined}
    >
      <Confirm />
      <ResponsiveWrapper className={classNames(flexStyles.vert050, flexStyles.grow, paddingStyles.p125)}>
        <Panel count={contactGroup.contactCount} />
        {hasPendingContacts && (
          <InfoCard>
            <TextAccent>Some contacts are pending import verification.</TextAccent>
            <TextMain>
              Your imported contacts are awaiting approval from a PopSend admin. You can send emails to these contacts
              after approval.
            </TextMain>
          </InfoCard>
        )}
        <ContactsList newContactTo="contacts/new" contacts={filteredContacts} />
        <LoadMore loadMore={onMore} />
      </ResponsiveWrapper>
      <Outlet />
    </Overlay>
  );
}
