import { captureException } from "@sentry/browser";
import type { PasswordResetPayload } from "schemas";
import { apiRequest } from "../apiRequest";

export async function requestPasswordReset(payload: PasswordResetPayload) {
  try {
    await apiRequest({ url: "passwordReset", method: "POST", body: JSON.stringify(payload) });
  } catch (e) {
    captureException(e);
  }
}
