import type { HobDomain } from "schemas";
import { apiRequest } from "../apiRequest";
import { setDomain } from "./setDomain";

export async function validateDomain(hobDomainId: number) {
  const { domain } = await apiRequest<{ domain: HobDomain }>({
    url: `domains/${hobDomainId}/validate`,
    method: "PUT",
  });
  setDomain(domain);

  return domain;
}
