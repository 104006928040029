import type { ThemePayload } from "../schemas/Theme";
import { Colors } from "./colors";
import { gradientCss } from "./gradientCss";
import { gradientForTheme } from "./gradientForTheme";
import { isGradientTheme } from "./helpers/isGradientTheme";
import { themePrimary } from "./themePrimary";
import { themePrimaryDark } from "./themePrimaryDark";

export function themeButtonBackground(theme: ThemePayload) {
  const isGradient = isGradientTheme(theme);

  switch (theme.variation) {
    case "Primary":
    case "Light":
    case "Dark":
      return Colors.Primary;
    case "Gray":
      return isGradient ? gradientCss(gradientForTheme({ ...theme, variation: "Primary" })) : themePrimaryDark(theme);
    case "Black":
      return isGradient ? gradientCss(gradientForTheme({ ...theme, variation: "Primary" })) : themePrimary(theme);
  }
}
