import { useNavigate } from "react-router";
import { CampaignWithSubjectSchema, type PersistedCampaign } from "schemas";
import { FormProvider, FormTag, Loader, Progress, TextInput, paddingStyles } from "ui";
import { updateCampaign, useCampaign } from "../../store";
import { Overlay, Submit } from "../ui";

export function Subject() {
  const navigate = useNavigate();
  const { campaign } = useCampaign();

  if (!campaign) {
    return <Loader />;
  }

  const onSubmit = async (payload: PersistedCampaign) => {
    await updateCampaign(campaign.id, payload);
    navigate("../summary", { relative: "path" });
  };

  return (
    <FormProvider defaultValues={campaign} schema={CampaignWithSubjectSchema}>
      <Overlay
        className={paddingStyles.p125}
        backTo={{ to: "../recipients", label: "Campaign Recipients" }}
        title="Give your campaign a catchy subject"
        header="Create a campaign"
        subHeaderElement={<Progress current={4} total={5} />}
      >
        <FormTag<PersistedCampaign> onSubmit={onSubmit}>
          <TextInput name="subject" label="Email Subject *" placeholder="Your campaign's subject" />
          <Submit label="Save and continue" />
        </FormTag>
      </Overlay>
    </FormProvider>
  );
}
