import type { PersistedElement } from "schemas";
import { apiRequest } from "../apiRequest";
import { setBlock } from "../blocks/setBlock";
import { useLiteSiteStore } from "../store";

export async function getCampaignElements(campaignId: string) {
  const { elements } = await apiRequest<{ elements: PersistedElement[] }>({
    url: `campaignElements/${campaignId}/elements`,
  });
  useLiteSiteStore.setState(({ campaignElements }) => ({
    campaignElements: campaignElements.set(campaignId, elements),
  }));
  elements.map(({ block }) => {
    setBlock(block);
  });
}
