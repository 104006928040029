import type { CampaignRecipientsPayload, PersistedCampaign } from "schemas";
import { apiRequest } from "../apiRequest";
import { setCampaign } from "./setCampaign";

export async function updateCampaignRecipients(id: string, payload: CampaignRecipientsPayload) {
  const campaign = await apiRequest<PersistedCampaign>({
    url: `campaigns/${id}/recipients`,
    method: "PATCH",
    body: JSON.stringify(payload),
  });
  setCampaign(campaign);

  return campaign;
}
