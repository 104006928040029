import type { ContactImportType } from "schemas";
import { Progress as UiProgress } from "ui";
interface Props {
  current: 1 | 2 | 3;
  contactImportType: ContactImportType;
}
export function Progress({ current, contactImportType }: Props) {
  const total = contactImportType === "csv" ? 2 : 3;
  return <UiProgress current={current} total={total} />;
}
