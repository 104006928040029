import classNames from "classnames";
import type { PropsWithChildren } from "react";
import { Icon } from "./Icon";
import { bgStyles, flexStyles, iconStyles, paddingStyles, pointerStyles } from "./styles";
import type { OnClickHandler, PropsWithClassName } from "./types";
import { HeaderMain } from "./typography";

interface Props extends PropsWithClassName, PropsWithChildren {
  onClick: OnClickHandler;
}
export function CaretLabel({ children, className, onClick }: Props) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        paddingStyles.py025,
        paddingStyles.px075,
        flexStyles.horiz050,
        flexStyles.justifyStart,
        flexStyles.alignSelfStart,
        iconStyles.neutral600,
        bgStyles.neutral0,
        pointerStyles.cursor,
        className,
      )}
    >
      <HeaderMain>{children}</HeaderMain>
      <Icon iconType="ChevronDown" />
    </div>
  );
}
