import type { SVGProps } from "react";

const SvgAutomations = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="Automations_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#Automations_svg__a)">
      <path d="M12 9.75H7V12H2V6h5v2.25h6V6h5v6h-5V9.75h-1Zm2.5.75h2v-3h-2v3Zm-11 0h2v-3h-2v3Z" fill="#465357" />
      <path d="m16.5 10-3 2.5 5 3.5 1-6h-3Z" fill="#fff" />
      <path
        d="M11 18.605v-1.77l4.417-4.418 1.77 1.771-4.416 4.417H11Zm6.77-5L16 11.835l.667-.668a.729.729 0 0 1 1.062 0l.709.709a.73.73 0 0 1 0 1.062l-.667.667Z"
        fill="#465357"
      />
    </g>
  </svg>
);
export default SvgAutomations;
