import classNames from "classnames";
import type { PersistedContactGroup } from "schemas";
import { type PropsWithName, SmallInitialsGroup, TextMain, flexStyles } from "ui";
import { Selectable } from "../ui/Selectable";

interface Props extends PropsWithName {
  contactGroup: PersistedContactGroup;
  disabled?: boolean;
}

export function SelectableContactGroup({ name, contactGroup, disabled }: Props) {
  return (
    <Selectable disabled={disabled} id={contactGroup.id} name={name}>
      <div className={classNames(flexStyles.horiz075)}>
        <TextMain className={flexStyles.grow}>{contactGroup.name}</TextMain>
        <SmallInitialsGroup initials={contactGroup.initials} />
      </div>
    </Selectable>
  );
}
