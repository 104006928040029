import type { SVGProps } from "react";

const SvgHeroInsta = (props: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={22} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.483 11.317c0 .433.003.866.006 1.3.006.904.012 1.809-.009 2.712-.083 3.476-2.596 6.036-6.077 6.108-2.76.057-5.524.055-8.284-.01-3.49-.08-5.988-2.604-6.061-6.11-.057-2.76-.055-5.525 0-8.285.07-3.468 2.65-5.986 6.137-6.027 2.718-.034 5.438-.034 8.157 0 3.478.041 6.027 2.565 6.123 6.043.027.986.02 1.971.014 2.957-.003.438-.006.876-.006 1.314v-.002Zm-10.289 8.116c.418 0 .836 0 1.255.002.92.003 1.84.006 2.76-.005 2.435-.026 4.225-1.691 4.274-4.093.057-2.762.06-5.53 0-8.292-.046-2.223-1.74-3.979-3.94-4.038-2.847-.078-5.7-.08-8.548 0-2.226.062-3.901 1.802-3.943 4.02-.051 2.806-.062 5.615.006 8.422.054 2.259 1.862 3.945 4.118 3.976.87.011 1.74.009 2.61.007.468-.002.937-.003 1.405-.002l.003.003Zm-4.869-8.427c.057-2.77 2.321-4.884 5.17-4.827 2.788.057 4.943 2.298 4.91 5.104-.037 2.84-2.33 5.04-5.19 4.975-2.849-.065-4.95-2.324-4.89-5.252Zm8.173.252c.023-1.738-1.32-3.162-3.032-3.217-1.748-.057-3.266 1.383-3.289 3.113-.023 1.704 1.364 3.154 3.06 3.2 1.81.05 3.24-1.307 3.263-3.096h-.002Zm2.137-4.83a1.09 1.09 0 1 0 0-2.18 1.09 1.09 0 0 0 0 2.18Z"
      fill="#fff"
    />
  </svg>
);
export default SvgHeroInsta;
