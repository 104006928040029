import { type Asserts, object, string } from "yup";

export const HobDomainSchema = object({
  host: string()
    .matches(/^(?!www\.)/, "No need for www")
    .matches(/^(?!https:\/\/)/, "No need for https://")
    .matches(/^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/, "Enter a valid domain")
    .required(),
}).noUnknown();

export type HobDomainPayload = Asserts<typeof HobDomainSchema>;
