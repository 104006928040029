import classNames from "classnames";
import { useFormContext, useWatch } from "react-hook-form";
import {
  type BuiltInGradients,
  Gradients,
  type ThemePayload,
  type ThemeSolidColor,
  ThemeSolids,
  compareThemes,
  isGradientTheme,
} from "schemas";
import { ClickableThemedWrapper, borderStyles, flexStyles, heightStyles, pointerStyles } from "ui";
import styles from "./Selector.module.css";

export function Selector() {
  const { reset } = useFormContext<ThemePayload>();
  const values = useWatch<ThemePayload>() as ThemePayload;

  if (!values) {
    return null;
  }

  const onThemeSelect = (theme: ThemePayload) => {
    reset(theme);
  };
  const isGradient = isGradientTheme(values);

  const gradientThemes: ThemePayload[] = Object.entries(Gradients).map(
    ([key, _gradient]): ThemePayload => ({
      ...values,
      type: "Gradient",
      gradient: key as BuiltInGradients,
      variation: "Primary",
    }),
  );

  const solidThemes: ThemePayload[] = ThemeSolids.map(
    ({ color }: ThemeSolidColor): ThemePayload => ({
      ...values,
      type: "SolidColor",
      primaryColor: color,
      variation: "Primary",
    }),
  );

  const themesCollection: ThemePayload[] = isGradient ? gradientThemes : solidThemes;

  return (
    <div className={classNames(flexStyles.horiz025, flexStyles.justifyBetween, flexStyles.wrap)}>
      {themesCollection.map((themeItem) => {
        const key = isGradient ? themeItem.gradient : themeItem.primaryColor;
        const active = compareThemes(values, themeItem);
        return (
          <ClickableThemedWrapper
            className={classNames(
              borderStyles.neutral300,
              pointerStyles.cursor,
              heightStyles.height250,
              borderStyles.radius025,
              styles.item,
              {
                [styles.active]: active,
              },
            )}
            key={key}
            onClick={onThemeSelect}
            theme={themeItem}
          />
        );
      })}
    </div>
  );
}
