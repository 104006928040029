import classNames from "classnames";
import { useFormContext, useWatch } from "react-hook-form";
import type { FontFamily } from "schemas";
import { Card, type PropsWithName, RawIcon, TextMain, flexStyles, paddingStyles, textStyles } from "ui";
import { FontPreview } from "./FontPreview";

interface Props extends PropsWithName {
  fontFamily: FontFamily;
}
export function FontOption({ name, fontFamily }: Props) {
  const { setValue } = useFormContext();
  const value = useWatch({ name });
  const selected = value === fontFamily;

  const onClick = () => {
    setValue("fontFamily", fontFamily, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
  };

  return (
    <Card onClick={onClick} className={classNames(paddingStyles.p125, flexStyles.horiz)}>
      <FontPreview className={flexStyles.grow} fontFamily={fontFamily}>
        <TextMain className={textStyles.fontTheme}>{fontFamily}</TextMain>
      </FontPreview>
      <RawIcon iconType={selected ? "RadioChecked" : "RadioUnchecked"} />
    </Card>
  );
}
