import classNames from "classnames";
import type { ContactImportType } from "schemas";
import { Loader, TextMain, flexStyles, paddingStyles } from "ui";
import { useGoBack } from "../../hooks";
import { useContactImport } from "../../store";
import { Overlay } from "../ui";
import { Csv } from "./Csv";
import { Details } from "./Details";
import { Progress } from "./Progress";
import { RunImport } from "./RunImport";

const Subtitles: Record<ContactImportType, string> = {
  csv: "Upload a .csv file with your contacts",
  googleContacts: "Take a look at your google contacts import",
};

export function Summary() {
  const { contactImport, loading } = useContactImport();
  const onDone = useGoBack("/contacts");

  if (loading || !contactImport) {
    return <Loader />;
  }

  return (
    <Overlay
      header="Contact import"
      title="Import your contacts"
      subtitle={<TextMain>{Subtitles[contactImport.type]}</TextMain>}
      subHeaderElement={
        <Progress current={contactImport.type === "csv" ? 2 : 3} contactImportType={contactImport.type} />
      }
      backTo={
        contactImport.type === "csv"
          ? { to: `/contactImports/${contactImport.id}/lists`, label: "List Selection" }
          : undefined
      }
    >
      <div className={classNames(paddingStyles.p125, flexStyles.vert125, flexStyles.grow)}>
        <Csv contactImport={contactImport} />
        <Details contactImport={contactImport} />
        <RunImport contactImport={contactImport} goBack={onDone} />
      </div>
    </Overlay>
  );
}
