import classNames from "classnames";
import { useNavigate } from "react-router";
import { Drawer, Loader, flexStyles } from "ui";
import { useContactImportCandidates } from "../../store";
import { CandidateCard } from "./CandidateCard";

export function DuplicatesDrawer() {
  const navigate = useNavigate();
  const { loading, candidates: duplicates } = useContactImportCandidates(true);

  const onClose = () => navigate("..");

  return (
    <Drawer title="Contact duplicates" open onClose={onClose} className={classNames(flexStyles.vert125)}>
      {loading ? (
        <Loader />
      ) : (
        duplicates.map((contact) => <CandidateCard key={`candidate-${contact.email}`} contact={contact} />)
      )}
    </Drawer>
  );
}
