import type { SVGProps } from "react";

const SvgDiamond = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="Diamond_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#Diamond_svg__a)">
      <path
        d="M10 17 2 8l2-5h12l2 5-8 9ZM8.208 7.25h3.584L10.417 4.5h-.834L8.208 7.25Zm1.042 6.646V8.75H4.667l4.583 5.146Zm1.5 0 4.583-5.146H10.75v5.146Zm2.708-6.646h2.625L15 4.5h-2.917l1.375 2.75Zm-9.541 0h2.625L7.917 4.5H5.02L3.917 7.25Z"
        fill="#fff"
      />
    </g>
  </svg>
);
export default SvgDiamond;
