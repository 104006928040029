import type { SVGProps } from "react";

const SvgCustomersUpperLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width={37} height={33} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M35.477 2.838c-7.743-.925-15.484-.716-23.268-.716-2.45 0-4.825.716-7.16.716-2.23 0-1.695 2.83-2.346 4.295-.531 1.195-.807 4.56-.875 5.728-.1 1.694-.473 4.59.358 6.085.647 1.166-.358 3.474-.358 5.012v6.801"
      stroke="#F5A343"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgCustomersUpperLeft;
