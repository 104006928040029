import type { SVGProps } from "react";

const SvgEvent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M24.603 8.01h-3.068v-.882a.735.735 0 0 0-.728-.728.735.735 0 0 0-.728.728v.883h-7.172v-.883a.735.735 0 0 0-.728-.728.735.735 0 0 0-.728.728v.883H8.36A2.364 2.364 0 0 0 6 10.372v13.814a2.364 2.364 0 0 0 2.361 2.362h16.242a2.364 2.364 0 0 0 2.361-2.362V10.35c0-1.28-1.06-2.34-2.361-2.34Zm.905 16.154a.89.89 0 0 1-.905.883H8.36c-.485 0-.905-.397-.905-.883v-9.356h18.03v9.356h.022Zm0-10.835H7.456v-2.98a.89.89 0 0 1 .905-.882h3.068v.883c0 .397.33.728.728.728.397 0 .728-.33.728-.728v-.883h7.172v.883c0 .397.331.728.728.728.397 0 .729-.33.729-.728v-.883h3.067c.485 0 .905.397.905.883v2.98h.022Z"
      fill="#F0A056"
    />
    <path
      d="M14.496 20.611c.088.067.11.199.088.287l-.265 1.5a.438.438 0 0 0 .64.464l1.324-.684a.377.377 0 0 1 .31 0l1.346.706c.33.177.706-.11.64-.463l-.243-1.479a.326.326 0 0 1 .088-.286l1.103-1.06c.265-.264.11-.706-.243-.75l-1.478-.22a.341.341 0 0 1-.243-.177l-.684-1.368c-.154-.331-.618-.331-.794 0l-.662 1.346c-.045.088-.133.176-.243.176l-1.523.221c-.353.044-.507.486-.243.75l1.082 1.037Z"
      fill="#F0A056"
    />
  </svg>
);
export default SvgEvent;
