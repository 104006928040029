import { DateTime } from "luxon";
import { Fragment, forwardRef } from "react";
import type { PersistedBooking } from "schemas";
import { SubheaderMain, TextAccent, TextMain, dateToDayDescription, flexStyles, paddingStyles } from "ui";
import { useCurrentBusiness } from "../../store";
import { BookingCard } from "../ui/BookingCard";

interface Props {
  day: DateTime;
  bookings: PersistedBooking[];
}

export const DayOfBookings = forwardRef<HTMLDivElement, Props>(({ day, bookings }: Props, ref) => {
  const business = useCurrentBusiness();
  const hours: number[] = [];
  return (
    <div className={flexStyles.vert050} ref={ref}>
      <div className={flexStyles.vert050}>
        <SubheaderMain>
          {day.toLocaleString({ month: "long", day: "2-digit" })}, {dateToDayDescription(day, business.timeZone)}
        </SubheaderMain>
        <TextMain>
          {bookings.length > 0 ? (
            <>
              You have {bookings.length} appointment{bookings.length > 1 && "s"}.
            </>
          ) : (
            <>No appointments today! Spend the day doing what you love most.</>
          )}
        </TextMain>
      </div>
      <div className={flexStyles.vert050}>
        {bookings.map((booking) => {
          const start = DateTime.fromISO(booking.startDate, { setZone: true });
          const { hour } = start;
          let hourGroup: React.JSX.Element | undefined;
          if (hours.indexOf(hour) < 0) {
            hours.push(hour);
            hourGroup = (
              <TextAccent className={paddingStyles.pt075}>
                {start.startOf("hour").toLocaleString({ hour: "2-digit", minute: "2-digit" })}
              </TextAccent>
            );
          }
          return (
            <Fragment key={booking.id}>
              {hourGroup}
              <BookingCard booking={booking} />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
});
