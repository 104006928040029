import classNames from "classnames";
import { squareStyles, textStyles } from "../styles";
import type { PropsWithClassName } from "../types";
import { RawInitials } from "./RawInitials";

interface Props extends PropsWithClassName {
  text: string;
}
export function SmallCircle({ className, text }: Props) {
  return (
    <RawInitials
      className={classNames(squareStyles.square150, textStyles.size062, className)}
      contact={{ initials: text }}
    />
  );
}
