import { useNavigate } from "react-router";
import { type ThemePayload, ThemeSchema, type ThemeType, ThemeTypeOptions } from "schemas";
import { Drawer, FormProvider, FormTag, Radio } from "ui";
import { updateTheme, useTheme } from "../../store";
import { Submit } from "../ui";
import { HexInput } from "./HexInput";
import { Selector } from "./Selector";

export function Edit() {
  const navigate = useNavigate();
  const theme = useTheme();
  const onClose = () => navigate("../..", { relative: "path" });

  const onSubmit = async (payload: ThemePayload) => {
    await updateTheme(theme.id, payload);
    onClose();
  };

  return (
    <Drawer onClose={onClose} title="Edit theme" open>
      <FormProvider<ThemePayload> defaultValues={theme} schema={ThemeSchema}>
        <FormTag<ThemePayload> onSubmit={onSubmit}>
          <Radio<ThemeType> name="type" options={ThemeTypeOptions} label="Style" />

          <Selector />
          <HexInput />
          <Submit cancelTo="../.." label="apply changes" disableInvalid />
        </FormTag>
      </FormProvider>
    </Drawer>
  );
}
