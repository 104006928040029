import type { SVGProps } from "react";

const SvgNote = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="Note_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#Note_svg__a)">
      <path
        d="M6 14h1.5l4.896-4.896-1.5-1.5L6 12.5V14Zm7.104-5.604.75-.75a.498.498 0 0 0 .167-.354.496.496 0 0 0-.167-.354l-.792-.792a.496.496 0 0 0-.354-.167.498.498 0 0 0-.354.167l-.75.75 1.5 1.5ZM4.5 17c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 3 15.5v-11c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 4.5 3h3.562c.111-.43.344-.788.698-1.073A1.923 1.923 0 0 1 10 1.5c.472 0 .885.142 1.24.427.354.285.587.642.698 1.073H15.5c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v11c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 15.5 17h-11Zm0-1.5h11v-11h-11v11ZM10 4a.482.482 0 0 0 .354-.146.482.482 0 0 0 .146-.354.482.482 0 0 0-.146-.354A.482.482 0 0 0 10 3a.482.482 0 0 0-.354.146.482.482 0 0 0-.146.354c0 .139.049.257.146.354A.482.482 0 0 0 10 4Z"
        fill="#F0A056"
      />
    </g>
  </svg>
);
export default SvgNote;
