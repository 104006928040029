import type { SVGProps } from "react";
const SvgFormSingleChoiceField = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="FormSingleChoiceField_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#FormSingleChoiceField_svg__a)">
      <path
        d="M10.005 14c1.108 0 2.05-.39 2.828-1.171S14 11.103 14 9.995s-.39-2.05-1.171-2.828S11.103 6 9.995 6s-2.05.39-2.828 1.171S6 8.897 6 10.005s.39 2.05 1.171 2.828S8.897 14 10.005 14Zm0 4a7.81 7.81 0 0 1-3.11-.625 8.064 8.064 0 0 1-2.551-1.719 8.065 8.065 0 0 1-1.719-2.551A7.818 7.818 0 0 1 2 9.99c0-1.104.208-2.14.625-3.105a8.066 8.066 0 0 1 4.27-4.26A7.818 7.818 0 0 1 10.009 2a7.75 7.75 0 0 1 3.106.625 8.082 8.082 0 0 1 4.26 4.265A7.77 7.77 0 0 1 18 9.994a7.81 7.81 0 0 1-.625 3.11 8.063 8.063 0 0 1-1.719 2.552 8.08 8.08 0 0 1-2.546 1.719 7.77 7.77 0 0 1-3.104.625ZM10 16.5c1.806 0 3.34-.632 4.604-1.896C15.868 13.34 16.5 11.806 16.5 10s-.632-3.34-1.896-4.604C13.34 4.132 11.806 3.5 10 3.5s-3.34.632-4.604 1.896C4.132 6.66 3.5 8.194 3.5 10s.632 3.34 1.896 4.604C6.66 15.868 8.194 16.5 10 16.5Z"
        fill="#F0A056"
      />
    </g>
  </svg>
);
export default SvgFormSingleChoiceField;
