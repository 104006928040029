import { useNavigate } from "react-router";
import { type PagePayload, PageSchema } from "schemas";
import { FormProvider, Loader, paddingStyles } from "ui";
import { useIdParam } from "../../hooks";
import { updatePage, usePage } from "../../store";
import { Overlay } from "../ui";
import { Form } from "./Form";

export function Edit() {
  const navigate = useNavigate();
  const id = useIdParam("pageId");
  const page = usePage(id);

  if (!page) {
    return <Loader />;
  }

  const onSubmit = async (payload: PagePayload) => {
    await updatePage(page.id, payload);
    navigate("..", { relative: "path" });
  };

  return (
    <FormProvider defaultValues={page} schema={PageSchema}>
      <Overlay
        className={paddingStyles.p125}
        backTo={{ to: "..", label: "Page Editor" }}
        header="Update a Page Block"
        title="Page Basics"
      >
        <Form onSubmit={onSubmit} id={id} />
      </Overlay>
    </FormProvider>
  );
}
