import { Outlet, useParams } from "react-router";
import { EmptyForm, type FormPayload, FormSchema, type PersistedForm } from "schemas";
import { FormProvider, FormTag, Loader, flexStyles } from "ui";
import { useGoBack } from "../../hooks";
import { createForm, updateAppointment, updateForm, useAppointment, useForm } from "../../store";

export function Editor() {
  const { appointmentId } = useParams();
  const appointment = useAppointment(appointmentId || "");
  const { loading, form } = useForm();
  const goBack = useGoBack("/forms");

  if (loading) {
    return <Loader />;
  }

  const onSubmit = async (payload: FormPayload) => {
    if (form?.id) {
      await updateForm(form.id, payload);
    } else {
      const form = await createForm(payload);
      if (appointment) {
        appointmentId && (await updateAppointment(appointmentId, { ...appointment, formId: form.id }));
      }
    }
    goBack();
  };

  return (
    <FormProvider<PersistedForm> schema={FormSchema} defaultValues={form || EmptyForm}>
      <FormTag<PersistedForm> onSubmit={onSubmit} className={flexStyles.grow}>
        <Outlet />
      </FormTag>
    </FormProvider>
  );
}
