"use client";

import type { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";
import { withHttp } from "../../../helpers";
import type { PropsWithClassName, PropsWithName } from "../../types";
import { Error } from "../Error";

export interface Props extends PropsWithClassName, PropsWithName {
  type?: "email" | "text" | "date" | "url";
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  disableAutoComplete?: boolean;
  autoFocus?: boolean;
}

export function InputWithErrors({
  name,
  type = "text",
  placeholder,
  onChange,
  autoFocus = false,
  disabled = false,
  disableAutoComplete,
  className,
}: Props) {
  const { register, setValue } = useFormContext();
  const localOnChange =
    onChange ||
    (type === "url"
      ? (e: ChangeEvent<HTMLInputElement>) => {
          setValue(name, withHttp(e.target.value));
        }
      : undefined);

  return (
    <>
      <input
        dir="auto"
        autoComplete={disableAutoComplete ? "off" : undefined}
        className={className}
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        autoFocus={autoFocus}
        {...register(name, { onChange: localOnChange })}
      />
      <Error name={name} />
    </>
  );
}
