import classNames from "classnames";
import { bgStyles, flexStyles, marginStyles, widthStyles } from "../../styles";

export function ToolbarDivider() {
  return (
    <div
      className={classNames(
        widthStyles.width006,
        bgStyles.editorBorder,
        marginStyles.myNegative025,
        flexStyles.alignSelfStretch,
      )}
    />
  );
}
