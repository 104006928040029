import { type Asserts, boolean, number, object, string } from "yup";
import { DefaultSchedule, TimeBetweenAppointmentsValues } from "../consts";
import { type OnlineConferenceProvider, OnlineConferenceProviderValues } from "../enums";
import { LinkValidation } from "./Link";
import { ScheduleSchema } from "./Schedule";
import { type DefaultTemplates, RemindersSchema } from "./reminder";
import { DaysAheadAllowedValues, NullableNumber, SessionDurationValidation, type Tag } from "./shared";

const AppointmentLinkValidation = LinkValidation.required("Appointment link is required");

export const AppointmentSchema = object({
  name: string().required("Appointment name is required"),
  public: boolean(),
  description: string().max(200, "Description can't be longer than 200 characters").nullable(),
  location: string()
    // biome-ignore lint/suspicious:
    .when("virtual", { is: false, then: (schema) => schema.required("Location is required") })
    .nullable(),
  virtual: boolean().required(),
  schedule: ScheduleSchema,
  handle: AppointmentLinkValidation,
  paddingMinutes: number().oneOf(TimeBetweenAppointmentsValues),
  durationMinutes: SessionDurationValidation.required("Appointment duration is required"),
  daysAheadAllowed: number().oneOf(DaysAheadAllowedValues),
  reminders: RemindersSchema,
  image: string().url().nullable(),
  price: NullableNumber("Price"),
  formId: string().nullable(),
  conferenceType: string().required().oneOf(OnlineConferenceProviderValues),
  conferenceJoinLink: string().nullable(),
}).noUnknown();

export type AppointmentPayload = Asserts<typeof AppointmentSchema> & {
  handle: string;
};

export type PersistedAppointment = AppointmentPayload & {
  id: string;
  businessId: string;
  tags: Tag[];
  pathname: string;
  url: string;
  defaultTemplates: DefaultTemplates;
  formattedPrice?: string;
  formId: string | null;
  conferenceType: OnlineConferenceProvider;
};

export const EmptyAppointment: AppointmentPayload = {
  name: "",
  description: "",
  location: "",
  handle: "",
  virtual: true,
  schedule: DefaultSchedule,
  conferenceType: "GoogleMeet",
  paddingMinutes: 0,
  durationMinutes: 30,
  public: true,
  daysAheadAllowed: 30,
  image: undefined,
  reminders: {
    ReturnPrompt: { emailEnabled: false, smsEnabled: false, days: 7 },
    ThankYou: { emailEnabled: false, smsEnabled: false },
    UpcomingBooking: { emailEnabled: false, smsEnabled: false },
  },
};
