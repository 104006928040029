import classNames from "classnames";
import type { PropsWithChildren } from "react";
import { Divider, flexStyles, paddingStyles, widthStyles } from "ui";

export function ChoiceWrapper({ children }: PropsWithChildren) {
  return (
    <div>
      <div className={classNames(flexStyles.horiz075, flexStyles.alignCenter, widthStyles.full, paddingStyles.py125)}>
        {children}
      </div>
      <Divider />
    </div>
  );
}
