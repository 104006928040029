import type { ThemePayload } from "../schemas/Theme";
import { Colors } from "./colors";
import { themePrimaryLight } from "./themePrimaryLight";

export function themeTagBackgroundColor(theme: ThemePayload) {
  switch (theme.variation) {
    case "Light":
    case "Dark":
    case "Primary":
      return themePrimaryLight(theme);
    case "Black":
    case "Gray":
      return Colors.Neutral100;
  }
}
