import { Outlet, useNavigate } from "react-router";
import type { PersistedContactImport } from "schemas";
import { pluralize } from "../../helpers";
import { DetailsCard } from "./DetailsCard";

interface Props {
  contactImport: PersistedContactImport;
}
export function Details({ contactImport }: Props) {
  const navigate = useNavigate();
  const { candidateCount, duplicateCount } = contactImport;

  if (contactImport.type === "csv" && !contactImport.userUpload) {
    return null;
  }

  return (
    <>
      <Outlet />
      <DetailsCard
        label="Found for import"
        text={`${candidateCount} ${pluralize("contact", candidateCount)}`}
        onClick={() => navigate("candidates")}
      />
      {duplicateCount > 0 && (
        <DetailsCard
          label="Duplicates"
          text={`${duplicateCount} ${pluralize("contact", duplicateCount)}`}
          onClick={() => navigate("duplicates")}
        />
      )}
    </>
  );
}
