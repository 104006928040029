import { type Asserts, object, string } from "yup";

export const VideoBlockSchema = object({
  video: string().url().required("Video url is required"),
  kind: string().oneOf(["VideoBlock"]).required(),
}).noUnknown();
export type VideoBlockPayload = Asserts<typeof VideoBlockSchema> & {
  kind: "VideoBlock";
};
export type PersistedVideoBlock = VideoBlockPayload & {
  id: string;
  thumbnailURL: string | undefined;
};
