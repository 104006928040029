"use client";

import type { ReactNode } from "react";

import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { bgStyles, flexStyles } from "../styles";
import type { PropsWithClassName } from "../types";
import { Error } from "./Error";
import { Label, LabelWithCount } from "./Label";
import formStyles from "./forms.module.css";

interface Props extends PropsWithClassName {
  name: string;
  label: string;
  placeholder?: string;
  maxCharacterCount?: number;
  extra?: ReactNode;
}

export function TextArea({ className, name, label, placeholder, extra, maxCharacterCount = 0 }: Props) {
  const { register } = useFormContext();

  const hasCharacterCount = maxCharacterCount > 0;
  return (
    <div className={flexStyles.vert025}>
      {hasCharacterCount ? (
        <LabelWithCount name={name} maxCharacterCount={maxCharacterCount}>
          {label}
        </LabelWithCount>
      ) : (
        <Label name={name} extra={extra}>
          {label}
        </Label>
      )}
      <textarea
        {...register(name)}
        className={classNames(bgStyles.neutral0, formStyles.input, className)}
        placeholder={placeholder}
      />
      <Error name={name} />
    </div>
  );
}
