import type { SVGProps } from "react";

const SvgInPerson = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="InPerson_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#InPerson_svg__a)">
      <path
        d="M4 17V7.5L10 3l6 4.5V17H4Zm1.5-8.75v6.521a7.808 7.808 0 0 1 2.125-1.125A7.22 7.22 0 0 1 10 13.25c.82 0 1.611.135 2.375.406.764.27 1.472.656 2.125 1.156V8.25L10 4.875 5.5 8.25Zm4.5 4a2.17 2.17 0 0 1-1.594-.656A2.17 2.17 0 0 1 7.75 10c0-.625.219-1.157.656-1.594A2.17 2.17 0 0 1 10 7.75a2.17 2.17 0 0 1 1.594.656c.437.437.656.969.656 1.594a2.17 2.17 0 0 1-.656 1.594A2.17 2.17 0 0 1 10 12.25Zm0-1.5a.723.723 0 0 0 .531-.219.723.723 0 0 0 .219-.531.723.723 0 0 0-.219-.531A.723.723 0 0 0 10 9.25a.723.723 0 0 0-.531.219.723.723 0 0 0-.219.531c0 .208.073.385.219.531a.723.723 0 0 0 .531.219ZM7.125 15.5h5.771a5.063 5.063 0 0 0-1.396-.573 6.246 6.246 0 0 0-1.479-.177c-.514 0-1.014.063-1.5.188a5.758 5.758 0 0 0-1.396.562Z"
        fill="#465357"
      />
    </g>
  </svg>
);
export default SvgInPerson;
