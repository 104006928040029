"use client";

import { LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import {
  $convertFromMarkdownString,
  BOLD_STAR,
  HEADING,
  ITALIC_UNDERSCORE,
  LINK,
  ORDERED_LIST,
  STRIKETHROUGH,
  UNORDERED_LIST,
} from "@lexical/markdown";
import { type InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { HeadingNode } from "@lexical/rich-text";
import classNames from "classnames";
import type { TextAlignment } from "schemas";
import { flexStyles, positionStyles } from "../../styles";
import type { PropsWithClassName, PropsWithName, PropsWithOptionalFontFamily } from "../../types";
import { Editor } from "./Editor";

export const LITESITE_TRANSFORMERS = [
  UNORDERED_LIST,
  ORDERED_LIST,
  ITALIC_UNDERSCORE,
  STRIKETHROUGH,
  BOLD_STAR,
  HEADING,
  LINK,
];
const EDITOR_NODES = [ListNode, ListItemNode, LinkNode, HeadingNode];

export type RichEditorType = "inline" | "form";
interface Props extends PropsWithClassName, PropsWithName, PropsWithOptionalFontFamily {
  type: RichEditorType;
  value: string;
  setValue: (value: string) => void;
  onSubmit?: () => Promise<void>;
  placeholder?: string;
  autoFocus: boolean;
  fontSelectable: boolean;
  textAlignment: TextAlignment;
  textAlignmentSelectable: boolean;
}
export function Wysiwyg({
  type,
  name,
  onSubmit,
  value,
  setValue,
  placeholder,
  autoFocus,
  fontFamily,
  fontSelectable,
  textAlignment,
  textAlignmentSelectable,
}: Props) {
  const initialConfig: InitialConfigType = {
    theme: {
      text: {
        bold: "strong",
        italic: "em",
        strikethrough: "strikethrough",
      },
    },
    namespace: `${name}-texarea`,
    nodes: EDITOR_NODES,
    onError: (error: Error) => {
      console.error(error);
    },
    editorState: () => $convertFromMarkdownString(value, LITESITE_TRANSFORMERS),
  };
  return (
    <div className={classNames(positionStyles.relative, flexStyles.grow)}>
      <LexicalComposer initialConfig={initialConfig}>
        <Editor
          fontFamily={fontFamily}
          type={type}
          autoFocus={autoFocus}
          onSubmit={onSubmit}
          placeholder={placeholder}
          setValue={setValue}
          fontSelectable={fontSelectable}
          textAlignment={textAlignment}
          textAlignmentSelectable={textAlignmentSelectable}
        />
      </LexicalComposer>
    </div>
  );
}
