import classNames from "classnames";
import type { PropsWithChildren } from "react";
import { flexStyles, heightStyles, paddingStyles } from "ui";
import { useShowMenu } from "../../hooks";
import { Header } from "./Header";
import { Menu } from "./Menu";

export function MobileNav({ children }: PropsWithChildren) {
  const showMenu = useShowMenu();

  return (
    <div className={classNames(flexStyles.vert, heightStyles.minHeightFull, { [paddingStyles.pb537]: showMenu })}>
      <Header />
      {children}
      {showMenu && <Menu />}
    </div>
  );
}
