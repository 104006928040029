import { TextMain, textStyles } from "ui";
import { useUrlWithBack } from "../../hooks";
import { Link } from "../ui";

type MessageType = "contacts" | "campaign" | "sendableThreshold";
const Upgradeable: MessageType[] = ["contacts", "campaign"];

interface Props {
  type?: MessageType;
}

const copy: Record<MessageType, string> = {
  contacts: "This import will lead you to exceed the maximum free contacts count.",
  campaign: "You have exceeded the free contact allowance, please upgrade to send this campaign.",
  sendableThreshold: "You have exceeded out spam prevention volume limits. We're validating your account.",
};

export function ExceededLimit({ type = "contacts" }: Props) {
  const upgradePath = useUrlWithBack("/billing/discovered/contacts");

  return (
    <TextMain className={textStyles.errorDark}>
      {copy[type]} {Upgradeable.includes(type) && <Link to={upgradePath}>Upgrade to PopSend Business</Link>}
    </TextMain>
  );
}
