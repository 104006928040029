import classNames from "classnames";
import { textStyles } from "ui";
import { Button } from "../Button";
import { Link } from "../Link";

export type CancelType = "link" | "button";

interface Props {
  cancelType?: CancelType;
  to?: string;
}
export function Cancel({ to, cancelType = "link" }: Props) {
  if (!to) {
    return null;
  }
  return cancelType === "link" ? (
    <Link className={classNames(textStyles.weight500, textStyles.center)} to={to}>
      Cancel
    </Link>
  ) : (
    <Button buttonType="secondary" to={to}>
      Cancel
    </Button>
  );
}
