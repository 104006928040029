import type { SVGProps } from "react";

const SvgHeroArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg width={83} height={55} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.362 20.833c3.408 4.87 6.62 10.23 9.298 15.389 1.2 2.314 2.308 4.633 3.56 6.93.873 1.604.82 3.34 1.606 4.946.4.817 1.08 5.82 1.834 4.139 2.083-4.634 2.098-9.981 4.129-14.72 2.334-5.444 5.98-11.896 10.813-15.92 6.068-5.054 14.217-8.463 21.929-11.435C58.47 9.03 96.056-1.46 74.387 3.547"
      stroke="#8FC9FA"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgHeroArrow;
