import classNames from "classnames";
import { Icon } from "../Icon";
import { bgStyles, borderStyles, flexStyles, iconStyles, paddingStyles, positionStyles } from "../styles";
import { SmallTextMain } from "../typography";

interface Props {
  label: string;
}

export function TableRow({ label }: Props) {
  return (
    <div
      className={classNames(
        flexStyles.horiz062,
        flexStyles.alignCenter,
        iconStyles.successMedium,
        bgStyles.neutral0,
        paddingStyles.py050,
        paddingStyles.px125,
        borderStyles.bottomNeutral200,
        borderStyles.topFirstChildNeutral200,
        positionStyles.relative,
      )}
    >
      <Icon iconType="TaskAlt" />
      <SmallTextMain>{label}</SmallTextMain>
    </div>
  );
}
