import { useEffect, useState } from "react";
import type { CampaignStatType } from "schemas";
import { Description, Loader } from "ui";
import { fetchCampaignStatEmails, useCampaign } from "../../store";

interface Props {
  statType: CampaignStatType;
}
export function CampaignStatEmails({ statType }: Props) {
  const { campaign } = useCampaign();
  const [emails, setEmails] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (campaign?.id) {
      fetchCampaignStatEmails(campaign.id, statType)
        .then(setEmails)
        .then(() => setLoading(false));
    }
  }, [campaign?.id, statType]);

  if (loading) {
    return <Loader fitted />;
  }

  return (
    <>
      {emails.map((email) => (
        <Description key={email}>{email}</Description>
      ))}
    </>
  );
}
