import { type FC, useState } from "react";
import type { AnimationType } from "../components/Animation";
import { Confirm as ConfirmComponent } from "../components/Confirm";

type Confirmator = ({
  text,
  label,
  animationType,
  onConfirm,
}: {
  text: string;
  label: string;
  animationType?: AnimationType;
  onConfirm?: () => Promise<void>;
}) => Promise<boolean>;
interface Return {
  Confirm: FC;
  confirm: Confirmator;
}
export function useConfirm(): Return {
  const [resolve, setResolve] = useState<{ resolve: (result: boolean) => Promise<void> }>();
  const [text, setText] = useState<string>();
  const [label, setLabel] = useState<string>();
  const [animationType, setAnimationType] = useState<AnimationType>();

  const confirm: Confirmator = ({ text, label, onConfirm, animationType }) => {
    setText(text);
    setLabel(label);
    setAnimationType(animationType);
    return new Promise<boolean>((innerResolve) => {
      const resolve = async (result: boolean) => {
        result && onConfirm && (await onConfirm());
        innerResolve(result);
      };
      setResolve({ resolve });
    });
  };
  const cleanup = () => {
    setResolve(undefined);
    setText(undefined);
    setLabel(undefined);
  };

  const onClose = () => {
    if (resolve) {
      resolve.resolve(false);
      cleanup();
    }
  };

  const handleConfirm = async () => {
    if (resolve) {
      await resolve.resolve(true);
      cleanup();
    }
  };
  const Confirm = () =>
    ConfirmComponent({
      open: !!resolve,
      title: "Are you sure?",
      animationType,
      text: text || "",
      confirmText: label,
      onClose,
      onConfirm: handleConfirm,
    });

  return { confirm, Confirm };
}
