import classNames from "classnames";
import { HeaderMain, SubheaderMain, flexStyles, paddingStyles, textStyles } from "ui";
import { useBackLocation } from "../../hooks";
import googlePermissions from "../../images/google-calendar-auth-recomendation.png";
import { Overlay } from "../ui";
import { GoogleButton } from "../ui/GoogleButton";

export function Connect() {
  const redirect = useBackLocation("/content");

  return (
    <Overlay header="Connect Google" backTo={{ to: redirect, label: "Appointment" }}>
      <div className={classNames(flexStyles.vert200, flexStyles.alignCenter, textStyles.center, paddingStyles.pt600)}>
        <HeaderMain>Let&apos;s get connected!</HeaderMain>

        <SubheaderMain>
          Sync PopSend to your <span style={{ color: "#f43570" }}>Google Calendar</span> to so you don&apos;t double
          book.
        </SubheaderMain>

        <img style={{ height: "25rem" }} src={googlePermissions} alt="google-permissions" />

        <GoogleButton redirect={redirect} type="calendar" label="Connect your calendar" />
      </div>
    </Overlay>
  );
}
