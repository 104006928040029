import type { PersistedContact } from "schemas";
import { apiRequest } from "../apiRequest";
import { setContact } from "./setContact";

export async function addContactByEmail(email: string): Promise<PersistedContact> {
  const contact = await apiRequest<PersistedContact>({
    url: "contacts/byEmail",
    method: "POST",
    body: JSON.stringify({ email }),
  });

  setContact(contact);
  return contact;
}
