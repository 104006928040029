import type { PersistedAppointment } from "schemas";
import { apiRequest } from "../apiRequest";
import { setAppointment } from "./setAppointment";

export async function getAppointment(id: string) {
  try {
    const appointment = await apiRequest<PersistedAppointment>({ url: `appointments/${id}` });
    setAppointment(appointment);
    return appointment;
  } catch {
    // not found
  }
}
