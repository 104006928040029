import { useEffect, useMemo, useState } from "react";
import type { AutogeneratedType, PersistedContactGroup } from "schemas";
import { apiRequest } from "../apiRequest";
import { useLiteSiteStore } from "../store";
import type { HookLoading } from "../types";
import { setContactGroup } from "./setContactGroup";

async function getContactGroups() {
  const contactGroups = await apiRequest<PersistedContactGroup[]>({ url: "contactGroups" });
  contactGroups.map(setContactGroup);
}

function sortName({ autogeneratedType, name }: PersistedContactGroup) {
  if (autogeneratedType) {
    return autogeneratedType === "AllContacts" ? "0" : `zzzz${name}`;
  }
  return name.toLowerCase();
}

export function useContactGroups(
  excludeList: AutogeneratedType[] = [],
): HookLoading<{ contactGroups: PersistedContactGroup[] }> {
  const [loading, setLoading] = useState(true);
  const contactGroupsMap = useLiteSiteStore(({ contactGroups }) => contactGroups);
  const values = useMemo(
    () =>
      Array.from(contactGroupsMap.values())
        .sort((a, b) => sortName(a).localeCompare(sortName(b)))
        .filter(
          (group: PersistedContactGroup) => !group.autogeneratedType || !excludeList.includes(group.autogeneratedType),
        ),
    [contactGroupsMap, excludeList],
  );

  useEffect(() => {
    if (loading) {
      getContactGroups().then(() => setLoading(false));
    }
  }, [loading]);

  return { loading, contactGroups: values };
}
