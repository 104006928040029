"use client";

import { useFormContext, useWatch } from "react-hook-form";
import { Link } from "../Link";
import { SubheaderMain } from "../typography";
import { TextInput } from "./TextInput";

interface Props {
  type: "text" | "url";
  name: string;
  label?: string;
}
export function MultipleTextInputs({ name, type, label }: Props) {
  const value: string[] = useWatch({ name });
  const { setValue } = useFormContext();
  const onAddAntoher = () => {
    setValue(name, [...value, ""]);
  };

  return (
    <>
      {label && <SubheaderMain>{label}</SubheaderMain>}
      {value.map((_, index) => (
        // biome-ignore lint/suspicious:
        <TextInput key={`${name}.${index}`} type={type} name={`${name}.${index}`} placeholder="Add profile URL" />
      ))}
      <Link onClick={onAddAntoher}>Add another</Link>
    </>
  );
}
