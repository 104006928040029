import type { SVGProps } from "react";

const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="Logout_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#Logout_svg__a)">
      <path
        d="M4.5 17c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 3 15.5v-11c0-.412.147-.766.44-1.06.294-.293.648-.44 1.06-.44H10v1.5H4.5v11H10V17H4.5Zm9-3.5-1.063-1.063 1.688-1.687H8v-1.5h6.125l-1.688-1.688L13.5 6.5 17 10l-3.5 3.5Z"
        fill="#F0A056"
      />
    </g>
  </svg>
);
export default SvgLogout;
