import type { AppointmentPayload, PersistedAppointment, PersistedPage } from "schemas";
import { apiRequest } from "../apiRequest";
import { getPage } from "../pages/getPage";
import { setAppointment } from "./setAppointment";

export async function addAppointment(
  destinationIndex: number,
  destinationPage: PersistedPage,
  payload: AppointmentPayload,
): Promise<PersistedAppointment> {
  const appointment = await apiRequest<PersistedAppointment>({
    url: "appointments",
    method: "POST",
    body: JSON.stringify({
      appointment: payload,
      destination: {
        destinationIndex,
        destinationType: "Page",
        destinationId: destinationPage.id,
      },
    }),
  });
  setAppointment(appointment);
  getPage();
  return appointment;
}
