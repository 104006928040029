import classNames from "classnames";
import { DateTime } from "luxon";
import { type CSSProperties, type PropsWithChildren, useEffect, useRef } from "react";
import { businessThemeObject } from "schemas";
import { DayCarousel, HeaderMain, Loader, bgStyles, paddingStyles, positionStyles } from "ui";
import {
  scrollBookingIntoView,
  updateViewedItems,
  useBookingIntersection,
  useCurrentBusiness,
  useHomePage,
  useScrollBookingIntoView,
  useTheme,
  useUpcomingBookings,
  useUpcomingIntersectingDays,
} from "../../store";
import { BottomWrapper, Button, HomeWrapper } from "../ui";
import { DayOfBookings } from "./DayOfBookings";

interface ObserverWrapProps extends PropsWithChildren {
  dateString: string;
}
function ObserverWrap({ dateString, children }: ObserverWrapProps) {
  const ref = useRef<HTMLDivElement>(null);
  const scroll = useScrollBookingIntoView(dateString);
  useBookingIntersection(ref, -214, dateString);

  useEffect(() => {
    const offset = ref.current?.offsetTop;
    if (scroll && offset) {
      window.scrollTo({ top: offset - 214, behavior: "smooth" });
      scrollBookingIntoView(undefined);
    }
  }, [scroll]);

  return <div ref={ref}>{children}</div>;
}

export function Upcoming() {
  const homePage = useHomePage();
  const theme = useTheme(homePage?.themeId);
  const upcomingBookings = useUpcomingBookings();
  const { timeZone, url } = useCurrentBusiness();
  const intersectingDays = useUpcomingIntersectingDays();

  useEffect(() => {
    if (upcomingBookings && upcomingBookings.size > 0) {
      updateViewedItems({ bookings: true });
    }
  }, [upcomingBookings]);

  if (!upcomingBookings || !theme) {
    return <Loader />;
  }

  const dates: DateTime[] = [...upcomingBookings.keys()].map((key: string) => DateTime.fromISO(key));

  const scrollAndSet = (day: DateTime) => {
    scrollBookingIntoView(day.toISODate() || undefined);
  };
  const selectedDate = DateTime.fromISO(intersectingDays[0]);

  return (
    <HomeWrapper>
      <HeaderMain>Upcoming</HeaderMain>
      <DayCarousel
        style={businessThemeObject(theme) as CSSProperties}
        className={classNames(
          positionStyles.sticky,
          positionStyles.top400,
          bgStyles.neutral100,
          paddingStyles.py100,
          positionStyles.zIndex47,
        )}
        dates={dates}
        selectedDate={selectedDate}
        onClick={(day: DateTime) => scrollAndSet(day)}
        timeZone={timeZone}
      />
      {Array.from(upcomingBookings).map(([dateString, bookings]) => (
        <ObserverWrap key={dateString} dateString={dateString}>
          <DayOfBookings day={DateTime.fromISO(dateString)} bookings={bookings} />
        </ObserverWrap>
      ))}
      <BottomWrapper>
        <Button href={`${url}/schedule`} target="_blank">
          Schedule an appointment
        </Button>
      </BottomWrapper>
    </HomeWrapper>
  );
}
