import classNames from "classnames";
import { type PropsWithChildren, type ReactNode, forwardRef } from "react";
import type { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import {
  Card,
  type OnClickHandler,
  type PropsWithClassName,
  type PropsWithOptionalClick,
  type PropsWithOptionalTo,
  flexStyles,
  paddingStyles,
  textStyles,
} from "ui";
import { DragHandle } from "./DragHandle";
import { Header } from "./Header";
import { MaybeLink } from "./MaybeLink";

interface Props extends PropsWithClassName, PropsWithChildren, PropsWithOptionalTo, PropsWithOptionalClick {
  headerOnClick?: OnClickHandler;
  title: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
  dragHandleProps?: DraggableProvidedDragHandleProps | undefined | null;
  noBorder?: boolean;
}

export const SuperCard = forwardRef<HTMLDivElement, Props>(
  ({ className, dragHandleProps, children, title, to, onClick, headerOnClick, left, right, noBorder }: Props, ref) => {
    return (
      <Card ref={ref} className={className}>
        <div className={classNames(flexStyles.horiz, flexStyles.justifyStretch)}>
          <DragHandle dragHandleProps={dragHandleProps} />
          <MaybeLink to={to} onClick={onClick}>
            <Header noBorder={noBorder} left={left} right={right} title={title} onClick={headerOnClick} />
            {children && (
              <div dir="auto" className={classNames(flexStyles.vert050, paddingStyles.p125, textStyles.primary)}>
                {children}
              </div>
            )}
          </MaybeLink>
        </div>
      </Card>
    );
  },
);
