import type { PersistedTheme } from "schemas";
import { apiRequest } from "../apiRequest";
import { setTheme } from "./setTheme";

export async function setDefaultCampaignTheme(theme: PersistedTheme, defaultCampaignTheme: boolean) {
  const updatedTheme = await apiRequest<PersistedTheme>({
    url: `themes/${theme.id}/setDefaultCampaignTheme`,
    method: "PATCH",
    body: JSON.stringify({ defaultCampaignTheme }),
  });
  setTheme(updatedTheme);
}
