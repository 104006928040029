import type { SVGProps } from "react";
const SvgAuthStarTop = (props: SVGProps<SVGSVGElement>) => (
  <svg width={17} height={19} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.222 16.789c1.37-1.37 2.956-2.33 4.589-3.352.541-.338 2.198-1.29 1.606-1.045-.83.344-1.524.866-2.308 1.287-1.4.751-2.847 1.926-4.016 2.98-.303.274-.99.731-.366.107.893-.892 1.492-2.034 2.22-3.054.736-1.031.989-2.226 1.88-3.118.7-.7-2.482-2.677-3.167-3.362-.43-.43-1.821-1.373-1.95-1.963-.113-.525 1.037.32 1.569.388.756.098 1.82.477 2.584.724.605.196 1.123.546 1.75.695.582.14.919-.563 1.354-.894 1.145-.869 1.881-2.197 2.882-3.198l.965-.965c.243-.243-.229 1.809-.309 2.121-.405 1.582-.74 3.163-1.259 4.682-.124.363-.776.723-.44 1.059.417.417.95 1.213 1.502 1.447 1.052.444 2.158 1.869 2.945 2.75.301.337-.934-.002-1.082-.083-.477-.258-.986-.438-1.474-.669-.7-.33-2.252-1.218-2.942-.998"
      stroke="#F5A343"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgAuthStarTop;
