import type { FC } from "react";
import type { EditableBlockKinds } from "schemas";
import { AppointmentLinkForm } from "./AppointmentLinkForm";
import { FormLinkForm } from "./FormLinkForm";
import { SocialLinksForm } from "./SocialLinksForm";

// biome-ignore lint:
export const BlockFormComponents: Record<EditableBlockKinds, FC<any>> = {
  AppointmentLinkBlock: AppointmentLinkForm,
  FormLinkBlock: FormLinkForm,
  SocialLinksBlock: SocialLinksForm,
};
