"use client";

import classNames from "classnames";
import { useFormContext, useWatch } from "react-hook-form";
import { PhoneInput as ReactPhoneInput } from "react-international-phone";
import { borderStyles, flexStyles, paddingStyles, placeholderStyles, textStyles, widthStyles } from "../styles";
import { Error } from "./Error";
import { Label } from "./Label";
import styles from "./PhoneInput.module.css";

interface Props {
  name: string;
  label: string;
  placeholder?: string;
}
export function PhoneInput({ name, label, placeholder }: Props) {
  const { register, setValue } = useFormContext();
  const value = useWatch({ name });

  const onChange = (value: string) => setValue(name, value, { shouldValidate: true });

  return (
    <div className={flexStyles.vert025}>
      <Label name={name}>{label}</Label>
      <ReactPhoneInput
        countrySelectorStyleProps={{ buttonClassName: paddingStyles.ps050 }}
        {...register(name, { value })}
        className={classNames(styles.phoneInput)}
        inputClassName={classNames(
          textStyles.size087,
          widthStyles.full,
          borderStyles.secondary300Active,
          placeholderStyles.neutral300,
        )}
        onChange={onChange}
        placeholder={placeholder}
        value={value || ""}
      />
      <Error name={name} />
    </div>
  );
}
