"use client";

import classNames from "classnames";
import { Button } from "./Button";
import { Card } from "./Card";
import { Icon } from "./Icon";
import { flexStyles, iconStyles, paddingStyles, textStyles } from "./styles";
import type { OnClickHandler } from "./types";
import { Description, SmallTextMain } from "./typography";

interface Props {
  addElement: OnClickHandler;
}
export function EmptyElements({ addElement }: Props) {
  return (
    <Card
      className={classNames(
        flexStyles.vert125,
        flexStyles.alignCenter,
        paddingStyles.p125,
        textStyles.primary,
        iconStyles.primary,
      )}
    >
      <SmallTextMain>Add blocks with headers, rich text, photos and buttons.</SmallTextMain>
      <Button onClick={addElement}>+ ADD BLOCK</Button>
      <div className={flexStyles.horiz050}>
        <Icon iconType="Show" />
        <Description>This box is only visible to you.</Description>
      </div>
    </Card>
  );
}
